import React from 'react';

import classNames from 'classnames';

import { Avatar } from '@components/Avatar';
import ClubsList from '@components/ClubsList';
import Link from '@components/Link';

import { Club, Maybe } from '@typings/operations';

interface Props {
  photoUri?: Maybe<string>;
  individualId: string;
  individualName: string;
  club: Partial<Club>;
  wrapperClassname?: string;
}

const IndividualInfo: React.FC<Props> = ({
  photoUri,
  individualId,
  individualName,
  club,
  wrapperClassname = 'mb-7',
}) => (
  <div
    data-label="membership-management-individual-info"
    className={classNames('flex items-center flex-1', wrapperClassname)}
  >
    <Avatar
      photoUri={photoUri || undefined}
      className="flex justify-center self-center mr-6"
    />

    <div className="min-h-16 pr-4">
      <h3 className="text-md font-bold leading-6 mb-0 underline">
        <Link noLinkStyles to={`/profile/${individualId}`}>
          {individualName}
        </Link>
      </h3>
      <ClubsList clubs={[club]} />
    </div>
  </div>
);
export default IndividualInfo;
