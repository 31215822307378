import React, { MutableRefObject, useRef, useState } from 'react';

import { v4 as uuid } from 'uuid';

import { Button } from '@components/Button';
import LinkPrevious from '@components/LinkPrevious';
import Loading from '@components/Loading';
import { Pagination } from '@components/Pagination';
import { ResultsList, ResultsListItem } from '@components/ResultsList';
import { IdentifyLeadsMemberResult } from '@presenters/web/components/Leads/IdentifyMemberResult/index';

import SearchForm from './SearchForm';

import { IdentificationParameters } from '@domain/clubs';

import { useClubAddMemberFilters } from '@use-cases/clubs';
import { getMembershipCandidatePath } from '@use-cases/leads';
import { useErrorHandling } from '@use-cases/notifications';

import { useSearchIndividual } from '@repositories/clubs';
import { useDISCountries } from '@repositories/disCountry';

import { isTerminationBeforeCurrentDate } from '@utils/membershipUtils';

import { useTranslation } from '@external/react-i18next';

import { MembershipType } from '@typings/operations';

const PAGE_SIZE = 5;

const searchNonMembers = true;

const IdentifyCandidate: React.FC<{
  clubId: string;
  filters: IdentificationParameters;
  setFilters: (filters: IdentificationParameters) => void;
  submitHandler: (id: string | undefined | false) => void;
  clubName: string;
  handleFormFieldsTouched?: (isTouched: boolean) => void;
}> = ({
  submitHandler,
  children,
  setFilters,
  filters,
  clubName,
  clubId,
  handleFormFieldsTouched,
}) => {
  const gridContainerClassName = 'tablet:w-1/2 desktop:w-2/5';

  const { t } = useTranslation();

  const [expandedResult, setExpandedResult] = useState<string | null>(null);

  const { error: errorCountries } = useDISCountries();

  const { currentPage, setCurrentPage, handleSearch } = useClubAddMemberFilters(
    setFilters
  );

  const {
    data,
    error: errorSearch,
    loading: loadingSearch,
  } = useSearchIndividual(filters, currentPage, PAGE_SIZE, searchNonMembers);

  const isError = Boolean(errorCountries || errorSearch);

  useErrorHandling(
    t(
      'add-member.identify-member-form.temporary-error',
      'A temporary error occurred.'
    ),
    isError
  );

  const totalCount = data?.searchIndividual.totalCount || 0;

  const results = data?.searchIndividual.results;

  const resultListRef = useRef<HTMLElement | null>(null);

  const scrollToElement = (
    elementRef: MutableRefObject<HTMLElement | null>
  ) => {
    const currentElement = elementRef.current;

    const elementTopValue = currentElement?.getBoundingClientRect().top || 0;
    const elementOffsetHeightValue = currentElement?.offsetHeight || 0;

    window.scrollTo(
      0,
      elementTopValue + window.scrollY - elementOffsetHeightValue
    );
  };

  const pageHandler = (event: React.SyntheticEvent, pageNumber: number) => {
    event.preventDefault();
    setCurrentPage(pageNumber);
    scrollToElement(resultListRef);
  };

  const handleCollapse = (uniqueIdentifier: string) => {
    expandedResult !== uniqueIdentifier
      ? setExpandedResult(uniqueIdentifier)
      : setExpandedResult(null);
  };

  return (
    <>
      <div className="mt-5">
        <LinkPrevious
          path={getMembershipCandidatePath(clubId)}
          label={t('page-steps.back-button', 'Back')}
        />
        <h2 className="mt-5">
          {t('mml.add-member-form.title', 'Add new candidate')}
        </h2>
      </div>
      <div className="mb-4 desktop:mb-8 desktop:w-5/5">
        <p className="mb-5">
          {t(
            'mml.add-member-form.info',
            'First, determine if the person is already in Rotary’s database. Enter any information you have for them — only one <br>field is required.</br>'
          )}
        </p>
        <p className="font-bold mt-4">
          {t(
            'mml.add-member-form.latin-alert',
            'Note that this form accepts information only in the Latin alphabet.'
          )}
        </p>
      </div>

      <SearchForm
        classes={gridContainerClassName}
        filters={filters}
        loadingSearch={loadingSearch}
        searchHandler={handleSearch}
        handleFormFieldsTouched={handleFormFieldsTouched}
      />

      {loadingSearch && <Loading className={gridContainerClassName} />}

      {data && (
        <ResultsList
          darkBackground
          summary={
            <span ref={resultListRef} data-cy="results-count" className="pr-4">
              {t(
                'mml.identify-member-form.show_results-html',
                '<b>RESULTS ({{ totalCount }})</b>',
                { totalCount }
              )}
            </span>
          }
        >
          {totalCount === 0 && (
            <ResultsListItem key={0}>
              <span data-cy="no-results-message">
                {t(
                  'mml.identify-member-form.no-results-message',
                  'We didn’t find this person in our records. Check the spelling of their name and try again. You can also add the person as a new candidate.'
                )}
              </span>
            </ResultsListItem>
          )}
          {results?.map(result => {
            const highlightedMembership =
              result.activeMemberships.find(
                member =>
                  member.type === MembershipType.Member &&
                  !member.terminationDate
              ) || result.activeMemberships.find(() => true);

            const uniqueIdentifier = result.email ? `${result.email}` : uuid();
            const isTerminated = isTerminationBeforeCurrentDate(
              highlightedMembership?.terminationDate
            );
            return (
              <ResultsListItem key={uniqueIdentifier}>
                <IdentifyLeadsMemberResult
                  uniqueIdentifier={uniqueIdentifier}
                  collapseHandler={handleCollapse}
                  name={result.name}
                  email={result.email || ''}
                  riIndividualId={result.riIndividualId}
                  address={result.address || {}}
                  activeMemberships={result.activeMemberships}
                  phoneNumber={result.phoneNumber || undefined}
                  photoUri={result.photoUri}
                  selectMember={() => submitHandler(result.id)}
                  expanded={
                    data?.searchIndividual?.totalCount === 1 ||
                    expandedResult === uniqueIdentifier
                  }
                  sharingPermission={result.sharingPermission || null}
                  isTerminated={isTerminated}
                  clubName={clubName}
                />
              </ResultsListItem>
            );
          })}
          {totalCount > 5 && (
            <Pagination
              pageSize={PAGE_SIZE}
              page={currentPage}
              totalCount={data?.searchIndividual?.totalCount}
              pageHandler={pageHandler}
            />
          )}
        </ResultsList>
      )}
      <div className={gridContainerClassName}>
        {data && (
          <>
            {totalCount > 0 && (
              <p className="mt-8 mb-4 desktop:mb-8" data-cy="not-found">
                {t(
                  'mml.identify-member-form.not-found-spelling-check',
                  `If the person you searched for isn’t in these results, check the spelling of their name and try again. You can also add the person as a new candidate.`
                )}
              </p>
            )}
            <Button
              clickHandler={() => submitHandler(undefined)}
              secondary
              full
              className="mb-4 desktop:mb-8 mt-11"
            >
              {t(
                'mml.identify-member-form.add-new-candidate-button',
                'ADD NEW CANDIDATE'
              )}
            </Button>
          </>
        )}
        {children}
      </div>
    </>
  );
};

export default IdentifyCandidate;
