import { WeekdayFull } from '@typings/graphql';
import { TFunction } from 'i18next';

export const getDayLabel = (t: TFunction, day: string) => {
  const translations: Record<string, string> = {
    [WeekdayFull.Monday]: t('global.weekdayfull.monday', 'Monday'),
    [WeekdayFull.Tuesday]: t('global.weekdayfull.tuesday', 'Tuesday'),
    [WeekdayFull.Wednesday]: t('global.weekdayfull.wednesday', 'Wednesday'),
    [WeekdayFull.Thursday]: t('global.weekdayfull.thursday', 'Thursday'),
    [WeekdayFull.Friday]: t('global.weekdayfull.friday', 'Friday'),
    [WeekdayFull.Saturday]: t('global.weekdayfull.saturday', 'Saturday'),
    [WeekdayFull.Sunday]: t('global.weekdayfull.sunday', 'Sunday'),
  };
  return translations[day] || '';
};
