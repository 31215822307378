// Libs
import React from 'react';

// Components
import TextArea from '@components/Formik/TextArea';
import ToggleField from '@components/Formik/ToggleField';
import TextField from '@components/Formik/TextField';
import WeekdaySelect from '@components/Formik/Select/WeekdaySelect';
import MeetingTypeSelect from '@components/Formik/Select/MeetingTypeSelect';
import CountrySelect from '@components/Formik/Select/CountrySelect';
import StateSelect from '@components/Formik/Select/StateSelect';
import LanguageSelect from '@components/Formik/Select/LanguageSelect';

import { useTranslation } from '@external/react-i18next';

// Utils
import { zipCodeIsRequired } from '@utils/validation';

// Types
import { MeetingType } from '@typings/operations';
import { MeetingFormValues } from './types';

const MeetingFormFields: React.FC<{
  values: MeetingFormValues;
}> = ({ values }) => {
  const { t } = useTranslation();

  return (
    <>
      <h2>{t('club-editmeeting.overview-title', 'Overview')}</h2>
      <TextField
        name="name"
        label={t('club-editmeeting.form.name.label', 'Meeting Name')}
      />
      <LanguageSelect
        name="languageId"
        label={t('club-editmeeting.form.language.label', 'Language')}
        required
      />
      <TextArea
        name="comment"
        label={t('club-editmeeting.form.comment.label', 'Notes')}
      />
      <h2>{t('club-editmeeting.when-title', 'When')}</h2>
      <WeekdaySelect
        name="weekday"
        label={t('club-editmeeting.form.weekday.label', 'Meeting Day')}
        required
      />
      <TextField
        name="frequency"
        label={t('club-editmeeting.form.frequency.label', 'Frequency')}
      />
      <TextField
        name="time"
        label={t('club-editmeeting.form.time.label', 'Time')}
        required
      />
      <h2>{t('club-editmeeting.where-title', 'Where')}</h2>
      <MeetingTypeSelect
        name="type"
        label={t('club-editmeeting.form.type.label', 'Meeting Type')}
        required
      />
      {(values.type === MeetingType.Physical ||
        values.type === MeetingType.Hybrid) && (
        <>
          <div className="flex justify-between my-8">
            <h5>
              {t(
                'club-editmeeting.form.location-varies.label',
                'The location changes meeting to meeting'
              )}
            </h5>
            <ToggleField name="locationVaries" label="" />
          </div>
          {values.locationVaries ? (
            <TextArea
              label={t(
                'club-editmeeting.form.location-details.label',
                'Location Details'
              )}
              name="locationNameOrDetail"
              required
            />
          ) : (
            <>
              <TextField
                label={t(
                  'club-editmeeting.form.location-name.label',
                  'Meeting Location Name'
                )}
                name="locationNameOrDetail"
              />
              <TextField
                label={t(
                  'club-editmeeting.form.address.label',
                  'Meeting Address'
                )}
                name="lineOne"
                required
              />
              <TextField
                label={t(
                  'club-editmeeting.form.adress.label-two',
                  'Address line two'
                )}
                name="lineTwo"
                labelHidden
              />
              <TextField
                label={t(
                  'club-editmeeting.form.adress.label-three',
                  'Address line three'
                )}
                name="lineThree"
                labelHidden
              />
            </>
          )}
          <CountrySelect
            name="countryId"
            label={t(
              'club-editmeeting.form.address.country-label',
              'Country/Region'
            )}
            stateName="stateId"
            required
          />
          <TextField
            name="city"
            label={t('club-editmeeting.form.address.city-label', 'City')}
            required
          />
          {values?.countryId && (
            <StateSelect
              countryId={values?.countryId || ''}
              name="stateId"
              label={t(
                'club-editmeeting.form.state-label',
                'State/Province/Territory'
              )}
              textStateName="internationalProvince"
              textStateLabel={t(
                'club-editmeeting.form.address.state-label',
                'State/Province/Territory'
              )}
              hasStatesName="address.hasStates"
              required
            />
          )}
          <TextField
            name="postalCode"
            label={t(
              'edit-contact-information.address.form.postalCode-label',
              'Postal Code'
            )}
            required={zipCodeIsRequired(values?.countryId)}
          />
        </>
      )}
      {(values.type === MeetingType.Online ||
        values.type === MeetingType.Hybrid) && (
        <>
          <TextArea
            name="detailForJoiningRemotely"
            label={t(
              'club-editmeetint.form.remote-details',
              'Details for Joining Remotely'
            )}
            required
          />
          <TextField
            name="onlineLocation"
            label={t('club-editmeeting.form.online-location.label', 'URL')}
            required
          />
        </>
      )}
    </>
  );
};

export default MeetingFormFields;
