import React, { useState } from 'react';

import { ApolloClient, InMemoryCache, useApolloClient } from '@apollo/client';
import { Form, Formik } from 'formik';

import MeetingFormFields from '@presenters/web/components/ClubsMeetingForm/MeetingFormFields';
import MeetingPreview from '@presenters/web/components/ClubsMeetingForm/MeetingPreview';
import { MeetingFormValues } from '@presenters/web/components/ClubsMeetingForm/types';
import validationSchema from '@presenters/web/components/ClubsMeetingForm/validationSchema';
import ClubsSaveChangesButtons from '@presenters/web/components/ClubsSaveChangesButtons';

import { mapMeetingValuesToContent } from '@domain/clubs';
import { DISPLAY_TYPE } from '@domain/common';

import i18n from '@i18n';

import { useTranslation } from '@external/react-i18next';
import { useDisplayNamesFromCache } from '@hooks/useDisplayNames';

import { Country } from '@typings/operations';

type Props = {
  initialValues: MeetingFormValues;
  countries: Country[];
  handleSubmit: (values: MeetingFormValues) => void;
  onCancelClick: () => void;
};

const MeetingForm: React.FC<Props> = ({
  initialValues,
  countries,
  handleSubmit,
  onCancelClick,
}) => {
  const { t } = useTranslation();

  const client = useApolloClient();
  const initialLanguageId = initialValues.languageId;
  const [languageId, setLanguageId] = useState(initialLanguageId);
  const isDisplayNamesFromCache = languageId !== initialLanguageId;
  const { displayNames } = useDisplayNamesFromCache(
    client as ApolloClient<InMemoryCache>,
    isDisplayNamesFromCache,
    i18n.language,
    DISPLAY_TYPE.LANGUAGE
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema(t)}
    >
      {({ values, isSubmitting, isValid, dirty }) => {
        setLanguageId(values.languageId);
        return (
          <Form>
            <div className="flex flex-col mt-8 desktop:flex-row">
              <div className="desktop:w-3/5 desktop:px-6 desktop:mr-8 w-full">
                <MeetingFormFields values={values} />
                <ClubsSaveChangesButtons
                  isSubmitting={isSubmitting}
                  onCancelClick={onCancelClick}
                  isValid={isValid && dirty}
                />
              </div>
              <div className="desktop:w-2/5 w-full">
                <h2>{t('club-editmeeting.preview-title', 'Preview')}</h2>
                <div className="border border-gray-300 py-4 px-3 mb-4 break-all">
                  <MeetingPreview
                    meeting={mapMeetingValuesToContent(
                      displayNames,
                      countries,
                      values
                    )}
                  />
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default MeetingForm;
