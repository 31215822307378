import React, { useEffect, useState } from 'react';

import { Formik, FormikConfig, FormikValues } from 'formik';
import { ObjectSchema } from 'yup';
import * as Yup from 'yup';

import { useTranslation } from '@external/react-i18next';

/**
 * Extra properties that can be added to wizard pages.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type WizardPageExtraProps = { [key: string]: any };

/**
 * The full set of properties that is passed to a wizard page.
 */
type WizardPageProps<ExtraProps extends WizardPageExtraProps> = {
  /**
   * The current step this page is displayed in.
   */
  step: number;
  setStep: (step: number) => void;
  /**
   * Boolean flag, indicating if this is the last wizard step.
   */
  isLastStep: boolean;
  /**
   * Indicates if the current page is valid.
   */
  isValid: boolean;
  /**
   * Indicates if the form is currently validating or submitting.
   */
  isProcessing: boolean;
  /**
   * Handler to trigger the next step.
   */
  proceed: () => void;

  /**
   *
   * Handler for back click or cancel
   */
  handleBack: () => void;

  setOtherEmails?: (emails: string[]) => void;
  handleBackArrowClick?: (
    step: number,
    setStep: (step: number) => void
  ) => void;
  handleFormFieldsTouched?: (isTouched: boolean) => void;
  handleCancelBtnClick?: (e?: React.MouseEvent) => void;
  confirmationModal?: (
    step: number,
    setStep: (step: number) => void
  ) => JSX.Element;
  checkPopup?: (step: number, setStep: (step: number) => void) => void;
} & ExtraProps;

/**
 * Type definition for a wizard page.
 */
export type WizardPage<
  Values extends FormikValues,
  ExtraProps extends WizardPageExtraProps = {}
> = React.FC<WizardPageProps<ExtraProps>> & {
  schema?: ObjectSchema<Partial<Values>>;
};

/**
 * Property type definition for a the whole wizard.
 */
export type WizardProps<
  Values extends FormikValues,
  ExtraProps extends WizardPageExtraProps = {}
> = {
  origin?: string;
  pageProps?: ExtraProps;
  pages: WizardPage<Values, ExtraProps>[];
  setOtherEmails?: (emails: string[]) => void;
  handleBackArrowClick?: (
    step: number,
    setStep: (step: number) => void
  ) => void;
  handleFormFieldsTouched?: (isTouched: boolean) => void;
  handleCancelBtnClick?: (e?: React.MouseEvent) => void;
  confirmationModal?: (
    step: number,
    setStep: (step: number) => void
  ) => JSX.Element;
  checkPopup?: (step: number, setStep: (step: number) => void) => void;
} & Pick<FormikConfig<Values>, 'initialValues' | 'onSubmit'>;

const Wizard = <Values, ExtraProps = {}>(
  props: WizardProps<Values, ExtraProps>
) => {
  const { t } = useTranslation();
  const [step, setStepInternal] = useState<number>(0);
  const setStep = (step: number) => {
    setStepInternal(step);
  };

  const {
    pages,
    pageProps,
    setOtherEmails,
    handleBackArrowClick,
    handleFormFieldsTouched,
    handleCancelBtnClick,
    confirmationModal,
    checkPopup,
  } = props;

  const pagesCount = pages.length;

  const isFirstStep = step === 0;
  const isLastStep = step === pagesCount - 1;

  useEffect(() => {
    checkPopup?.(step, setStep);
  }, [checkPopup]);

  const handleBack = () => {
    if (isFirstStep) {
      // eslint-disable-next-line no-restricted-globals
      history.back();
    } else {
      setStep(step - 1);
    }
  };

  const schema = Yup.object(
    pages
      .map((page, index) => (index <= step ? page.schema?.fields || {} : {}))
      .reduce((acc, val) => ({ ...acc, ...val }), {})
  );

  const Page = pages[step];

  return (
    Page && (
      <Formik {...props} validationSchema={schema} validateOnChange>
        {formProps => {
          const fields = (Object.keys(Page.schema?.fields || {}) || []) as [
            keyof Values
          ];

          const { dirty, initialTouched } = formProps;

          const isValid =
            fields.map(key => formProps.errors[key]).filter(err => !!err)
              .length === 0;

          const proceed = () => {
            fields.forEach(field => formProps.setFieldTouched(field as string));

            formProps.validateForm().then(errors => {
              if (
                fields.map(key => errors[key]).filter(err => !!err).length === 0
              ) {
                if (isLastStep) {
                  formProps.submitForm();
                } else {
                  setStep(step + 1);
                }
              }
            });
          };

          return (
            <form
              onSubmit={evt => {
                evt.preventDefault();
                proceed();
              }}
            >
              <div className="mt-8">
                {pagesCount > 1 && (
                  <p className="text-small font-bold">
                    <button
                      className="inline-flex items-center font-bold text-bright-blue-600 text-xs leading-xs-heading"
                      onClick={
                        handleBackArrowClick
                          ? () => handleBackArrowClick(step, setStep)
                          : handleBack
                      }
                      type="button"
                    >
                      &#8249; {t('page-steps.back-button', 'Back')}
                    </button>

                    <span className="inline-block mx-1">&#124;</span>
                    {t(
                      'pages-steps.step-X-of-Y-total-steps',
                      'Step {{current}} of {{total}}',
                      {
                        current: step + 1,
                        total: pagesCount,
                      }
                    )}
                  </p>
                )}
                {Page && (
                  <Page
                    proceed={proceed}
                    step={step}
                    setStep={setStep}
                    isProcessing={
                      !!(formProps.isSubmitting || formProps.isInitialValid)
                    }
                    isLastStep={isLastStep}
                    isValid={isValid}
                    dirty={dirty}
                    intialTouched={initialTouched}
                    setOtherEmails={setOtherEmails}
                    handleBackArrowClick={handleBackArrowClick}
                    handleFormFieldsTouched={handleFormFieldsTouched}
                    handleCancelBtnClick={handleCancelBtnClick}
                    confirmationModal={confirmationModal}
                    checkPopup={checkPopup}
                    handleBack={handleBack}
                    {...(pageProps as ExtraProps)}
                  />
                )}
              </div>
            </form>
          );
        }}
      </Formik>
    )
  );
};

export default Wizard;
