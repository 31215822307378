import { TFunction } from 'i18next';

import { MembershipType } from '@typings/graphql';

export const getMembershipTypeLabel = (
  t: TFunction,
  membershipType: MembershipType
): string => {
  const translations: Record<MembershipType, string> = {
    [MembershipType.Member]: t('membership.type.full-member', 'Member'),
    [MembershipType.Honorary]: t('membership.type.honorary', 'Honorary'),
    [MembershipType.Rotaractor]: t('membership.type.full-member', 'Member'),
    [MembershipType.RotaractHonorary]: t(
      'membership.type.honorary',
      'Honorary'
    ),
  };
  return translations[membershipType];
};
