import React from 'react';

import { RouteComponentProps } from '@reach/router';
import { toString } from 'lodash';
import moment from 'moment';

import Wizard from '@components/Formik/Wizard';
import Loading from '@components/Loading';

import ConfirmationForm from './ConfirmationForm';
import InformationForm from './InformationForm';
import {
  confirmationFormSchema,
  informationFormSchema,
} from './validationShema';

import {
  ROTARACT_HONORARY_TERMINATION_REASON_ID,
  ROTARY_HONORARY_TERMINATION_REASON_ID,
  TerminateMembershipValues,
} from '@domain/clubs';

import { getClubMembersPath, isHonoraryMember } from '@use-cases/clubs';
import { useNotifications } from '@use-cases/notifications';

import {
  useFetchMembershipInfo,
  useTerminateMembership,
} from '@repositories/clubs';

import { localizedNavigate } from '@utils/localized-navigate';

import { useTranslation } from '@external/react-i18next';

import { MembershipType } from '@typings/operations';

export type TerminateMembershipProps = {
  clubId: string;
  membershipId: string;
} & RouteComponentProps;

const TerminateMembership: React.FC<TerminateMembershipProps> = ({
  clubId,
  membershipId,
}) => {
  const { t } = useTranslation();
  const { data, loading } = useFetchMembershipInfo(clubId, membershipId);

  const {
    terminateMembership,
    error: errorTerminateMembership,
  } = useTerminateMembership();
  const { addError, addSuccess } = useNotifications();

  if (loading) {
    return <Loading />;
  }

  const { admissionDate, type: membershipType, club, individual } =
    data?.membership || {};

  const { clubType } = club || {};

  const { name: memberName } = individual || {};

  const isHonorary = isHonoraryMember(membershipType);

  const terminatedOtherId =
    membershipType === MembershipType.RotaractHonorary
      ? ROTARACT_HONORARY_TERMINATION_REASON_ID
      : ROTARY_HONORARY_TERMINATION_REASON_ID;

  InformationForm.schema = informationFormSchema(t, isHonorary);
  ConfirmationForm.schema = confirmationFormSchema(t);

  const handleSubmit = async (values: TerminateMembershipValues) => {
    if (clubId && membershipId) {
      await terminateMembership({
        variables: {
          clubId,
          membershipId,
          terminationDate: moment(values.terminationDate).format('YYYY-MM-DD'),
          terminationReasonId: !isHonorary
            ? values.terminationReason
            : terminatedOtherId, // "Terminated - Other"
        },
      });

      if (errorTerminateMembership) {
        addError(
          t(
            'terminate-member.submit-terminate-member-form.error',
            'An error happened.'
          ),
          { id: 'form.error' }
        );

        addError(errorTerminateMembership.message);
      } else {
        addSuccess(
          t(
            'terminate-member.submit-terminate-member-form.success',
            'Success.'
          ),
          { id: 'form.success' }
        );

        localizedNavigate(getClubMembersPath(clubId));
      }
    }
  };

  return (
    (clubId && clubType && (
      <Wizard
        initialValues={{
          terminationReason: '',
          terminationConfirmation: false,
        }}
        onSubmit={handleSubmit}
        pages={[InformationForm, ConfirmationForm]}
        pageProps={{
          clubId,
          clubType,
          admissionDate: toString(admissionDate),
          memberName: toString(memberName),
          membershipType: toString(membershipType),
        }}
      />
    )) ||
    null
  );
};

export default TerminateMembership;
