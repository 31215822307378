import { TFunction } from 'i18next';
import * as yup from 'yup';

import addressValidationSchema from '@components/Formik/Address/validation';
import {
  emailSchema,
  numberSchema,
} from '@components/Formik/validation/fieldDefinitions';
import {
  isRequired,
  isURL,
  schemaField,
} from '@components/Formik/validation/schemaDefinitions';

const editClubContactValidationSchema = (t: TFunction) =>
  yup.object().shape({
    address: addressValidationSchema(t),
    primaryEmail: emailSchema(t),
    primaryWebsite: schemaField(t, yup.string(), isURL),
    primaryPhone: yup.object().shape({
      country: yup.string().when('number', {
        is: (phoneNumber: string) => !!phoneNumber,
        then: schemaField(t, yup.string(), isRequired),
      }),
      number: numberSchema(t),
    }),
    primaryFax: yup.object().shape({
      country: yup.string().when('number', {
        is: (faxNumber: string) => !!faxNumber,
        then: schemaField(t, yup.string(), isRequired),
      }),
      number: numberSchema(t),
    }),
  });

export default editClubContactValidationSchema;
