import React, { useEffect, useState } from 'react';

import { useField } from 'formik';
import moment from 'moment';

import ActionButtons from '@components/ActionButtons';
import Select from '@components/Formik/Select';
import { WizardPage } from '@components/Formik/Wizard';
import IndividualListColumn from '@components/IndividualListColumn';
import SelectTerm from '@presenters/web/components/SelectTerm';

import {
  Club,
  Entity,
  Individual,
  Period,
  SelectRoleValues,
  UnassignedRole,
} from '@domain/clubs/types';
import { TermToAssign } from '@domain/districts';

import {
  getClubMembersPath,
  getUnassignedClubOfficerRoles,
  useSelectableRoleOptions,
} from '@use-cases/clubs';
import { getTermByYear } from '@use-cases/districts';

import { useFetchClubOfficers } from '@repositories/clubs';

import { getRotaryYear } from '@utils/datetime';
import { getClubOfficerCalendarStartDate } from '@utils/getClubOfficerCalendarStartDate';

import { useTranslation } from '@external/react-i18next';

import { MembershipType } from '@typings/operations';

const SelectRole: WizardPage<SelectRoleValues> = ({ proceed }) => {
  const { t } = useTranslation();
  const [club] = useField<Club>('club');
  const [, , { setValue: setRole }] = useField<Entity>('role');
  const [, , { setValue: setSlot }] = useField<Period>('slot');
  const [selectedTerm, , { setValue: setTerm }] = useField<TermToAssign>(
    'selectedTerm'
  );
  const [selectedRole, , { setValue: setSelectedRole }] = useField<string>(
    'selectedRole'
  );
  const [individual] = useField<Individual>('individual');
  const [type] = useField<
    | MembershipType.Member
    | MembershipType.Honorary
    | MembershipType.RotaractHonorary
  >('type');

  const [availableClubOfficerRoles, setAvailableClubOfficerRoles] = useState<
    UnassignedRole[]
  >([]);

  const selectedTermStartYear = Number(selectedTerm.value.startDate);

  const { data: clubOfficersData, loading, error } = useFetchClubOfficers({
    clubId: club.value.id,
    termStartYear: selectedTermStartYear,
    roleValidOn: `${selectedTermStartYear}-07-01`,
    clubType: club.value.clubType,
  });

  useEffect(() => {
    if (clubOfficersData) {
      const { roles, club } = clubOfficersData;
      const unassignedClubOfficerRoles = getUnassignedClubOfficerRoles(
        club.leaders || [],
        roles || [],
        selectedTermStartYear
      );

      setAvailableClubOfficerRoles(unassignedClubOfficerRoles);
    }
  }, [clubOfficersData]);

  const currentTermStartYear = Number(getRotaryYear());

  const onChangeTerm = (value: TermToAssign) => {
    setTerm(value);
    setSelectedRole('');
  };

  const handleGoToNextStep = () => {
    const [id, name] = selectedRole.value.split(':');
    const { startDate, endDate } =
      availableClubOfficerRoles.find(role => role.id === id)?.term || {};
    const { membershipAdmissionDate } = individual.value;
    setSlot({
      start: getClubOfficerCalendarStartDate(
        startDate,
        membershipAdmissionDate
      ),
      end: moment(endDate),
    });
    setRole({ id, name });
    proceed();
    window.scrollTo(0, 0);
  };

  return (
    <>
      <h2 className="text-xl my-2 leading-6">
        {t('club-add-officer.select-role.title', 'Select a Role for a Member')}
      </h2>

      {individual && (
        <IndividualListColumn
          fullName={individual.value.name}
          photoUri={individual.value.photoUri || ''}
          id={individual.value.id}
          usingFlex
          wrapperClasses="my-7"
        />
      )}

      <div className="max-w-lg">
        <SelectTerm
          termsSelector="selectedTerm"
          termsToAssign={[
            getTermByYear(currentTermStartYear),
            getTermByYear(currentTermStartYear + 1),
          ]}
          selectedTerm={selectedTerm.value}
          onChangeTerm={onChangeTerm}
          classes={{ optionsWrapper: 'mb-3' }}
        />
        <Select
          name="selectedRole"
          label={t(
            'club-add-officer.select-role.role-label',
            'Available club officer roles'
          )}
          notice={t(
            'club-add-officer.select-role.required-roles-notice',
            '* Represents the Role that is required by Policy'
          )}
          searchable
          options={useSelectableRoleOptions(
            availableClubOfficerRoles,
            selectedTerm.value.endDate,
            type.value,
            loading,
            error,
            t
          )}
          hasLabelMargin={false}
          filterOption={(option, input) =>
            new RegExp(input, 'i').test(option.label.toString())
          }
        />
        <ActionButtons
          wrapperClassNames="flex flex-col mt-13"
          submitBtnLabel={t(
            'club-add-officer.select-role.continue-button',
            'Continue'
          )}
          cancelBtnLabel={t(
            'club-add-officer.select-role.cancel-button',
            'Cancel'
          )}
          isSubmitDisabled={!selectedTerm.value || !selectedRole.value}
          cancelUrl={getClubMembersPath(club.value.id)}
          onSubmit={handleGoToNextStep}
        />
      </div>
    </>
  );
};

export default SelectRole;
