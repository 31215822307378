// Libs
import React from 'react';

// Components
import LinkEdit from '@components/LinkEdit';

// Hooks
import { useTranslation } from '@external/react-i18next';
import { VendorItem } from '@domain/clubs';

interface Props {
  isEditable: boolean;
  vendors: VendorItem[];
  editManagementSystemsLink: string;
}

const ClubManagementSystems: React.FC<Props> = ({
  isEditable,
  vendors,
  editManagementSystemsLink,
}) => {
  const { t } = useTranslation();
  const title = t(
    'club-details.club-management-systems.title',
    'Club Management Systems'
  );

  return (
    <div>
      <div className="flex justify-between">
        <h2>{title}</h2>
        {isEditable && (
          <LinkEdit path={editManagementSystemsLink} component={title} />
        )}
      </div>
      <h3 className="h5">
        {t('club-details.club-management-systems.vendors-tag', 'Vendors')}
      </h3>
      {vendors?.length ? (
        vendors.map(({ name }, index: number) => (
          <li key={`${name}-${index}`}>{name}</li>
        ))
      ) : (
        <p>{t('club-details.club-management-systems.vendors-none', 'None')}</p>
      )}
    </div>
  );
};

export default ClubManagementSystems;
