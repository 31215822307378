const channel: {
  [key: string]: {
    channel: BroadcastChannel;
    isClose: Boolean;
  };
} = {};

export const getChannel = (channelName: string) => {
  if (channel?.[channelName] && !channel[channelName].isClose) {
    return channel[channelName].channel;
  }

  channel[channelName] = {
    isClose: false,
    channel: new BroadcastChannel(channelName),
  };

  return channel[channelName].channel;
};

export const closeChannel = (channelName: string) => {
  if (channel?.[channelName]) {
    channel[channelName].isClose = true;
    channel[channelName].channel.close();
  }
};
