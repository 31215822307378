// Libs
import React from 'react';

// Components
import { Button } from '@components/Button';
import TableWrapper from '@components/Table';
import Loading from '@components/Loading';

// Hooks
import { useTranslation } from '@external/react-i18next';
import { useFetchClubVendors } from '@repositories/clubs';

// Utils
import { isEditLevel } from '@utils/access-helpers';

interface ManagementSystemsTableProps {
  clubId?: string;
  handleDelete: (vendorId: string, requestorKey: string) => void;
}

const ManagementSystemsTable: React.FC<ManagementSystemsTableProps> = ({
  clubId,
  handleDelete,
}) => {
  const { t } = useTranslation();
  const { data, loading } = useFetchClubVendors(clubId!);

  if (loading || !data || !data.clubById?.vendors) {
    return <Loading />;
  }

  const canEditManagementSystems = isEditLevel(
    data.clubById.operationsAccess?.club
  );

  const getAccessType = (requestor: any) => {
    return requestor.get && requestor.edit
      ? t('club-editmanagementsystems.access-type.edit', 'View & Edit')
      : t('club-editmanagementsystems.access-type.view', 'View-Only');
  };

  const getAccessFor = (type: string) => {
    return type === 'District'
      ? t('club-editmanagementsystems.table-values.district', 'My District')
      : t('club-editmanagementsystems.table-values.club', 'My Club');
  };

  return (
    <TableWrapper>
      <table>
        <thead>
          <tr>
            <th>
              {t('club-editmanagementsystems.table-header.vendor', 'Vendor')}
            </th>
            <th>
              {t(
                'club-editmanagementsystems.table-header.start-date',
                'Start Date'
              )}
            </th>
            <th>
              {t(
                'club-editmanagementsystems.table-header.access-type',
                'Access Type'
              )}
            </th>
            <th>
              {t(
                'club-editmanagementsystems.table-header.access-for',
                'Access For'
              )}
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          {data.clubById.vendors.map((vendor, vendorIndex) => (
            <>
              {vendor.requestors.length ? (
                vendor.requestors.map((requestor, requestorIndex) => (
                  <tr key={`${vendorIndex}-${requestorIndex}`}>
                    <td>{vendor.vendorName}</td>
                    <td>{vendor.startDate}</td>
                    <td>{getAccessType(requestor)}</td>
                    <td>{getAccessFor(requestor.organizationType)}</td>
                    <td>
                      {canEditManagementSystems && (
                        <Button
                          small
                          text
                          clickHandler={() => {
                            handleDelete(vendor.vendorKey, requestor.key);
                          }}
                        >
                          {t(
                            'club-editmanagementsystems.terminate-button.label',
                            'Terminate'
                          )}
                        </Button>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                /*
                At the moment it's not clear what to do with vendors having
                no requestors (MRX-1377). For now we just make them readonly.
                */
                <tr key={vendorIndex}>
                  <td>{vendor.vendorName}</td>
                  <td>{vendor.startDate}</td>
                  <td></td>
                  <td></td>
                  <td>
                    {canEditManagementSystems && (
                      <Button small text disabled>
                        {t(
                          'club-editmanagementsystems.terminate-button.label',
                          'Terminate'
                        )}
                      </Button>
                    )}
                  </td>
                </tr>
              )}
            </>
          ))}
          {canEditManagementSystems && (
            <tr>
              <td colSpan={6} className="text-right">
                <Button
                  icon="ui/plus"
                  small
                  secondary
                  to={`/club/${clubId}/add-vendor`}
                >
                  {t(
                    'club-editmanagementsystems.form.add-vendor',
                    'Add vendor'
                  )}
                </Button>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </TableWrapper>
  );
};

export default ManagementSystemsTable;
