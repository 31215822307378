import React, { useEffect, useMemo, useRef, useState } from 'react';

import { RouteComponentProps } from '@reach/router';
import moment from 'moment';

import { Button } from '@components/Button';
import { useAvailableSponsorsQueryLazy } from '@components/Forms/Select/SponsorSelect/hooks';
import Loading from '@components/Loading';
import LeaveFormConfirmationModal from '@components/Modals/LeaveFormConfirmationModal';
import PageSteps from '@components/PageSteps';
import MemberDetails from '@presenters/web/pages/Leads/MMLPage/AddLeadAsNewClubMember';
import MembershipInformationLeads from '@presenters/web/pages/Leads/MMLPage/AddLeadAsNewClubMember/MembershipInformationClub';

import {
  mapMemberValuesToFormInput,
  MemberDetailsInput,
  MemberDetailsOutput,
  MembershipData,
} from '@domain/clubs';

import { useModal, useStopBrowserNavigate } from '@use-cases/districts';
import {
  getCandidateDetailsPath,
  getMembershipCandidatePath,
} from '@use-cases/leads';
import { useErrorHandling, useNotifications } from '@use-cases/notifications';

import {
  useCreateIndividual,
  useCreateMembership,
  useFetchMemberDetails,
  useUpdateIndividual,
} from '@repositories/clubs';

import { localizedNavigate } from '@utils/localized-navigate';
import { getChannel } from '@utils/sendMessage';

import { useTranslation } from '@external/react-i18next';
import { useFetchRiIndividualId } from '@hooks/useFetchRiIndividualId';

import { UpdateIndividualMutationVariables } from '@typings/operations';

interface ClubAddMemberPageProps extends RouteComponentProps {
  clubId: string;
  isRotaractClub: boolean;
  riClubId: string;
}

const CandidateNonClubMember: React.FC<ClubAddMemberPageProps> = ({
  clubId: id,
  isRotaractClub,
  riClubId,
}) => {
  const { t } = useTranslation();
  const { addSuccess } = useNotifications();
  const addSuccessRef = useRef(addSuccess);

  enum AddMemberSteps {
    INFO,
    MEMBERSHIP,
  }

  const [step, setStep] = useState<AddMemberSteps>(AddMemberSteps.INFO);
  const [isBackClick, setIsBackClick] = useState(false);
  const isFirstStep = step === AddMemberSteps.INFO;

  // Initiate a state for the current member id.
  // - undefined: no member was selected and the user did not choose to create one
  // - false: no member was selected, the user decided to create a new one
  // - string: the ID of the selected member
  const [selectedMember] = useState<undefined | string | false>(undefined);

  // When a member has been selected, attempt to load data from the api.
  const { data, loading, error } = useFetchMemberDetails(
    selectedMember || '',
    id
  );

  useErrorHandling(error?.message, !!error, 'member-details.error');

  // If member data has been loaded, message it into InitialData.
  const individual = data?.addMemberGetIndividual;

  const initialData = mapMemberValuesToFormInput(individual);

  // Member data that has been reviewed or created by the user.
  const [memberDataReviewed, setMemberDataReviewed] = useState<
    MemberDetailsInput | undefined | null
  >();

  // If we have reviewed member data, massage it into a mutation payload.
  const [memberDataPayload, setMemberDataPayload] = useState<
    MemberDetailsOutput | undefined
  >();

  const [memberId, setMemberId] = useState<undefined | string>();
  const [isFieldTouched, setIsFieldTouched] = useState<boolean>(false);
  const { isShowing, show } = useModal(window.stopBrowserNavigate);

  const handleBackClick = () => {
    if (isFirstStep) {
      localizedNavigate(getCandidateDetailsPath(id));
    } else {
      setStep(step - 1);
    }
  };

  const { globalHide, setGlobal } = useStopBrowserNavigate({
    showModal: show,
    isNextStepVisited: true,
    onNavigate: handleBackClick,
  });

  const [
    createIndividual,
    {
      error: createIndividualError,
      called: createIndividualCalled,
      loading: createIndividualLoading,
    },
  ] = useCreateIndividual();

  const [
    updateIndividual,
    {
      error: updateIndividualError,
      called: updateIndividualCalled,
      loading: updateIndividualLoading,
    },
  ] = useUpdateIndividual();

  const [
    createMembership,
    {
      error: createMembershipError,
      called: createMembershipCalled,
      loading: createMembershipLoading,
    },
  ] = useCreateMembership();

  const [
    fetchRiIndividualId,
    { data: fetchRiIndividualIdData, loading: fetchRiIndividualLoading },
  ] = useFetchRiIndividualId();

  const [fetchSponsorsLazily] = useAvailableSponsorsQueryLazy();

  useEffect(() => {
    if (fetchRiIndividualIdData) {
      setMemberId(String(fetchRiIndividualIdData.getIndividual.riIndividualId));
    }
  }, [fetchRiIndividualIdData]);

  useEffect(() => {
    if (
      createMembershipCalled &&
      !createMembershipError &&
      memberId &&
      ((createIndividualCalled && !createIndividualError) ||
        (updateIndividualCalled && !updateIndividualError))
    ) {
      const localizedName = memberDataReviewed?.localizedName;

      addSuccessRef.current(
        t(
          'add_member.success',
          'Successfully added {{name}} to club with Member ID {{memberId}}.',
          {
            name: localizedName,
            memberId,
          }
        ),
        { id: 'form.success' }
      );

      getChannel('add-member').postMessage(
        t(
          'club-addMember-tab-update.message',
          'Please refresh this browser tab to see updated information.'
        )
      );
      setGlobal(false);
      localizedNavigate(`/club/${id}/roster`);
    }
  }, [
    createMembershipCalled,
    createMembershipError,
    t,
    memberDataReviewed,
    addSuccessRef,
    id,
    createIndividualCalled,
    createIndividualError,
    updateIndividualCalled,
    updateIndividualError,
    memberId,
  ]);

  const modalOnConfirmHandler = () => {
    globalHide();
    if (isBackClick) {
      setIsBackClick(false);
      setStep(step - 1);
    } else {
      localizedNavigate(getMembershipCandidatePath(id));
    }
  };

  const handleCancelClick = () => {
    if (isFieldTouched) {
      setIsBackClick(false);
      show(true);
    } else {
      localizedNavigate(getCandidateDetailsPath(id));
    }
  };

  const handleFormFieldsTouched = (isTouched: boolean) => {
    setIsFieldTouched(isTouched);
  };

  const BackButton = () =>
    useMemo(() => {
      return (
        <>
          <Button clickHandler={handleCancelClick} text full>
            {t('add_member.identify_member_form.cancel_button', 'Cancel')}
          </Button>
          <LeaveFormConfirmationModal
            isOpen={isShowing}
            closeModal={globalHide}
            onConfirm={modalOnConfirmHandler}
          />
        </>
      );
    }, [isShowing]);

  if (
    loading ||
    createIndividualLoading ||
    updateIndividualLoading ||
    createMembershipLoading ||
    fetchRiIndividualLoading
  ) {
    return <Loading />;
  }

  const noPaddingClass = 'p-0';
  const navClassName = 'text-small font-bold mt-4 mb-0';

  const handleSubmit = async (membershipData: Partial<MembershipData>) => {
    let individualId = null;

    const createMembershipVariables = {
      clubId: `${id}`,
      admissionDate:
        membershipData.admissionDate || moment().format('YYYY-MM-DD'),
      individualId,
      sponsorIds: membershipData.sponsoredBy,
      type: membershipData?.memberType || 'Member',
    };

    if (memberDataReviewed && membershipData) {
      if (!selectedMember) {
        const result = await createIndividual({
          variables: memberDataPayload,
        });

        individualId = String(result.data?.createIndividual.individualId);

        await createMembership({
          variables: { ...createMembershipVariables, individualId },
        });
      } else {
        individualId = selectedMember;

        const result = await updateIndividual({
          variables: {
            individualId,
            useInternalKey: true,
            ...(memberDataPayload as Omit<
              UpdateIndividualMutationVariables,
              'individualId'
            >),
          },
        });

        if (result) {
          await createMembership({
            variables: { ...createMembershipVariables, individualId },
          });
        }
      }

      await fetchRiIndividualId({
        variables: {
          individualId,
          delayAfterResponse: 5000,
        },
      });

      fetchSponsorsLazily({
        variables: {
          clubId: riClubId || '',
          name: '',
          admissionDate:
            membershipData.admissionDate || moment().format('YYYY-MM-DD'),
        },
      });
    }
  };

  switch (step) {
    case AddMemberSteps.INFO:
      return (
        <PageSteps
          step={1}
          total={2}
          backHandler={handleBackClick}
          className={noPaddingClass}
          navClassName={navClassName}
        >
          <MemberDetails
            data={memberDataReviewed || initialData}
            initialData={initialData}
            submitHandler={(formData, dataToUpdate) => {
              setStep(AddMemberSteps.MEMBERSHIP);
              setMemberDataReviewed(formData);
              setMemberDataPayload(dataToUpdate);
              window.scrollTo(0, 0);
            }}
            handleFormFieldsTouched={handleFormFieldsTouched}
          >
            <BackButton />
          </MemberDetails>
        </PageSteps>
      );
    case AddMemberSteps.MEMBERSHIP:
      return (
        <PageSteps
          step={2}
          total={2}
          backHandler={handleBackClick}
          className={noPaddingClass}
          navClassName={navClassName}
        >
          <MembershipInformationLeads
            isRotaractClub={isRotaractClub}
            hasActiveMembership={
              memberDataReviewed
                ? memberDataReviewed.hasActiveMembership
                : false
            }
            clubId={id || ''}
            recentMemberships={memberDataReviewed?.recentMemberships || []}
            submitHandler={handleSubmit}
            handleFormFieldsTouched={handleFormFieldsTouched}
          >
            <BackButton />
          </MembershipInformationLeads>
        </PageSteps>
      );
    default:
      return null;
  }
};

export default CandidateNonClubMember;
