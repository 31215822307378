import React from 'react';

import { TFunction } from 'i18next';

import Loading from '@components/Loading';
import ClubTabsLayout from '@presenters/web/components/ClubTabs/ClubTabsLayout';

import { useTranslation } from '@external/react-i18next';
import { useMenu } from '@hooks/menu';

import { Drupal } from '@typings/drupal';

const renderClubGoalsData = (t: TFunction, data: Array<Drupal.MenuItem>) => {
  return data?.map((item: Drupal.MenuItem) => (
    <React.Fragment key={item.id}>
      <ClubTabsLayout
        tabHeader={t('club-details.menu.administration.goals', 'Goals')}
        data={item.children || []}
        classes="mobile-m:w-70 tablet:w-92 desktop:w-92"
      />
    </React.Fragment>
  ));
};

const ClubGoals: React.FC = () => {
  const { t, i18n } = useTranslation();

  const { data: goalsData, loading } = useMenu(
    'menu-club-administration-goals',
    i18n.language,
    false,
    false
  );

  if (loading) return <Loading />;

  return <>{renderClubGoalsData(t, goalsData)}</>;
};

export default ClubGoals;
