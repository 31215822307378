import React, {
  Reducer,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';

import { useField } from 'formik';
import { isUndefined } from 'lodash';

import { Button } from '@components/Button';
import { WizardPage } from '@components/Formik/Wizard';
import Loading from '@components/Loading';
import LeaveFormConfirmationModal from '@components/Modals/LeaveFormConfirmationModal';
import IdentifyMember from '@presenters/web/components/MembershipManagement/IdentifyMember';
import MemberDetails from '@presenters/web/components/MembershipManagement/MemberDetails';
import MembershipInformation from '@presenters/web/components/MembershipManagement/MembershipInformation';

import {
  AddOfficerSteps,
  Entity,
  IdentificationParameters,
  mapMemberValuesToFormInput,
  MemberDetailsInput,
  MemberDetailsOutput,
  UpdateIndividual,
} from '@domain/clubs';

import { getOfficersPagePath } from '@use-cases/clubs';
import { useModal, useStopBrowserNavigate } from '@use-cases/districts';
import { useErrorHandling, useNotifications } from '@use-cases/notifications';

import {
  useCreateIndividual,
  useFetchMemberDetails,
  useUpdateIndividual,
} from '@repositories/clubs';

import { getClubRoleTValue } from '@utils/getClubRoleTValue';
import { localizedNavigate } from '@utils/localized-navigate';

import { useTranslation } from '@external/react-i18next';
import { useFetchRiIndividualId } from '@hooks/useFetchRiIndividualId';

export type AssignNonMemberProps = {
  clubId: string;
  isRotaractClub: boolean;
  clubName: string;
};

const AssignNonMemberToRole: WizardPage<AssignNonMemberProps> = ({
  proceed,
  handleBack,
}) => {
  const { t } = useTranslation();

  const [clubId] = useField<string>('clubId');
  const [selectedIndividualProp] = useField<string>('selectedIndividualProp');

  const id = clubId?.value;
  const [clubName] = useField<string>('clubName');
  const [, , helpers] = useField<UpdateIndividual>('editIndividual');
  const [, , selectedEmailhelper] = useField<string>('email');

  const [isRotaractClubProp] = useField<boolean>('isRotaractClub');
  const isRotaractClub = isRotaractClubProp?.value;
  const [role] = useField<Entity>('role');
  const roleName = getClubRoleTValue(t, role?.value.name || '');
  const [isCreateAssignFlowEligible] = useField<boolean>(
    'isCreateAssignFlowEligible'
  );
  const { addSuccess } = useNotifications();
  const addSuccessRef = useRef(addSuccess);

  const [step, setStep] = useState<AddOfficerSteps>(
    selectedIndividualProp?.value
      ? AddOfficerSteps.INFO
      : AddOfficerSteps.IDENTIFY
  );
  const isFirstStep = step === AddOfficerSteps.IDENTIFY;

  const [filters, setFilters] = useReducer<
    Reducer<IdentificationParameters, IdentificationParameters>
  >((state, action) => {
    return { ...state, ...action };
  }, {});

  // Initiate a state for the current member id.
  // - undefined: no member was selected and the user did not choose to create one
  // - false: no member was selected, the user decided to create a new one
  // - string: the ID of the selected member
  const [selectedMember, selectMember] = useState<undefined | string | false>(
    selectedIndividualProp?.value || false
  );

  // When a member has been selected, attempt to load data from the api.
  const { data, loading, error } = useFetchMemberDetails(
    selectedMember || '',
    id
  );

  useErrorHandling(error?.message, !!error, 'member-details.error');

  // If member data has been loaded, message it into InitialData.
  const individual = data?.addMemberGetIndividual;

  const initialData = mapMemberValuesToFormInput(
    individual,
    filters.firstName,
    filters.lastName,
    filters.email,
    isRotaractClub
  );

  // Member data that has been reviewed or created by the user.
  const [memberDataReviewed, setMemberDataReviewed] = useState<
    MemberDetailsInput | undefined | null
  >();

  // If we have reviewed member data, massage it into a mutation payload.
  const [, setMemberDataPayload] = useState<MemberDetailsOutput | undefined>();

  const [, setMemberId] = useState<undefined | string>();
  const [isFieldTouched, setIsFieldTouched] = useState<boolean>(false);
  const { isShowing, show } = useModal(window.stopBrowserNavigate);

  const handleBackClick = () => {
    if (isFieldTouched) {
      show(true);
    } else if (isFirstStep) {
      localizedNavigate(getOfficersPagePath(id));
    } else {
      setStep(step - 1);
    }
  };

  const { globalHide, setGlobal } = useStopBrowserNavigate({
    showModal: show,
    isNextStepVisited: true,
    onNavigate: handleBackClick,
  });

  const [
    ,
    {
      error: createIndividualError,
      called: createIndividualCalled,
      loading: createIndividualLoading,
    },
  ] = useCreateIndividual();

  const [
    ,
    {
      error: updateIndividualError,
      called: updateIndividualCalled,
      loading: updateIndividualLoading,
    },
  ] = useUpdateIndividual();

  const [
    ,
    { data: fetchRiIndividualIdData, loading: fetchRiIndividualLoading },
  ] = useFetchRiIndividualId();

  useEffect(() => {
    if (fetchRiIndividualIdData) {
      setMemberId(String(fetchRiIndividualIdData.getIndividual.riIndividualId));
    }
  }, [fetchRiIndividualIdData]);

  useEffect(() => {
    if (
      (createIndividualCalled && !createIndividualError) ||
      (updateIndividualCalled && !updateIndividualError)
    ) {
      setGlobal(false);
    }
  }, [
    t,
    memberDataReviewed,
    addSuccessRef,
    id,
    createIndividualCalled,
    createIndividualError,
    updateIndividualCalled,
    updateIndividualError,
  ]);

  const modalOnConfirmHandler = () => {
    globalHide();
    if (isFirstStep) {
      handleBack();
    } else {
      setStep(step - 1);
    }
  };

  const handleFormFieldsTouched = (isTouched: boolean) => {
    setIsFieldTouched(isTouched);
  };

  const handleCancelClick = () => {
    if (isFieldTouched) {
      show(true);
    } else {
      setStep(0);
      handleBack();
    }
  };

  const BackButton = () =>
    useMemo(() => {
      return (
        <>
          <Button type="button" clickHandler={handleCancelClick} text full>
            {t('add_member.identify_member_form.cancel_button', 'Cancel')}
          </Button>
          <LeaveFormConfirmationModal
            isOpen={isShowing}
            closeModal={globalHide}
            onConfirm={modalOnConfirmHandler}
          />
        </>
      );
    }, [isShowing]);

  if (
    loading ||
    createIndividualLoading ||
    updateIndividualLoading ||
    fetchRiIndividualLoading
  ) {
    return <Loading />;
  }

  const handleSubmit = (
    formData: MemberDetailsInput,
    dataToUpdate: MemberDetailsOutput
  ) => {
    if (formData && dataToUpdate) {
      if (selectedMember) {
        const params = {
          id: selectedMember,
          name: formData.localizedName,
          email: formData.email,
          ...dataToUpdate,
        };
        helpers.setValue(params);
      } else {
        const params = {
          id: '',
          name: formData.localizedName,
          email: formData.email,
          ...dataToUpdate,
        };
        helpers.setValue(params);
      }
      selectedEmailhelper.setValue(formData.email || '');
    }
  };

  switch (step) {
    case AddOfficerSteps.IDENTIFY:
      return (
        <IdentifyMember
          submitHandler={(id: string | false | undefined) => {
            setMemberDataReviewed(null);
            selectMember(id);
            if (!isUndefined(id)) {
              setStep(AddOfficerSteps.INFO);
            }
          }}
          filters={filters}
          setFilters={setFilters}
          isRotaractClub={isRotaractClub}
          clubName={clubName?.value}
          handleFormFieldsTouched={handleFormFieldsTouched}
          isCreateAssignFlowEligible={isCreateAssignFlowEligible?.value}
          roleName={roleName}
        >
          <BackButton />
        </IdentifyMember>
      );
    case AddOfficerSteps.INFO:
      return (
        <MemberDetails
          data={memberDataReviewed || initialData}
          initialData={initialData}
          isCreateAssignFlowEligible={isCreateAssignFlowEligible?.value}
          roleName={roleName}
          submitHandler={(formData, dataToUpdate) => {
            setMemberDataReviewed(formData);
            setMemberDataPayload(dataToUpdate);
            handleSubmit(formData, dataToUpdate);
            proceed();
          }}
          handleFormFieldsTouched={handleFormFieldsTouched}
        >
          <BackButton />
        </MemberDetails>
      );
    case AddOfficerSteps.MEMBERSHIP:
      return (
        <MembershipInformation
          isRotaractClub={isRotaractClub}
          hasActiveMembership={
            memberDataReviewed ? memberDataReviewed.hasActiveMembership : false
          }
          clubId={id || ''}
          recentMemberships={memberDataReviewed?.recentMemberships || []}
          submitHandler={() => {
            // TODO
          }}
          handleFormFieldsTouched={handleFormFieldsTouched}
        >
          <BackButton />
        </MembershipInformation>
      );
    default:
      return null;
  }
};

export default AssignNonMemberToRole;
