// Libs
import * as yup from 'yup';

// Utils
import {
  isRequired,
  bindTFuncToSchemaField,
  isChecked,
} from '@components/Formik/validation/schemaDefinitions';

// Types
import { TFunction } from 'i18next';

const addVendorValidationSchema = (t: TFunction) => {
  const s = bindTFuncToSchemaField(t);

  return yup.object().shape({
    accessFor: s(yup.string()).when('accessType', {
      is: type => type === 'view',
      then: s(yup.string(), isRequired),
    }),
    vendorKey: s(yup.string(), isRequired),
    accessType: s(yup.string(), isRequired),
    certification: s(yup.boolean(), isChecked),
  });
};

export default addVendorValidationSchema;
