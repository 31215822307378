import React from 'react';

import classNames from 'classnames';

interface Props {
  title: string;
  description: string;
  wrapperClassname?: string;
  descriptionClassname?: string;
}

const MembershipDetailSection: React.FC<Props> = ({
  title,
  description,
  wrapperClassname = 'mb-8',
  descriptionClassname,
}) => {
  const descriptionClass = classNames(
    'leading-loose text-small',
    descriptionClassname
  );

  return (
    <div className={classNames(wrapperClassname)}>
      <h5 className="leading-loose mb-2">{title}</h5>
      <p className={descriptionClass}>{description}</p>
    </div>
  );
};

export default MembershipDetailSection;
