import { TFunction } from 'i18next';

import { OrganizationBase } from '@typings/graphql';

export const getOrganizationBase = (
  t: TFunction,
  organizationBase?: OrganizationBase | null
): string => {
  switch (organizationBase) {
    case OrganizationBase.School:
      return t('club-organization-base.school', 'School');
    case OrganizationBase.Community:
      return t('club-organization-base.community', 'Community');
    case OrganizationBase.University:
      return t('club-organization-base.university', 'University');
    default:
      return '';
  }
};
