// Libs
import React from 'react';

import classNames from 'classnames';

// Components
import MemberRow from '@components/MemberRow';

import { Button } from '../Button';

// Utils
import { activeRangeToCurrentYear } from '@use-cases/districts';

import { useTranslation } from '@external/react-i18next';

import { Leadership, Maybe, MembershipType } from '@typings/graphql';

const PrevOfficerListItem: React.FC<{
  name: string;
  individualId: string;
  photoUri?: Maybe<string>;
  type: string;
  email: string;
  phoneNumber?: string;
  leaderships: Leadership[];
  selectMember: (id: string) => void;
  expanded: boolean;
  isCES?: boolean;
}> = ({
  name,
  individualId,
  photoUri,
  type,
  leaderships,
  email,
  phoneNumber,
  selectMember,
  expanded,
  isCES,
}) => {
  const { t } = useTranslation();

  const isRotaractor = type === MembershipType.Rotaractor;

  const renderActionButton = (individualId: string, isMobile?: boolean) => (
    <Button
      clickHandler={() => selectMember(individualId)}
      secondary={!isMobile}
      small
      full={isMobile}
      className={classNames('min-w-9', { 'mt-4': isMobile })}
    >
      {t('button.select', 'Select')}
    </Button>
  );

  const renderTerms = (leaderships: Leadership[]) => (
    <div>
      {leaderships?.map(({ startDate, endDate, id }) => (
        <div key={id} className="text-xs w-24 pl-px">
          {activeRangeToCurrentYear(startDate, endDate)}
        </div>
      ))}
    </div>
  );

  return (
    <MemberRow
      id={individualId}
      memberInfo={type}
      email={email}
      fullName={name}
      photoUri={photoUri || ''}
      phoneNumber={phoneNumber}
      actionButton={renderActionButton(individualId)}
      actionButtonMobile={renderActionButton(individualId, true)}
      isTermsColumn
      terms={renderTerms(leaderships)}
      isOpened={expanded}
      isLinkToProfile={isCES ? Boolean(type) && !isRotaractor : Boolean(type)}
    />
  );
};

export default PrevOfficerListItem;
