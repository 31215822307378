import * as React from 'react';

import { Avatar } from '@components/Avatar';
import { Button } from '@components/Button';
import { IOfficerResultRow } from '@components/IdentifyOfficerResult/types';

import { useTranslation } from '@external/react-i18next';

export const IdentifyOfficerResultDesktop: React.FC<IOfficerResultRow> = ({
  name,
  individualId,
  photoUri,
  role,
  email,
  phoneNumber,
  selectMember,
  memberSince = '',
  isDisabledHonorary = false,
  isDisabled = false,
}) => {
  const { t } = useTranslation();

  return (
    <div className="hidden desktop:flex items-center w-full p-4">
      <div className="flex-1 pl-24 pr-4 text-xs leading-xs">
        <Avatar
          photoUri={photoUri || undefined}
          className="-ml-24 float-left"
        />
        <h4 className="mb-0">{name}</h4>
        <p className="mb-0">{role}</p>
        <p className="mb-0">
          {t(
            'club-add-officer.select-member.member-since',
            'Member since {{ date }}',
            { date: memberSince }
          )}
        </p>
      </div>
      <div className="flex-1 pr-4 text-xs leading-xs">
        <p className="mt-7 mb-0">{email}</p>
        <p className="mb-0">{phoneNumber}</p>
      </div>
      <div className="flex-1 pr-4 text-xs leading-xs text-red-600">
        <p className="mt-7 mb-0">
          {isDisabledHonorary &&
            t(
              'club-add-officer.select-member.disabled-reason',
              'Honorary members cannot be officers.'
            )}
        </p>
      </div>
      <div className="flex-1 text-right">
        <Button
          dataTestId="desktop-select-btn"
          clickHandler={() => selectMember(individualId)}
          secondary
          small
          disabled={isDisabled}
          className="min-w-9"
        >
          {t('club-add-officer.select-member.select-button', 'Select')}
        </Button>
      </div>
    </div>
  );
};
