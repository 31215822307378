import React from 'react';

import Link from '@components/Link';

import { isClubRotaractSatellite, isClubSatellite } from '@use-cases/clubs';

import { printYear } from '@utils/datetime';
import { getPhysicalLocation } from '@utils/getPhysicalLocation';

import { useTranslation } from '@external/react-i18next';

import { BaseAddress } from '@typings/graphql';

interface Props {
  district?: string | null;
  isUserHomeDistrict?: boolean;
  districtId?: string | null;
  riClubId: string;
  charteredDate?: string | null;
  physicalLocation?: Pick<
    BaseAddress,
    'country' | 'state' | 'internationalProvince'
  > | null;
  clubType: string;
}

const GeneralInfo: React.FC<Props> = ({
  district,
  isUserHomeDistrict,
  districtId,
  physicalLocation,
  charteredDate,
  riClubId,
  clubType,
}) => {
  const { t } = useTranslation();
  const noDetailsText = t('club-details.no-details', 'None');

  return (
    <div>
      <div className="flex flex-col tablet:flex-row tablet:justify-between">
        <div className="mb-8 tablet:mb-0">
          <h5>{t('club-details.rotary-club.location-label', 'Location')}</h5>
          <span>{getPhysicalLocation(physicalLocation) || noDetailsText}</span>
        </div>
        <div className="mb-8 tablet:mb-0">
          <h5>{t('club-details.rotary-club.district-label', 'District')}</h5>
          {district && isUserHomeDistrict ? (
            <Link to={`/district/${districtId}/details`}>
              {district || noDetailsText}
            </Link>
          ) : (
            district || noDetailsText
          )}
        </div>
        <div className="mb-8 tablet:mb-0">
          <h5>
            {isClubSatellite(clubType) || isClubRotaractSatellite(clubType)
              ? t(
                  'club-details.rotary-club.satellite.founded-label',
                  'Established'
                )
              : t('club-details.rotary-club.founded-label', 'Chartered')}
          </h5>
          <span>
            {charteredDate ? printYear(charteredDate) : noDetailsText}
          </span>
        </div>
        <div className="mb-8 tablet:mb-0">
          <h5>{t('club-details.rotary-club.clubId-label', 'Club ID')}</h5>
          <span>{riClubId}</span>
        </div>
      </div>
    </div>
  );
};

export default GeneralInfo;
