import React from 'react';

import RadioField from '@components/Formik/RadioField';
import Select, { SelectOption } from '@components/Formik/Select';
import Loading from '@components/Loading';

import { optionsAccessFor, optionsAccessType } from '@use-cases/clubs';

import { useFetchPotentialVendors } from '@repositories/clubs';

import { useTranslation } from '@external/react-i18next';

const AuthorizationSettings: React.FC<{
  accessType: string;
  hasPreferred: boolean;
  selectedVendors: Array<string | null | undefined>;
}> = ({ accessType, hasPreferred, selectedVendors }) => {
  const { t } = useTranslation();

  const { data: vendors, loading } = useFetchPotentialVendors();

  const accessTypeOptions = optionsAccessType(t);

  // Hide View&Edit option in case there's already preferred vendor.
  if (hasPreferred) {
    accessTypeOptions.splice(0, 1);
  }

  if (loading || !vendors || !vendors.vendors) {
    return <Loading />;
  }

  const filteredVendorList = vendors?.vendors.filter(
    item => !selectedVendors.includes(item.Name.toLowerCase())
  );

  const optionsVendor: SelectOption[] =
    filteredVendorList.map(item => ({
      label: item.Name || '',
      value: item.Key || '',
    })) || [];

  return (
    <section className="mb-14">
      <h2>
        {t(
          'club-editmanagementsystems.add-vendor.authorization-form.title',
          'Rotary Club and Rotaract Club Authorization to Initiate Data Integration Agreement'
        )}
      </h2>
      <h3 className="h4 alternate">
        {t(
          'club-editmanagementsystems.add-vendor.authorization-form.subtitle',
          'Select the Vendor and Access Level'
        )}
      </h3>
      <Select
        additionalClasses="max-w-lg"
        name="vendorKey"
        label={t(
          'club-editmanagementsystems.add-vendor.authorization-form.vendorname-label',
          'Club Management Vendor'
        )}
        options={optionsVendor}
        required
        searchable
      />
      <RadioField
        name="accessType"
        label={t(
          'club-editmanagementsystems.add-vendor.authorization-form.accesstype-label',
          'Vendor Access Level'
        )}
        required
        options={accessTypeOptions}
      />
      {accessType === 'view' && (
        <RadioField
          name="accessFor"
          label={t(
            'club-editmanagementsystems.add-vendor.authorization-form.accessfor-label',
            'Please indicate why you’re authorizing Rotary International to enable club data integration with this vendor'
          )}
          required
          options={optionsAccessFor(t)}
        />
      )}
    </section>
  );
};

export default AuthorizationSettings;
