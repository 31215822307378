import React from 'react';

import { Form, Formik } from 'formik';

import { Button } from '@components/Button';
import AddressFields from '@components/Formik/Address';
import TextArea from '@components/Formik/TextArea';
import {
  getCandidateFormInformation,
  getCandidateFormInitialValue,
} from '@presenters/web/pages/Leads/MMLPage/CandidateManagement/FormInfo';
import {
  AddressProps,
  CandidateFormFieldsProps,
  CandidateFormValues,
  FormAddressFieldsType,
} from '@presenters/web/pages/Leads/MMLPage/CandidateManagement/types';
import { addCandidateFormValidationSchema } from '@presenters/web/pages/Leads/validationSchema';
import AddressTypeSelect from '@presenters/web/pages/Profile/Edit/ContactInformation/AddressForm/AddressTypeSelect';

import PrivacyPolicy from './PrivacyPolicy';

import { getMembershipCandidatePath } from '@use-cases/leads';

import { localizedNavigate } from '@utils/localized-navigate';

import { useTranslation } from '@external/react-i18next';

const CandidateAddressFormDetails: React.FC<{
  clubId: string;
  submitHandler: () => void;
}> = ({ clubId, submitHandler }) => {
  const { t } = useTranslation();
  const gridContainerClassName = 'tablet:w-1/2 desktop:w-1/2';
  const fieldProps: CandidateFormFieldsProps = getCandidateFormInformation(
    t,
    'candidateAddressDetails'
  );
  const fieldInitialValue: CandidateFormValues = getCandidateFormInitialValue(
    'candidateAddressDetails'
  );

  const handleCancel = () => {
    localizedNavigate(getMembershipCandidatePath(clubId));
  };

  return (
    <>
      <h2>{t('mml.add-candidate-form.title', 'Add new candidate')}</h2>
      <Formik
        initialValues={fieldInitialValue}
        validationSchema={addCandidateFormValidationSchema(t)}
        onSubmit={submitHandler}
      >
        {({ values }) => {
          const { countryId } = values.primaryAddress as AddressProps;
          return (
            <Form className={gridContainerClassName}>
              <AddressTypeSelect {...fieldProps.addressTypeSelect} />
              <AddressFields
                countryId={countryId}
                postalcode={t(
                  'mml.add-new-candidate.form.address.postalCode-label',
                  'Postal code'
                )}
                {...(fieldProps.addressFields
                  .formAddress as FormAddressFieldsType)}
              />
              <TextArea
                data-testid="club-meeting-preference-involved"
                name="club-meeting-preference-involved"
                label={t(
                  'referral-club-meeting-preference.form.involved',
                  'Additional comments'
                )}
                characters={250}
                areaclasses="h-40"
              />
              <PrivacyPolicy />
              <div className="desktop:flex-row max-w-lg mt-6 desktop:mt-10 mb-6 desktop:mb-10">
                <Button type="submit" full>
                  {t('mml.add-new-candidate.form.submit-label', 'Submit')}
                </Button>
                <Button
                  full
                  text
                  type="button"
                  clickHandler={handleCancel}
                  className="mt-6"
                >
                  {t('mml.add-new-candidate.form.cancel-label', 'Cancel')}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default CandidateAddressFormDetails;
