import React, { useState } from 'react';

import { RouteComponentProps, useLocation } from '@reach/router';
import { toString } from 'lodash';
import moment from 'moment';

import Wizard from '@components/Formik/Wizard';
import Loading from '@components/Loading';
import LeaveFormConfirmationModal from '@components/Modals/LeaveFormConfirmationModal';
import SelectOfficer from '@presenters/web/pages/Clubs/ManageClubOfficers/SelectOfficer';

import ConfirmReplaceOfficer from '../ConfirmReplaceOfficer';
import ClubOfficerMultipleRoles from './ClubOfficerMultipleRoles';

import {
  ActiveLeaders,
  Individual,
  ReplaceRoleToMemberValues,
} from '@domain/clubs/types';

import { getClubMembersPath } from '@use-cases/clubs';
import {
  getTermByYear,
  useModal,
  useStopBrowserNavigate,
} from '@use-cases/districts';
import { useErrorHandling, useNotifications } from '@use-cases/notifications';

import {
  useFetchIndividualForAssignClubRole,
  useManageClubLeadership,
} from '@repositories/clubs';

import { getRotaryYear } from '@utils/datetime';
import { getClubRoleTValue } from '@utils/getClubRoleTValue';
import { localizedNavigate } from '@utils/localized-navigate';
import { getChannel } from '@utils/sendMessage';

import { useTranslation } from '@external/react-i18next';

import { MembershipType } from '@typings/operations';

interface ReplaceRoleToMemberProps extends RouteComponentProps {
  clubId: string;
  individualId: string;
}

const ReplaceRoleToMember: React.FC<ReplaceRoleToMemberProps> = ({
  clubId,
  individualId,
}) => {
  const { t } = useTranslation();
  const location = useLocation();

  const backUrlFromState = (location?.state as Record<string, string>)?.backUrl;
  const activeLeaderList = (location?.state as Record<string, ActiveLeaders[]>)
    ?.activeLeaders;
  const { isShowing, show } = useModal(window.stopBrowserNavigate);
  const [isBackClubMembersPage, setIsBackClubMembersPage] = useState<boolean>(
    false
  );

  const isFirstStep = (step: number) => step === 0;
  const isSecondStep = (step: number) => step === 1;
  const isLastStep = (step: number) => step === 2;

  const modalBackHandler = () => {
    show(true);
  };

  const { globalHide, setGlobal } = useStopBrowserNavigate({
    showModal: show,
    isNextStepVisited: true,
    onNavigate: modalBackHandler,
  });

  const stepBack = (step: number, setStep: (step: number) => void) => {
    if (isFirstStep(step)) {
      localizedNavigate(getClubMembersPath(clubId));
    } else {
      setStep(step - 1);
    }
  };
  const { addSuccess } = useNotifications();
  const { data, loading, error } = useFetchIndividualForAssignClubRole(
    clubId,
    individualId
  );

  const {
    replaceClubLeadership,
    isLoading: isLoadingClubLeadership,
    isError: isErrorClubLeadership,
  } = useManageClubLeadership({ clubId });

  const isError = Boolean(isErrorClubLeadership || error);

  const isLoading = isLoadingClubLeadership || loading;

  const errorMessage = t('replace-club-officer.error', 'An error occurred.');

  useErrorHandling(errorMessage, isError, 'replace-club-officer.error');

  const leavePage = (step: number, setStep: (step: number) => void) => {
    globalHide();

    if (isBackClubMembersPage) {
      localizedNavigate(getClubMembersPath(clubId));
    }
    stepBack(step, setStep);
  };

  const handleBackArrowClick = (
    step: number,
    setStep: (step: number) => void
  ) => {
    if (isLastStep(step)) {
      show(true);
    } else {
      stepBack(step, setStep);
      window.scrollTo(0, 0);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  if (!data || loading || error) {
    return <Loading />;
  }

  let type: MembershipType = MembershipType.Member;

  const individual: Individual = {
    id: data.individual.id,
    name: data.individual.name,
    email: data.individual.email,
    photoUri: data.individual.photoUri,
    membershipAdmissionDate: null,
    membershipTerminationDate: null,
    onlineId: data.individual.onlineId,
  };

  const clubAffiliations =
    data.individual.clubAffiliations?.[0]?.affiliations || [];

  const membershipAffiliation = clubAffiliations.find(
    affiliation => affiliation.__typename === 'MembershipAffiliation'
  );

  if (membershipAffiliation?.__typename === 'MembershipAffiliation') {
    type = membershipAffiliation.membershipType;
    individual.membershipAdmissionDate = membershipAffiliation.admissionDate;
    individual.membershipTerminationDate =
      membershipAffiliation.terminationDate;
  }

  const handleSubmit = async ({
    individualToReplace,
    role,
    individual,
    effectiveDate,
    email,
    slot,
    leadershipId,
  }: ReplaceRoleToMemberValues) => {
    const terminationDate = moment(effectiveDate)
      .add(-1, 'days')
      .toDate();

    const params = {
      individualId: toString(individualToReplace?.id),
      roleId: toString(role?.id),
      leadershipId: toString(leadershipId),
      terminationDate,
      startDate: moment(effectiveDate).toDate(),
      endDate: moment(slot?.end).toDate(),
      newEmail: email !== individualToReplace?.email ? email : null,
    };

    await replaceClubLeadership(params);

    addSuccess(
      t(
        'club-replace-officer.assign-role.success-message',
        'Success! {{individualName}} has been replaced by {{replacedName}} with the role of {{roleName}}.',
        {
          individualName: individual?.name,
          replacedName: individualToReplace?.name,
          roleName: getClubRoleTValue(t, role?.name || ''),
        }
      ),
      { id: 'form.club-replace-officer.success' }
    );
    getChannel('manage-club-officers').postMessage(
      t(
        'club-officer-tab-update.message',
        'Please refresh this browser tab to see updated information.'
      )
    );
    setGlobal(false);
    localizedNavigate(backUrlFromState || getClubMembersPath(clubId));
  };

  const handleCancelBtnClick = (e?: React.MouseEvent) => {
    e?.preventDefault();
    show(true);
    setIsBackClubMembersPage(true);
  };

  const checkPopup = (step: number, setStep: (step: number) => void) => {
    if (isShowing && (isFirstStep(step) || isSecondStep(step))) {
      leavePage(step, setStep);
    }
  };

  const getConfirmationModal = (
    step: number,
    setStep: (step: number) => void
  ) => {
    return (
      <LeaveFormConfirmationModal
        isOpen={isShowing}
        closeModal={() => {
          globalHide();
          setIsBackClubMembersPage(false);
        }}
        onConfirm={() => {
          leavePage(step, setStep);
          window.scrollTo(0, 0);
        }}
      />
    );
  };

  return (
    <Wizard
      pages={[ClubOfficerMultipleRoles, SelectOfficer, ConfirmReplaceOfficer]}
      initialValues={{
        activeLeaders: activeLeaderList,
        effectiveDate: null,
        individualToReplace: null,
        club: data?.club,
        isMemberRow: true,
        individual,
        slot: {
          start: moment(new Date()),
          end: moment(new Date()),
        },
        role: {
          name: '',
          id: '',
        },
        email: data.individual.email || '',
        selectedTerm: getTermByYear(Number(getRotaryYear())),
        type,
        customSlot: null,
        leadershipId: null,
      }}
      onSubmit={handleSubmit}
      handleBackArrowClick={handleBackArrowClick}
      handleCancelBtnClick={handleCancelBtnClick}
      confirmationModal={getConfirmationModal}
      checkPopup={checkPopup}
    />
  );
};

export default ReplaceRoleToMember;
