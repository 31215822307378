import React, { useState } from 'react';

import { RouteComponentProps, useLocation } from '@reach/router';
import { toString } from 'lodash';
import moment from 'moment';

import Wizard from '@components/Formik/Wizard';
import Loading from '@components/Loading';
import LeaveFormConfirmationModal from '@components/Modals/LeaveFormConfirmationModal';
import SelectOfficer from '@presenters/web/pages/Clubs/ManageClubOfficers/SelectOfficer';

import ConfirmReplaceOfficer from '../ConfirmReplaceOfficer';
import ReplaceNonMemberToRole from '../ReplaceNonMemberToRole';
import { ReplaceNonMemberProps } from '../ReplaceNonMemberToRole/ReplaceNonMemberToRole';
import { confirmReplaceOfficerValidationSchema } from '../validationSchema';

import {
  ConfirmOfficerValues,
  Individual,
  SelectRoleValues,
} from '@domain/clubs/types';

import { getClubMembersPath, getOfficersPagePath } from '@use-cases/clubs';
import {
  isCESNonMemberFlowEligible,
  isRotractAdvisorNonMemberFlowEligible,
} from '@use-cases/clubs/helpers/getRoleEligibility';
import { useModal, useStopBrowserNavigate } from '@use-cases/districts';
import { useErrorHandling, useNotifications } from '@use-cases/notifications';

import {
  useCreateIndividual,
  useFetchClubRolesForAssignMember,
  useFetchIndividualForAssignClubRole,
  useManageClubLeadership,
  useUpdateIndividual,
} from '@repositories/clubs';

import { getYear, isFutureRY } from '@utils/datetime';
import { getClubRoleTValue } from '@utils/getClubRoleTValue';
import { localizedNavigate } from '@utils/localized-navigate';
import { getChannel } from '@utils/sendMessage';

import { useTranslation } from '@external/react-i18next';

import { UpdateIndividualMutationVariables } from '@typings/operations';

interface ReplaceMemberToRoleProps extends RouteComponentProps {
  clubId: string;
  individualId: string;
  roleId: string;
  startDate: string;
  leadershipId: string;
  endDate: string;
  clubType: string;
}

type ReplaceRoleToMemberValues = Partial<
  SelectRoleValues & ReplaceNonMemberProps & ConfirmOfficerValues
>;

const ReplaceMemberToRole: React.FC<ReplaceMemberToRoleProps> = ({
  clubId,
  individualId,
  roleId,
  startDate,
  leadershipId,
  endDate,
  clubType,
}) => {
  const { t } = useTranslation();
  const [otherEmails, setOtherEmails] = useState<string[]>([]);
  const location = useLocation();
  const { isShowing, show } = useModal(window.stopBrowserNavigate);
  const [isBackClubMembersPage, setIsBackClubMembersPage] = useState<boolean>(
    false
  );

  const isFirstStep = (step: number) => step === 0;

  const modalBackHandler = () => {
    show(true);
  };

  const { globalHide, setGlobal } = useStopBrowserNavigate({
    showModal: show,
    isNextStepVisited: true,
    onNavigate: modalBackHandler,
  });

  const backUrlFromState = (location?.state as Record<string, string>)?.backUrl;

  const [isClubMembersPage] = useState(Boolean(backUrlFromState?.length));

  const stepBack = (step: number, setStep: (step: number) => void) => {
    if (isFirstStep(step)) {
      isClubMembersPage
        ? localizedNavigate(getClubMembersPath(clubId))
        : localizedNavigate(getOfficersPagePath(clubId));
    } else {
      setStep(step - 1);
    }
  };

  const [
    updateIndividual,
    {
      error: updateIndividualError,
      called: updateIndividualCalled,
      loading: updateIndividualLoading,
    },
  ] = useUpdateIndividual();

  const [
    createIndividual,
    {
      error: createIndividualError,
      called: createIndividualCalled,
      loading: createIndividualLoading,
    },
  ] = useCreateIndividual();

  const { addSuccess, addError } = useNotifications();

  const { data, loading, error } = useFetchIndividualForAssignClubRole(
    clubId,
    individualId
  );

  const isFutureRotaryYear = isFutureRY(getYear(endDate));

  const {
    data: dataClubRoles,
    loading: loadingClubRoles,
    error: errorClubRoles,
  } = useFetchClubRolesForAssignMember(clubId, roleId, clubType);

  const {
    replaceClubLeadership,
    isLoading: isLoadingClubLeadership,
    isError: isErrorClubLeadership,
  } = useManageClubLeadership({ clubId });

  const isError = Boolean(isErrorClubLeadership || errorClubRoles || error);

  const isLoading =
    isLoadingClubLeadership ||
    loadingClubRoles ||
    loading ||
    (updateIndividualCalled && updateIndividualLoading) ||
    (createIndividualCalled && createIndividualLoading);

  const errorMessage = t('replace-club-officer.error', 'An error occurred.');

  useErrorHandling(errorMessage, isError, 'replace-club-officer.error');

  const leavePage = (step: number, setStep: (step: number) => void) => {
    globalHide();

    if (isBackClubMembersPage) {
      localizedNavigate(getClubMembersPath(clubId));
    }
    stepBack(step, setStep);
  };

  const handleBackArrowClick = (
    step: number,
    setStep: (step: number) => void
  ) => {
    stepBack(step, setStep);
    window.scrollTo(0, 0);
  };

  if (isLoading) {
    return <Loading />;
  }

  const individual: Individual = {
    id: toString(data?.individual.id),
    name: toString(data?.individual.name),
    email: toString(data?.individual.email),
    membershipAdmissionDate: null,
    membershipTerminationDate: null,
  };

  const role = dataClubRoles?.roles?.find(
    ({ id: roleItemId }) => roleItemId === roleId
  );

  const clubAffiliations =
    data?.individual.clubAffiliations?.[0]?.affiliations || [];

  const membershipAffiliation = clubAffiliations.find(
    affiliation => affiliation.__typename === 'MembershipAffiliation'
  );

  if (
    individual &&
    membershipAffiliation?.__typename === 'MembershipAffiliation'
  ) {
    individual.membershipAdmissionDate = membershipAffiliation.admissionDate;
    individual.membershipTerminationDate =
      membershipAffiliation.terminationDate;
  }
  const isReplaceNonMemberFlowEligible =
    role &&
    (isRotractAdvisorNonMemberFlowEligible(role.id) ||
      isCESNonMemberFlowEligible(role.id));

  const handleSubmit = async (
    handleSubmitResponse: ReplaceRoleToMemberValues
  ) => {
    const {
      individualToReplace,
      role,
      individual,
      effectiveDate,
      email,
    } = handleSubmitResponse;

    // If replace non member flow eligible role or Rotract advisor flow
    if (isReplaceNonMemberFlowEligible) {
      let isUpdateIndividualSuccess = false;
      let isCreateIndividualSuccess = false;

      // If existing individual selected 1. update individual 2. replace individual
      // If new user added - 1. create individual 2. Replace individual
      if (individualToReplace?.id) {
        // update individual
        const result = await updateIndividual({
          variables: {
            individualId: individualToReplace?.id,
            useInternalKey: true,
            ...(individualToReplace as Omit<
              UpdateIndividualMutationVariables,
              'individualId'
            >),
          },
        });

        // show error message when update individual fails
        if (updateIndividualError) {
          addError(updateIndividualError.message);
        }

        if (result?.data?.updateIndividual?.individualId) {
          isUpdateIndividualSuccess = true;
        }
      } else {
        // create individual
        const result = await createIndividual({
          variables: individualToReplace,
        });

        if (createIndividualError) {
          addError(createIndividualError.message);
        }

        const individualId = String(result.data?.createIndividual.individualId);
        if (individualToReplace) {
          individualToReplace.id = individualId;
        }
        if (individualId) {
          isCreateIndividualSuccess = true;
        }
      }

      // replace membership
      if (isUpdateIndividualSuccess || isCreateIndividualSuccess) {
        const terminationDate = moment(effectiveDate)
          .add(-1, 'days')
          .toDate();

        const params = {
          individualId: toString(individualToReplace?.id),
          roleId: toString(role?.id),
          leadershipId,
          terminationDate,
          startDate: moment(effectiveDate).toDate(),
          endDate: moment(endDate).toDate(),
          newEmail: null,
        };

        await replaceClubLeadership(params);
      }
    } else {
      const terminationDate = moment(effectiveDate)
        .add(-1, 'days')
        .toDate();

      const params = {
        individualId: toString(individualToReplace?.id),
        roleId: toString(role?.id),
        leadershipId,
        terminationDate,
        startDate: moment(effectiveDate).toDate(),
        endDate: moment(endDate).toDate(),
        newEmail: email !== individualToReplace?.email ? email : null,
      };

      await replaceClubLeadership(params);
    }

    addSuccess(
      t(
        'club-replace-officer.assign-role.success-message',
        'Success! {{individualName}} has been replaced by {{replacedName}} with the role of {{roleName}}.',
        {
          individualName: individual?.name,
          replacedName:
            individualToReplace?.name || individualToReplace?.localizedName,
          roleName: getClubRoleTValue(t, role?.name || ''),
        }
      ),
      { id: 'form.club-replace-officer.success' }
    );
    getChannel('manage-club-officers').postMessage(
      t(
        'club-officer-tab-update.message',
        'Please refresh this browser tab to see updated information.'
      )
    );
    setGlobal(false);
    localizedNavigate(backUrlFromState || getOfficersPagePath(clubId));
  };

  ConfirmReplaceOfficer.schema = confirmReplaceOfficerValidationSchema(
    t,
    isFutureRotaryYear,
    otherEmails
  );

  const handleCancelBtnClick = (e?: React.MouseEvent) => {
    e?.preventDefault();
    show(true);
    setIsBackClubMembersPage(true);
  };

  const checkPopup = (step: number, setStep: (step: number) => void) => {
    if (isShowing && isFirstStep(step)) {
      leavePage(step, setStep);
    }
  };

  const getConfirmationModal = (
    step: number,
    setStep: (step: number) => void
  ) => {
    return (
      <LeaveFormConfirmationModal
        isOpen={isShowing}
        closeModal={() => {
          globalHide();
          setIsBackClubMembersPage(false);
        }}
        onConfirm={() => {
          leavePage(step, setStep);
        }}
      />
    );
  };

  const pages = isReplaceNonMemberFlowEligible
    ? [SelectOfficer, ReplaceNonMemberToRole, ConfirmReplaceOfficer]
    : [SelectOfficer, ConfirmReplaceOfficer];

  return (
    <Wizard
      pages={pages}
      initialValues={{
        club: data?.club,
        role: {
          name: toString(role?.name),
          id: toString(role?.id),
        },
        slot: {
          start: moment(startDate),
          end: moment(endDate),
        },
        clubId: data?.club.id,
        clubName: data?.club.name,
        isReplaceNonMemberFlowEligible,
        effectiveDate: null,
        isMemberRow: true,
        individual,
        email: '',
      }}
      onSubmit={handleSubmit}
      setOtherEmails={emails => setOtherEmails(emails)}
      handleBackArrowClick={handleBackArrowClick}
      handleCancelBtnClick={handleCancelBtnClick}
      confirmationModal={getConfirmationModal}
      checkPopup={checkPopup}
    />
  );
};

export default ReplaceMemberToRole;
