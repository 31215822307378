import React from 'react';

import { getYear } from '@utils/datetime';

import { useTranslation } from '@external/react-i18next';

interface Props {
  selectedRYStart: string;
  selectedRYEnd: string;
  isSpanRequired?: boolean;
}

const ConfirnOfficerTerm: React.FC<Props> = ({
  selectedRYStart,
  selectedRYEnd,
  isSpanRequired = true,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {`${getYear(selectedRYStart)}-${getYear(selectedRYEnd)}`}
      {isSpanRequired ? (
        <>
          <span className="text-xs text-gray-400 ml-4">{`${t(
            'club-add-officer.confirm.term-start',
            'Start'
          )}: ${selectedRYStart}`}</span>
          <span className="text-xs text-gray-400 ml-4">{`${t(
            'club-add-officer.confirm.term-end',
            'End'
          )}: ${selectedRYEnd}`}</span>
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default ConfirnOfficerTerm;
