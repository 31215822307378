import React from 'react';

import { RouteComponentProps } from '@reach/router';

import Loading from '@components/Loading';
import ClubTabsLayout from '@presenters/web/components/ClubTabs/ClubTabsLayout';

import {
  ROTARACT_FINANCE_MENU_NAME,
  ROTARY_FINANCE_MENU_NAME,
} from '@domain/clubs/constants';

import { isRotaractClub } from '@utils/isRotaractClub';

import { useTranslation } from '@external/react-i18next';
import { useMenu } from '@hooks/menu';
import { FEATURE_INVOICE, isEnabled } from '@utils/features';
import { useFinanceMenu } from '@hooks/useFinanceMenu';

interface ClubFinanceProps extends RouteComponentProps {
  clubId: string;
  orgType: string;
  isLeader: boolean;
}

const ClubFinance: React.FC<ClubFinanceProps> = ({
  clubId,
  orgType,
  isLeader,
}) => {
  const { t, i18n } = useTranslation();

  const menuName = isRotaractClub(orgType)
    ? ROTARACT_FINANCE_MENU_NAME
    : ROTARY_FINANCE_MENU_NAME;

  const isInvoiceFlagEnabled = isEnabled(FEATURE_INVOICE);
  const { data: newFinanceData, loading: menuLoading } = useFinanceMenu(
    t,
    clubId,
    orgType,
    isLeader
  );

  const { data: financeData, loading } = useMenu(
    menuName,
    i18n.language,
    true,
    false
  );

  const financeMenuData = isInvoiceFlagEnabled ? newFinanceData : financeData;

  if (loading || menuLoading) return <Loading />;

  return (
    <ClubTabsLayout
      tabHeader={t('club-details.menu.administration.finances', 'Finances')}
      data={financeMenuData}
      classes="mobile-m:w-70 tablet:w-92 desktop:w-92"
    />
  );
};

export default ClubFinance;
