import { CLUB_TYPE_ROTARACT, CLUB_TYPE_ROTARY } from '@domain/districts';
import { Finances } from '@typings/finances';
import { TFunction } from 'i18next';
import { isEqual } from 'lodash';

export const useFinanceMenu = (
  t: TFunction,
  clubId: string,
  orgType: string,
  isLeader: boolean
) => {
  const managerClubFinanceMenu = [
    {
      label: t('about-the-club-invoice', 'About the club invoice'),
      path: `/manage/club-district-administration/club-invoice`,
      description: '',
    },
    {
      label: t('club-invoice', 'Club Invoice'),
      path: `/club-invoice/${clubId}`,
      description: '',
    },
    {
      label: t('daily-club-balance-report', 'Daily club balance report'),
      path: `/daily-club-balance-report/${clubId}`,
      description: '',
    },
    {
      label: t('rotary-exchange-rates', 'Rotary exchange rates'),
      path: `/myrotary/node/12891`,
      description: '',
    },
  ];

  const viewerClubFinanceMenu = [
    {
      label: t('about-the-club-invoice', 'About the club invoice'),
      path: `/manage/club-district-administration/club-invoice`,
      description: '',
    },
    {
      label: t('rotary-exchange-rates', 'Rotary exchange rates'),
      path: `/myrotary/node/12891`,
      description: '',
    },
  ];

  const data: Finances.MenuResponse =
    isLeader &&
    (isEqual(orgType, CLUB_TYPE_ROTARY) || isEqual(orgType, CLUB_TYPE_ROTARACT))
      ? managerClubFinanceMenu
      : viewerClubFinanceMenu;

  return {
    data,
    loading: !data,
  };
};
