import moment from 'moment';

import { isToday } from '@utils/datetime';

const AVAILABLE_DAYS_COUNT = 30;

const getCanChangeAdmissionDate = (admissionDate: string) =>
  moment().diff(admissionDate, 'days') <= AVAILABLE_DAYS_COUNT;

export const getCalendarValues = (admissionDate: string) => {
  const canChangeAdmissionDate = getCanChangeAdmissionDate(admissionDate);
  const tomorrowDate = moment().add(1, 'day');
  const minEditableDate = moment(admissionDate).add(1, 'day');
  const maxEditableDate = tomorrowDate;
  const minCalendarDate = moment()
    .subtract(AVAILABLE_DAYS_COUNT, 'day')
    .toDate();

  let effectiveDate = canChangeAdmissionDate ? minEditableDate : moment();

  if (isToday(admissionDate)) {
    effectiveDate = tomorrowDate;
  }

  const isDisabled = !canChangeAdmissionDate;

  return {
    initialEffectiveDate: effectiveDate.toDate(),
    maxEditableDate: maxEditableDate.toDate(),
    minEditableDate: canChangeAdmissionDate
      ? minEditableDate.toDate()
      : undefined,
    isDisabled,
    minCalendarDate,
  };
};
