import React, { useState } from 'react';

import SelectRotaryYear from '@components/Forms/Select/SelectRotaryYear';
import LinkPrevious from '@components/LinkPrevious';
import { LocationStateType } from '@presenters/web/pages/Districts/Officers/DistrictOfficersHeader';

import {
  getBackButtonLabel,
  getClubDetailsPath,
  getClubMembersPath,
} from '@use-cases/clubs';
import { getOfficerTermSelectOptions } from '@use-cases/districts';

import { localizedNavigate } from '@utils/localized-navigate';

import { useTranslation } from '@external/react-i18next';

interface ClubOfficersHeaderProps {
  selectedYear: string;
  handleYearChanged: (year: string) => void;
  clubId: string;
  locationState?: LocationStateType;
}

const ClubOfficersHeader: React.FC<ClubOfficersHeaderProps> = ({
  selectedYear,
  handleYearChanged,
  clubId,
  locationState,
}) => {
  const { t } = useTranslation();

  const [isDetailsPage] = useState(locationState?.isFromDetailsPage);

  const handleBack = (e: React.MouseEvent) => {
    e.preventDefault();
    isDetailsPage
      ? localizedNavigate(getClubDetailsPath(clubId))
      : localizedNavigate(getClubMembersPath(clubId));
  };

  return (
    <>
      <LinkPrevious
        path=""
        label={getBackButtonLabel(t)}
        onClick={handleBack}
      />

      <div className="flex flex-col flex-wrap tablet:flex-row justify-between items-baseline">
        <div>
          <div className="mt-2 tablet:mt-4 mb-0 tablet:mb-2 mr-auto text-xl font-extralight leading-xl">
            {t('club-officers.title', 'Manage Club Officers')}
          </div>

          <p className="mb-0">
            {t(
              'club-officers.members.description',
              'Select club members to fulfill designated roles'
            )}
          </p>
        </div>

        <div>
          <SelectRotaryYear
            handleChange={handleYearChanged}
            options={getOfficerTermSelectOptions({ useRYEndForValue: false })}
            initialValue={selectedYear}
          />
        </div>
      </div>
    </>
  );
};

export default ClubOfficersHeader;
