import React from 'react';

import { RouteComponentProps } from '@reach/router';
import { Form, Formik } from 'formik';

import { Button } from '@components/Button';
import LinkPrevious from '@components/LinkPrevious';
import Loading from '@components/Loading';
import Title from '@components/Title';

import AuthorizationSettings from './AuthorizationSettings';
import Definitions from './Definitions';
import TermsAndConditions from './TermsAndConditions';
import addVendorValidationSchema from './validation';

import { getBackButtonLabel } from '@use-cases/clubs';

import { useAddClubVendor, useFetchClubVendors } from '@repositories/clubs';

import { localizedNavigate } from '@utils/localized-navigate';

import { useTranslation } from '@external/react-i18next';

interface AddVendorProps extends RouteComponentProps {
  clubId: string;
}

interface FormValues {
  accessFor: string;
  vendorKey: string;
  accessType: string;
  certification: boolean;
}

const AddClubManagementVendor: React.FC<AddVendorProps> = ({ clubId }) => {
  const { t } = useTranslation();

  const editManagementSystemsLink = `/club/${clubId}/edit-management-systems`;

  const today = new Date();

  const { data: vendors, loading } = useFetchClubVendors(clubId);
  const [addVendor, { loading: addingInProcess }] = useAddClubVendor();

  const handleSubmit = async (values: FormValues) => {
    await addVendor({
      variables: {
        input: {
          // If vendor can view & edit, accessFor has to be 'club'.
          accessFor: values.accessType === 'edit' ? 'club' : values.accessFor,
          accessType: values.accessType,
          certification: values.certification,
          clubKey: clubId,
          vendorKey: values.vendorKey,
        },
      },
      awaitRefetchQueries: true,
    });

    localizedNavigate(editManagementSystemsLink);
  };

  if (!vendors || loading || addingInProcess) {
    return <Loading />;
  }

  const hasPreferred = Boolean(
    vendors.clubById.vendors?.find(item =>
      item?.requestors?.find(requestor => requestor?.edit === true)
    )
  );

  const selectedVendors = vendors.clubById.vendors?.map(item =>
    item.vendorName?.toLowerCase()
  );

  return (
    <>
      <LinkPrevious
        path={editManagementSystemsLink}
        label={getBackButtonLabel(t)}
        classes="mt-4"
      />
      <Title>
        {t(
          'club-editmanagementsystems.add-vendor.title',
          'Add Club Management Vendor'
        )}
      </Title>
      <p className="text-big mb-12 mb-14">
        {t(
          'club-editmanagementsystems.add-vendor.subtitle',
          'Please review the Agreement below and click on “I Agree” to submit your authorization and authorize Rotary International to complete the data integration process and submit your authorization.'
        )}
      </p>
      <Formik
        initialValues={{
          accessFor: '',
          vendorKey: '',
          accessType: '',
          certification: false,
        }}
        onSubmit={handleSubmit}
        validationSchema={addVendorValidationSchema(t)}
      >
        {({ isSubmitting, values }) => (
          <Form className="max-w-3xl">
            <AuthorizationSettings
              accessType={values.accessType}
              hasPreferred={hasPreferred}
              selectedVendors={selectedVendors}
            />
            <Definitions />
            <TermsAndConditions />
            <dl className="mt-14">
              <dt>
                {t(
                  'club-editmanagementsystems.add-vendor.start-date-label',
                  'Start Date'
                )}
              </dt>
              <dd>{today.toLocaleDateString(undefined, {})}</dd>
            </dl>
            <p className="text-gray-400">
              {t(
                'club-editmanagementsystems.add-vendor.submit-agreement',
                'By clicking the “I agree” button, I make the representations and warranties and agree to abide by the terms and conditions set forth below and, authorize RI to complete the data integration process in <a href="https://rotary.org/myrotary">rotary.org/myrotary</a>.'
              )}
            </p>
            <div className="tablet:flex mt-14">
              <Button
                disabled={isSubmitting}
                className="mb-5 tablet:mr-5 tablet:mb-0"
              >
                {t(
                  'club-editmanagementsystems.add-vendor.save-button-label',
                  'I agree'
                )}
              </Button>
              <Button
                type="button"
                secondary
                disabled={isSubmitting}
                clickHandler={() =>
                  localizedNavigate(editManagementSystemsLink)
                }
              >
                {t(
                  'club-editmanagementsystems.add-vendor.cancel-button-label',
                  'Cancel'
                )}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddClubManagementVendor;
