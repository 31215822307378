import React, { Reducer, useMemo, useReducer, useState } from 'react';

import { RouteComponentProps } from '@reach/router';

import { Button } from '@components/Button';
import Loading from '@components/Loading';
import LeaveFormConfirmationModal from '@components/Modals/LeaveFormConfirmationModal';

import IdentifyCandidate from './IdentifyCandidate';
import AddClubCandidate from '../AddClubCandidate';
import AddNewCandidateClub from '../AddNewCandidateClub';

import { IdentificationParameters } from '@domain/clubs';

import { useModal, useStopBrowserNavigate } from '@use-cases/districts';
import { getMembershipCandidatePath } from '@use-cases/leads';
import { useErrorHandling } from '@use-cases/notifications';

import { useFetchMemberDetails } from '@repositories/clubs';

import { localizedNavigate } from '@utils/localized-navigate';

import { useTranslation } from '@external/react-i18next';

interface ClubAddMemberPageProps extends RouteComponentProps {
  clubId: string;
  clubName: string;
}

const AddCandidate: React.FC<ClubAddMemberPageProps> = ({
  clubId: id,
  clubName,
}) => {
  const { t } = useTranslation();
  enum AddMemberSteps {
    IDENTIFY,
    NEWCANDIDATE,
    INFO,
  }

  const [step, setStep] = useState<AddMemberSteps>(AddMemberSteps.IDENTIFY);
  const isFirstStep = step === AddMemberSteps.IDENTIFY;

  const [filters, setFilters] = useReducer<
    Reducer<IdentificationParameters, IdentificationParameters>
  >((state, action) => {
    return { ...state, ...action };
  }, {});

  // Initiate a state for the current member id.
  // - undefined: no member was selected and the user did not choose to create one
  // - false: no member was selected, the user decided to create a new one
  // - string: the ID of the selected member
  const [selectedMember, selectMember] = useState<undefined | string | false>(
    undefined
  );

  // When a member has been selected, attempt to load data from the api.
  const { data, loading, error } = useFetchMemberDetails(
    selectedMember || '',
    id
  );

  useErrorHandling(error?.message, !!error, 'member-details.error');

  // If member data has been loaded, message it into InitialData.
  const individual = data?.addMemberGetIndividual.individual;

  const [isFieldTouched, setIsFieldTouched] = useState<boolean>(false);
  const { isShowing, show } = useModal(window.stopBrowserNavigate);

  const handleBackClick = () => {
    if (isFieldTouched) {
      show(true);
    } else if (isFirstStep) {
      localizedNavigate(getMembershipCandidatePath(id));
    } else {
      setStep(AddMemberSteps.IDENTIFY);
    }
  };

  const { globalHide } = useStopBrowserNavigate({
    showModal: show,
    isNextStepVisited: true,
    onNavigate: handleBackClick,
  });

  const modalOnConfirmHandler = () => {
    globalHide();
    if (isFirstStep) {
      localizedNavigate(getMembershipCandidatePath(id));
    } else {
      setStep(step - 1);
    }
  };

  const handleFormFieldsTouched = (isTouched: boolean) => {
    setIsFieldTouched(isTouched);
  };

  const handleCancelClick = () => {
    if (isFieldTouched) {
      show(true);
    } else {
      localizedNavigate(getMembershipCandidatePath(id));
    }
  };

  const BackButton = () =>
    useMemo(() => {
      return (
        <>
          <Button clickHandler={handleCancelClick} text full>
            {t('add_member.identify_member_form.cancel_button', 'Cancel')}
          </Button>
          <LeaveFormConfirmationModal
            isOpen={isShowing}
            closeModal={globalHide}
            onConfirm={modalOnConfirmHandler}
          />
        </>
      );
    }, [isShowing]);

  if (loading) {
    return <Loading />;
  }

  switch (step) {
    case AddMemberSteps.IDENTIFY:
      return (
        <IdentifyCandidate
          submitHandler={(id: string | false | undefined) => {
            selectMember(id);
            if (id !== undefined) {
              setStep(AddMemberSteps.INFO);
            } else {
              setStep(AddMemberSteps.NEWCANDIDATE);
            }
            window.scrollTo(0, 0);
          }}
          filters={filters}
          setFilters={setFilters}
          clubName={clubName}
          clubId={id}
          handleFormFieldsTouched={handleFormFieldsTouched}
        >
          <BackButton />
        </IdentifyCandidate>
      );
    case AddMemberSteps.INFO:
      return (
        <AddClubCandidate data={individual} handleBackClick={handleBackClick}>
          <BackButton />
        </AddClubCandidate>
      );
    case AddMemberSteps.NEWCANDIDATE:
      return <AddNewCandidateClub clubId={id} />;
    default:
      return null;
  }
};

export default AddCandidate;
