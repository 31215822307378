import React from 'react';

import { Button } from '@components/Button';

import { useTranslation } from '@external/react-i18next';

interface ClubCTAButtonsProps {
  isSubmitting: boolean;
  isValid?: boolean;
  onCancelClick?: () => void;
}

const ClubsSaveChangesButtons: React.FC<ClubCTAButtonsProps> = ({
  isSubmitting,
  isValid,
  onCancelClick,
}) => {
  const { t } = useTranslation();

  return (
    <div className="tablet:flex mt-16">
      <Button
        disabled={isSubmitting || !isValid}
        className="mb-5 tablet:mr-5 tablet:mb-0"
      >
        {t('club-editcontact.form.save-button-label', 'Save Changes')}
      </Button>
      <Button
        type="button"
        secondary
        disabled={isSubmitting}
        clickHandler={onCancelClick}
      >
        {t('club-editcontact.form.cancel-button-label', 'Cancel')}
      </Button>
    </div>
  );
};

export default ClubsSaveChangesButtons;
