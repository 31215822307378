import React, { useState } from 'react';

import { RouteComponentProps } from '@reach/router';

import Wizard from '@components/Formik/Wizard';
import Loading from '@components/Loading';
import LeaveFormConfirmationModal from '@components/Modals/LeaveFormConfirmationModal';
import ConfirmOfficer from '@presenters/web/pages/Clubs/ManageClubOfficers/ConfirmOfficer/ConfirmOfficer';
import SelectRole from '@presenters/web/pages/Clubs/ManageClubOfficers/SelectRole/SelectRole';
import {
  confirmOfficerValidationSchema,
  selectRoleValidationSchema,
} from '@presenters/web/pages/Clubs/ManageClubOfficers/validationSchema';

import { mapValuesToCreateClubLeadershipVariables } from '@domain/clubs/mappers';
import {
  ConfirmOfficerValues,
  Individual,
  SelectRoleValues,
} from '@domain/clubs/types';

import { getClubMembersPath } from '@use-cases/clubs';
import {
  getTermByYear,
  useModal,
  useStopBrowserNavigate,
} from '@use-cases/districts';
import { useErrorHandling, useNotifications } from '@use-cases/notifications';

import {
  useAssignClubLeadership,
  useFetchIndividualForAssignClubRole,
} from '@repositories/clubs';
import { useUpdateContactInformation } from '@repositories/profile/hooks';

import { getRotaryYear } from '@utils/datetime';
import { getClubRoleTValue } from '@utils/getClubRoleTValue';
import { localizedNavigate } from '@utils/localized-navigate';
import { getChannel } from '@utils/sendMessage';

import { useTranslation } from '@external/react-i18next';

import { EmailType, MembershipType } from '@typings/operations';

interface AssignRoleToMemberProps extends RouteComponentProps {
  clubId: string;
  individualId: string;
}

type AssignRoleToMemberValues = Partial<
  SelectRoleValues & ConfirmOfficerValues
>;

const AssignRoleToMember: React.FC<AssignRoleToMemberProps> = ({
  clubId,
  individualId,
}) => {
  const { t } = useTranslation();
  const { addError, addSuccess } = useNotifications();
  const { data, loading, error } = useFetchIndividualForAssignClubRole(
    clubId,
    individualId
  );
  const [isFieldTouched, setIsFieldTouched] = useState<boolean>(false);
  const [isBackClubMembersPage, setIsBackClubMembersPage] = useState<boolean>(
    false
  );

  const isFirstStep = (step: number) => step === 0;

  const { isShowing, show } = useModal(window.stopBrowserNavigate);
  const modalBackHandler = () => {
    show(true);
  };
  const { globalHide, setGlobal } = useStopBrowserNavigate({
    showModal: show,
    isNextStepVisited: true,
    onNavigate: modalBackHandler,
  });
  const [updateContactInformation] = useUpdateContactInformation();
  useErrorHandling(error?.message, !!error, 'assign-role.error');
  const [
    createClubLeadership,
    { error: errorCreateClubLeadership },
  ] = useAssignClubLeadership();

  const stepBack = (step: number, setStep: (step: number) => void) => {
    isFirstStep(step)
      ? localizedNavigate(getClubMembersPath(clubId))
      : setStep(step - 1);
  };

  const leavePage = (step: number, setStep: (step: number) => void) => {
    globalHide();

    if (isBackClubMembersPage) {
      localizedNavigate(getClubMembersPath(clubId));
    }
    stepBack(step, setStep);
  };

  const handleBackArrowClick = (
    step: number,
    setStep: (step: number) => void
  ) => {
    if (isFieldTouched) {
      show(true);
    } else {
      stepBack(step, setStep);
    }
  };
  const handleFormFieldsTouched = (isTouched: boolean) => {
    setTimeout(() => {
      setIsFieldTouched(isTouched);
    }, 0);
  };

  const handleCancelBtnClick = (e?: React.MouseEvent) => {
    e?.preventDefault();
    if (isFieldTouched) {
      show(true);
      setIsBackClubMembersPage(true);
    } else {
      localizedNavigate(getClubMembersPath(clubId));
    }
  };

  if (!data || loading || error) {
    return <Loading />;
  }

  SelectRole.schema = selectRoleValidationSchema(t);
  ConfirmOfficer.schema = confirmOfficerValidationSchema(t);

  let type: MembershipType = MembershipType.Member;

  const individual: Individual = {
    id: data.individual.id,
    name: data.individual.name,
    email: data.individual.email,
    photoUri: data.individual.photoUri,
    membershipAdmissionDate: null,
    membershipTerminationDate: null,
    onlineId: data.individual.onlineId,
  };

  const clubAffiliations =
    data.individual.clubAffiliations?.[0]?.affiliations || [];

  const membershipAffiliation = clubAffiliations.find(
    affiliation => affiliation.__typename === 'MembershipAffiliation'
  );

  if (membershipAffiliation?.__typename === 'MembershipAffiliation') {
    type = membershipAffiliation.membershipType;
    individual.membershipAdmissionDate = membershipAffiliation.admissionDate;
    individual.membershipTerminationDate =
      membershipAffiliation.terminationDate;
  }

  const handleSubmit = async (values: AssignRoleToMemberValues) => {
    const { role, individual, email } = values;
    const newEmail = email !== individual?.email ? email : null;

    await createClubLeadership({
      variables: mapValuesToCreateClubLeadershipVariables(
        values,
        clubId,
        role?.id || ''
      ),
    });

    if (newEmail) {
      await updateContactInformation({
        variables: {
          id: individualId,
          primaryEmail: {
            address: newEmail,
            type: EmailType.Business,
          },
        },
      });
    }

    if (errorCreateClubLeadership) {
      addError(errorCreateClubLeadership.message);
    } else {
      const individualName = individual?.name;

      addSuccess(
        t(
          'club-add-officer.assign-role.success-message',
          'Success! {{name}} has been assigned the role of {{role}}.',
          {
            name: individualName,
            role: getClubRoleTValue(t, role?.name || ''),
          }
        ),
        { id: 'form.success' }
      );
      getChannel('manage-club-officers').postMessage(
        t(
          'club-officer-tab-update.message',
          'Please refresh this browser tab to see updated information.'
        )
      );
      setGlobal(false);
      localizedNavigate(getClubMembersPath(clubId));
    }
  };

  const checkPopup = (step: number, setStep: (step: number) => void) => {
    if (isShowing && (!isFieldTouched || isFirstStep(step))) {
      leavePage(step, setStep);
    }
  };

  const getConfirmationModal = (
    step: number,
    setStep: (step: number) => void
  ) => {
    return (
      <LeaveFormConfirmationModal
        isOpen={isFieldTouched && isShowing}
        closeModal={() => {
          globalHide();
          setIsBackClubMembersPage(false);
        }}
        onConfirm={() => {
          handleFormFieldsTouched(false);
          leavePage(step, setStep);
          window.scrollTo(0, 0);
        }}
      />
    );
  };

  return (
    <Wizard
      pages={[SelectRole, ConfirmOfficer]}
      initialValues={{
        club: data.club,
        individual,
        selectedTerm: getTermByYear(Number(getRotaryYear())),
        email: data.individual.email || '',
        type,
        customSlot: null,
      }}
      onSubmit={handleSubmit}
      handleBackArrowClick={handleBackArrowClick}
      handleFormFieldsTouched={handleFormFieldsTouched}
      handleCancelBtnClick={handleCancelBtnClick}
      confirmationModal={getConfirmationModal}
      checkPopup={checkPopup}
    />
  );
};

export default AssignRoleToMember;
