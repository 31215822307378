import React, { useState } from 'react';

import { useField } from 'formik';
import moment from 'moment';

import ActionButtons from '@components/ActionButtons';
import { WizardPage } from '@components/Formik/Wizard';
import { Radios } from '@components/Forms/Radios';
import IndividualListColumn from '@components/IndividualListColumn';

import {
  ActiveLeaders,
  Club,
  Entity,
  Individual,
  Period,
  ReplaceRoleToMemberValues,
} from '@domain/clubs/types';

import { getClubMembersPath } from '@use-cases/clubs';

import { isToday } from '@utils/datetime';
import { getClubRoleTValue } from '@utils/getClubRoleTValue';

import { useTranslation } from '@external/react-i18next';

const ClubOfficerMultipleRoles: WizardPage<ReplaceRoleToMemberValues> = ({
  proceed,
}) => {
  const { t } = useTranslation();
  const [club] = useField<Club>('club');
  const [, , { setValue: setRole }] = useField<Entity>('role');
  const [, , { setValue: setSlot }] = useField<Period>('slot');

  const [individual] = useField<Individual>('individual');

  const [valueRadio, setValueRadio] = useState<string>('');

  const [, , { setValue: setLeadershipId }] = useField<string>('leadershipId');

  const [activeLeadersRoles] = useField<ActiveLeaders[]>('activeLeaders');

  const activeLeaders: ActiveLeaders[] = JSON.parse(
    JSON.stringify(activeLeadersRoles.value)
  );

  const handleGoToNextStep = () => {
    const { roleId, role: name, startDate, endDate, id } = activeLeaders.find(
      (activeLeader: ActiveLeaders) => activeLeader.roleId === valueRadio
    ) || { roleId: '', role: '', id: '' };
    setSlot({ start: moment(startDate), end: moment(endDate) });
    setRole({ id: roleId, name });
    setLeadershipId(id);
    proceed();
    window.scrollTo(0, 0);
  };

  const handleChangeRole = (roleId: string) => {
    setValueRadio(roleId);
  };

  return (
    <>
      <h2 className="text-xl my-2 leading-6">
        {t(
          'club-replace-officer.select-role.title',
          'Select a Role to Replace'
        )}
      </h2>

      {individual && (
        <IndividualListColumn
          fullName={individual.value.name}
          photoUri={individual.value.photoUri || ''}
          id={individual.value.id}
          usingFlex
          wrapperClasses="my-7"
        />
      )}

      {activeLeaders && (
        <div className="mt-4">
          <Radios
            id="available-roles"
            name="radioFieldSelector"
            legend={t(
              'select-role-to-replace.title',
              'Select a Role to Replace'
            )}
            value={valueRadio}
            options={activeLeaders.map((activeLeader: ActiveLeaders) => {
              const isDisabled = isToday(activeLeader.startDate);
              return {
                value: activeLeader.roleId,
                label: getClubRoleTValue(t, activeLeader.role),
                description: undefined,
                disabled: isDisabled,
              };
            })}
            changeHandler={handleChangeRole}
            classes={{
              radioBtn: 'z-0',
              optionWrapper: 'my-4',
            }}
          />
        </div>
      )}

      <div className="max-w-lg">
        <ActionButtons
          wrapperClassNames="flex flex-col mt-13"
          submitBtnLabel={t(
            'club-add-officer.select-role.continue-button',
            'Continue'
          )}
          cancelBtnLabel={t(
            'club-add-officer.select-role.cancel-button',
            'Cancel'
          )}
          isSubmitDisabled={valueRadio === ''}
          cancelUrl={getClubMembersPath(club.value.id)}
          onSubmit={handleGoToNextStep}
        />
      </div>
    </>
  );
};

export default ClubOfficerMultipleRoles;
