import React, { useState } from 'react';

import { RouteComponentProps } from '@reach/router';
import { Form, Formik } from 'formik';
import { size } from 'lodash';
import moment from 'moment';

import { Button } from '@components/Button';
import Calendar from '@components/Formik/Calendar';
import { SponsorSelect } from '@components/Forms/Select/SponsorSelect';
import Loading from '@components/Loading';
import Note from '@components/Note';
import PageSteps from '@components/PageSteps';

import IndividualInfo from '../IndividualInfo';
import { getCalendarValues } from './utils';
import { changeMembershipValidationSchema } from './validationSchema';

import { CLUB_MEMBERSHIP_MANAGEMENT_STEPS } from '@domain/clubs/constants';

import {
  getClubMembersPath,
  getEditMembershipPath,
  isMemberOrRotaractor,
} from '@use-cases/clubs';
import { useChangeMembership } from '@use-cases/clubs/hooks';
import { useErrorHandling } from '@use-cases/notifications';

import { useFetchMembershipInfo } from '@repositories/clubs';

import { useTranslation } from '@external/react-i18next';

import { MembershipType } from '@typings/operations';

export type ChangeMembershipProps = {
  clubId: string;
  membershipId: string;
} & RouteComponentProps;

type FormValues = {
  effectiveDate: Date;
};

const CALENDAR_DATE_FORMAT = 'dd MMM yyyy';

const ChangeMembership: React.FC<ChangeMembershipProps> = ({
  clubId,
  membershipId,
}) => {
  const { t } = useTranslation();

  const [sponsorId, setSponsorId] = useState<Array<string>>([]);

  const changeMembership = useChangeMembership();
  const { data, loading, error } = useFetchMembershipInfo(clubId, membershipId);

  useErrorHandling(error?.message, !!error);

  if (loading || !data?.membership) {
    return <Loading />;
  }

  const {
    club,
    admissionDate,
    type: membershipType,
    individual,
  } = data.membership;

  const { photoUri, id, name } = individual;

  const isMemberOrRotaractorMembershipType = isMemberOrRotaractor(
    membershipType
  );

  const {
    initialEffectiveDate,
    maxEditableDate,
    minEditableDate,
    isDisabled,
    minCalendarDate,
  } = getCalendarValues(admissionDate);

  const getUpdatedMembershipType = (membershipType: MembershipType) => {
    const updatedMembershipType: Record<MembershipType, MembershipType> = {
      [MembershipType.Member]: MembershipType.Honorary,
      [MembershipType.Honorary]: MembershipType.Member,
      [MembershipType.Rotaractor]: MembershipType.RotaractHonorary,
      [MembershipType.RotaractHonorary]: MembershipType.Rotaractor,
    };
    return updatedMembershipType[membershipType];
  };

  const handleOnSubmit = (values: FormValues) => {
    const updatedValues = {
      type: getUpdatedMembershipType(membershipType),
      admissionDate: moment(values.effectiveDate).format('YYYY-MM-DD'),
      clubId,
      membershipId,
      ...(sponsorId.length && { sponsorIndividualId: sponsorId }),
    };

    changeMembership(updatedValues);
  };

  return (
    <Formik
      initialValues={{
        effectiveDate: initialEffectiveDate,
      }}
      onSubmit={handleOnSubmit}
      validationSchema={changeMembershipValidationSchema(
        t,
        minEditableDate,
        maxEditableDate
      )}
    >
      {({ isValid, errors, isSubmitting }) => {
        const { effectiveDate: validationError } = errors;

        return (
          <Form>
            <PageSteps
              className="p-0"
              backBtnClassName="inline-flex items-baseline font-bold text-bright-blue-600 text-xs leading-xs-heading"
              to={getEditMembershipPath(clubId, membershipId)}
              step={CLUB_MEMBERSHIP_MANAGEMENT_STEPS.CHANGE_MEMBERSHIP}
              total={size(CLUB_MEMBERSHIP_MANAGEMENT_STEPS)}
              isArrowLarge
              navClassName="mt-6 desktop:mt-8 text-small font-bold"
            />

            <h2 className="mt-4 mb-7 font-light">
              {isMemberOrRotaractorMembershipType
                ? t(
                    'change-membership.form.change-to-honorary-title',
                    'Change to Honorary Member'
                  )
                : t(
                    'change-membership.form.change-to-active-title',
                    'Change to Active Member'
                  )}
            </h2>
            <IndividualInfo
              photoUri={photoUri}
              individualId={id}
              individualName={name}
              club={club}
            />
            {isMemberOrRotaractorMembershipType && (
              <Note className="mb-7 desktop:mb-8">
                <p className="text-md">
                  {t(
                    'change-membership.form.member-can-no-longer-hold-office',
                    'This member can no longer hold office'
                  )}
                </p>
                <p className="text-md">
                  {t(
                    'change-membership.form.changing-member-to-honorary-text',
                    "Changing Member Type to Honorary will end this member's eligibility to hold office and will remove current position(s) and remove any future position(s). *RI Bylaws, Article 4.050.2, Club Constitution Article 7, Section 6B."
                  )}
                </p>
              </Note>
            )}

            <div className="max-w-lg" data-testid="change-membership-form">
              <Calendar
                name="effectiveDate"
                label={t(
                  'change-membership.form.effective-date-label',
                  'Effective Date'
                )}
                required
                disabled={isDisabled}
                minDate={minCalendarDate}
                maxDate={maxEditableDate}
                dateFormat={CALENDAR_DATE_FORMAT}
              />
              {!isValid && (
                <Note>
                  <p>{validationError}</p>
                </Note>
              )}
              {membershipType === MembershipType.Honorary && (
                <SponsorSelect
                  selectId="sponsorId"
                  selectName="sponsorId"
                  selectLabel={t(
                    'change-membership.form.sponsored-by-label',
                    'Sponsored by'
                  )}
                  description={t(
                    'change-membership.form.sponsors-maximum',
                    '2 sponsors maximum'
                  )}
                  handleChange={(individualId: string[]) =>
                    setSponsorId(individualId)
                  }
                  clubId={clubId}
                  currentIndividualId={individual.id}
                  admissionDate={admissionDate}
                />
              )}
            </div>
            <div className="mt-15 flex flex-col">
              <Button
                className="max-w-lg w-full"
                type="submit"
                disabled={!isValid || isSubmitting}
              >
                {t('change-membership.form.submit-label', 'Save Changes')}
              </Button>
              <Button
                to={getClubMembersPath(clubId)}
                text
                className="max-w-lg w-full mt-6"
              >
                {t('change-membership.form.cancel-button', 'Cancel')}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ChangeMembership;
