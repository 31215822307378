import { useLazyQuery } from '@apollo/client';

import { fetchDominoOrganizationIdQuery } from '../queries';

import {
  FetchDominoOrganizationIdQuery,
  FetchDominoOrganizationIdQueryVariables,
} from '@typings/operations';

export const useFetchDominoOrganizationId = () =>
  useLazyQuery<
    FetchDominoOrganizationIdQuery,
    FetchDominoOrganizationIdQueryVariables
  >(fetchDominoOrganizationIdQuery);
