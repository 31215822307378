// Libs
import React from 'react';

// Hooks
import { getDayLabel } from '@utils/getDayLabel';

import { useTranslation } from '@external/react-i18next';

// Utils

// Types
import { Meeting as MeetingOutput, MeetingType } from '@typings/operations';

type Props = { meeting: MeetingOutput };

const MeetingPreview: React.FC<Props> = ({
  meeting: {
    name,
    language,
    comment,
    weekday,
    frequency,
    time,
    type,
    address,
    locationVaries,
    locationNameOrDetail,
    onlineLocation,
    detailForJoiningRemotely,
  },
}) => {
  const { t } = useTranslation();

  const getWhenText = (time: string, weekday?: string) =>
    `${getDayLabel(t, weekday ? String(weekday) : '')} ${
      weekday && time ? t('club-details.meetings.when-connector', 'at') : ''
    } ${time}`;

  return (
    <>
      <h2 className="break-normal">{name}</h2>
      <h3 className="h5">
        {t('club-details.meetings.language-label', 'Language')}
      </h3>
      <p>{language}</p>
      <h3 className="h5">{t('club-details.meetings.notes-label', 'Notes')}</h3>
      <p>{comment}</p>
      <h3 className="h5">{t('club-details.meetings.when-label', 'When')}</h3>
      <p>
        {getWhenText(time, weekday)}
        <br />
        {frequency}
      </p>
      {(type === MeetingType.Physical || type === MeetingType.Hybrid) && (
        <>
          <h3 className="h5">
            {t(
              'club-details.meetings.location-label-physical',
              'Where (In-Person)'
            )}
          </h3>
          <p>{locationNameOrDetail}</p>
          <p>
            {!locationVaries && (
              <>
                {address?.lineOne && (
                  <span className="block">{address.lineOne}</span>
                )}
                {address?.lineTwo && (
                  <span className="block">{address.lineTwo}</span>
                )}
                {address?.lineThree && (
                  <span className="block">{address.lineThree}</span>
                )}
              </>
            )}
            {address?.city && <span>{address.city}</span>}
            {address?.postalCode && <span>, {address.postalCode}</span>}
            {address?.country && <span>, {address.country}</span>}
            {address?.state && <span className="block">{address.state}</span>}
          </p>
        </>
      )}
      {(type === MeetingType.Online || type === MeetingType.Hybrid) && (
        <>
          <h3 className="h5">
            {t('club-details.meetings.location-label-online', 'Where (Online)')}
          </h3>
          <div>
            <div>{onlineLocation}</div>
            <div>{detailForJoiningRemotely}</div>
          </div>
        </>
      )}
    </>
  );
};

export default MeetingPreview;
