import React, { useState } from 'react';

import Loading from '@components/Loading';
import PageSteps from '@components/PageSteps';

import CandidateClubMemberResult from './CandidateClubMemberResult';
import ConsolidateMemberRecord from './ConsolidateMemberRecord';

import { getCandidateDetailsPath } from '@use-cases/leads';
import { useErrorHandling } from '@use-cases/notifications';

import { useFetchMemberDetails } from '@repositories/clubs';

import { localizedNavigate } from '@utils/localized-navigate';

const IdentifyClubCandidate: React.FC<{
  isRotaractClub: boolean;
  clubId: string;
}> = ({ clubId, isRotaractClub }) => {
  enum IdentifyClubCandidateLeadSteps {
    IDENTIFY,
    CONSOLIDATE,
  }
  const noPaddingClass = 'p-0';
  const navClassName = 'text-small font-bold mt-4';
  const backBtnClassName = 'font-bold text-bright-blue-600';
  const [step, setStep] = useState<IdentifyClubCandidateLeadSteps>(
    IdentifyClubCandidateLeadSteps.IDENTIFY
  );

  // Initiate a state for the current member id.
  // - undefined: no member was selected and the user did not choose to create one
  // - false: no member was selected, the user decided to create a new one
  // - string: the ID of the selected member
  const [selectedMember, selectMember] = useState<undefined | string | false>(
    undefined
  );

  // When a member has been selected, attempt to load data from the api.
  const { data, loading, error } = useFetchMemberDetails(
    selectedMember || '',
    clubId
  );

  useErrorHandling(error?.message, !!error, 'member-details.error');

  // If member data has been loaded, message it into InitialData.
  const individual = data?.addMemberGetIndividual.individual;

  const handleBackClick = () => {
    localizedNavigate(getCandidateDetailsPath(clubId));
  };

  if (loading) {
    return <Loading />;
  }

  switch (step) {
    case IdentifyClubCandidateLeadSteps.IDENTIFY:
      return (
        <PageSteps
          step={1}
          total={2}
          backHandler={handleBackClick}
          className={noPaddingClass}
          navClassName={navClassName}
          backBtnClassName={backBtnClassName}
        >
          <CandidateClubMemberResult
            clubId={clubId}
            isRotaractClub={isRotaractClub}
            selectMember={(id: string) => {
              selectMember(id);
              if (id !== undefined) {
                setStep(IdentifyClubCandidateLeadSteps.CONSOLIDATE);
                window.scrollTo(0, 0);
              }
            }}
          />
        </PageSteps>
      );
    case IdentifyClubCandidateLeadSteps.CONSOLIDATE:
      return (
        <PageSteps
          step={2}
          total={2}
          backHandler={() => setStep(IdentifyClubCandidateLeadSteps.IDENTIFY)}
          className={noPaddingClass}
          navClassName={navClassName}
          backBtnClassName={backBtnClassName}
        >
          <div>
            <ConsolidateMemberRecord
              clubId={clubId}
              data={individual}
              districtId=""
            />
          </div>
        </PageSteps>
      );
    default:
      return null;
  }
};
export default IdentifyClubCandidate;
