import * as Yup from 'yup';
import { TFunction } from 'i18next';
import {
  isRequired,
  schemaField,
} from '@components/Formik/validation/schemaDefinitions';

const stringReqSchema = (t: TFunction) =>
  schemaField(t, Yup.string(), isRequired);

const validationSchema = (t: TFunction) =>
  Yup.object().shape({
    procedureCertification: schemaField(t, Yup.boolean()),
    memberType: stringReqSchema(t),
    admissionDate: stringReqSchema(t).nullable(),
    sponsoredBy: Yup.array().of(stringReqSchema(t)),
  });

export default validationSchema;
