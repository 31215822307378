import React from 'react';

import { camelCase } from 'lodash';

import MemberPreview from '@components/MemberPreview';
import BlockWrapper from '@presenters/web/pages/Districts/Details/BlockWrapper';

import { getClubRoleTValue } from '@utils/getClubRoleTValue';

import { useTranslation } from '@external/react-i18next';

import { ClubLeadershipItem, Role } from '@typings/graphql';

type ClubDetailOfficer = Pick<
  ClubLeadershipItem,
  'individualName' | 'primaryEmail' | 'photoUri' | 'individualId'
> & {
  role: Pick<Role, 'name'>;
};

interface Props {
  leaders: Array<ClubDetailOfficer>;
  isEditable: boolean;
  handleGoToOfficers?: () => void;
  profilePreviousLinkPath?: string;
}
const ClubOfficers: React.FC<Props> = ({
  isEditable,
  leaders,
  handleGoToOfficers,
  profilePreviousLinkPath,
}) => {
  const { t } = useTranslation();

  return (
    <BlockWrapper
      title={t('club-details.club-officers.title', 'Club Officers')}
      titleWithArrow={isEditable}
      handleClick={isEditable ? handleGoToOfficers : undefined}
    >
      {leaders.map(
        ({ individualName, primaryEmail, photoUri, individualId, role }) => (
          <div key={`leaders-${camelCase(role.name)}`}>
            <div className="text-2xs text-gray-600 font-bold mb-2">
              {getClubRoleTValue(t, role.name)}
            </div>
            <MemberPreview
              name={individualName || ''}
              path={`/profile/${individualId}`}
              photoUri={photoUri}
              email={primaryEmail}
              noLinkStyles
              linkClassName="underline text-xs text-gray-600 font-bold block"
              key={`members-${individualId}`}
              dataTestId="officer-item"
              profilePreviousLinkPath={profilePreviousLinkPath}
            />
          </div>
        )
      )}
    </BlockWrapper>
  );
};

export default ClubOfficers;
