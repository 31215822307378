import { useMutation } from '@apollo/client';

import { addClubMeetingQuery } from '@repositories/clubs';

import {
  AddClubMeetingMutation,
  AddClubMeetingMutationVariables,
} from '@typings/operations';

export const useAddClubMeeting = () =>
  useMutation<AddClubMeetingMutation, AddClubMeetingMutationVariables>(
    addClubMeetingQuery,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        'fetchClubDetails',
        'fetchAddMeeting',
        'fetchEditMeeting',
      ],
    }
  );
