import React, { useContext, useEffect, useState } from 'react';

import { RouteComponentProps } from '@reach/router';

import Loading from '@components/Loading';
import { ResultsList, ResultsListItem } from '@components/ResultsList';
import { titleTemplateParams } from '@components/Title/util';
import { LocationStateType } from '@presenters/web/pages/Districts/Officers/DistrictOfficersHeader';

import ClubOfficerRoleItem from './ClubOfficerRoleItem';
import ClubOfficersHeader from './ClubOfficersHeader';

import { getClubOffisersRoles, getOfficersPagePath } from '@use-cases/clubs';
import { useErrorHandling } from '@use-cases/notifications';

import { useFetchClubOfficers } from '@repositories/clubs';

import { localizedNavigate } from '@utils/localized-navigate';
import { getCurrentTermStartYear } from '@utils/officers';

import { ClubLeadersContext } from '@layouts/club';

import { Helmet } from '@external/react-helmet-async';
import { useTranslation } from '@external/react-i18next';

interface Props extends RouteComponentProps {
  clubId: string;
  clubType: string;
}

type LocationState = Record<string, string>;

const ClubOfficers: React.FC<Props> = ({ clubId, clubType, location }) => {
  const { t } = useTranslation();
  const { suffix } = titleTemplateParams(t);
  const currentTermStartYear = getCurrentTermStartYear();

  const [termStartYear, setTermStartYear] = useState(
    Number((location?.state as LocationState)?.termStartYear) ||
      currentTermStartYear
  );

  const { data: clubOfficersData, loading, error } = useFetchClubOfficers({
    clubId,
    termStartYear,
    roleValidOn: `${termStartYear}-07-01`,
    clubType,
  });

  const [, setClubLeadersContext] = useContext(ClubLeadersContext);

  useEffect(() => {
    const clubLeaders = clubOfficersData?.club?.leaders;
    if (clubLeaders?.length) {
      setClubLeadersContext(clubLeaders);
    }
  }, [clubOfficersData]);

  useErrorHandling(error?.message, !!error, 'club-officers.error');

  if (loading || error || !clubOfficersData?.club || !clubOfficersData?.roles) {
    return <Loading />;
  }

  const clubOfficersRoles = getClubOffisersRoles(
    clubOfficersData,
    termStartYear
  );

  const filteredClubOfficersRoles = clubOfficersRoles.filter(
    role => role.officers.length
  );

  const handleRoleSelect = (
    startDate: string,
    endDate: string,
    role?: string,
    roleId?: string
  ) => {
    localizedNavigate(
      `${getOfficersPagePath(
        clubId
      )}/assign-member/${roleId}/${startDate}/${endDate}`
    );
  };

  const handleTermSelect = (newValue: string) => {
    setTermStartYear(Number(newValue));

    // replaceState is used to set state for the current path in Reach router
    // so when you navigate back the termStartYear value will be restored from it

    // eslint-disable-next-line no-restricted-globals
    history.replaceState(
      { termStartYear: Number(newValue) },
      '',
      window.location.pathname
    );
  };

  return (
    <div className="mt-6">
      <Helmet
        titleTemplate={`${clubOfficersData.club.clubName}: %s ${suffix}`}
      />

      <ClubOfficersHeader
        selectedYear={termStartYear.toString()}
        handleYearChanged={handleTermSelect}
        clubId={clubId || ''}
        locationState={location?.state as LocationStateType}
      />

      <ResultsList
        className="club-roster-result py-0 -mx-5 tablet:mx-0 desktop:mt-8 mt-4"
        headerClassName="desktop:bg-gray-100 px-5"
        summary={
          <h2 className="font-bold uppercase tracking-wide text-sm mb-0">
            {t('club-officers.members.headline', 'Club Officers')} (
            {filteredClubOfficersRoles.length})
          </h2>
        }
      >
        {filteredClubOfficersRoles.map(role => (
          <ResultsListItem key={role.name} columnLayout>
            <ClubOfficerRoleItem
              role={role}
              clubId={clubId || ''}
              handleRoleSelect={handleRoleSelect}
              termStartYear={termStartYear.toString()}
            />
          </ResultsListItem>
        ))}
      </ResultsList>
    </div>
  );
};

export default ClubOfficers;
