import moment from 'moment';

export const getClubOfficerCalendarStartDate = (
  termStartDate: string | undefined,
  admissionDate?: string | null
) => {
  const formattedTermStartDate = moment(termStartDate);
  const formattedAdmissionStartDate =
    admissionDate?.length === 0
      ? formattedTermStartDate
      : moment(admissionDate);
  if (formattedAdmissionStartDate.toDate() < formattedTermStartDate.toDate()) {
    return formattedTermStartDate;
  }
  return formattedAdmissionStartDate;
};
