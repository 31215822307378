import React from 'react';

import classNames from 'classnames';
import { Form, Formik } from 'formik';

import { Button } from '@components/Button';
import TextArea from '@components/Formik/TextArea';
import ContactInformationAddress from '@presenters/web/pages/Profile/View/ContactInformation/ContactInformationAddress';
import ContactInformationEmail from '@presenters/web/pages/Profile/View/ContactInformation/ContactInformationEmail';
import ContactInformationPhone from '@presenters/web/pages/Profile/View/ContactInformation/ContactInformationPhone';

import PrivacyPolicy from './AddCandidate/AddressInformation/PrivacyPolicy';

import { useTranslation } from '@external/react-i18next';

import { MemberDetailsQuery } from '@typings/operations';

export interface MemberDetailsProps {
  data: MemberDetailsQuery['addMemberGetIndividual']['individual'] | undefined;
  handleBackClick: () => void;
}

const AddClubCandidate: React.FC<MemberDetailsProps> = ({
  children,
  data,
  handleBackClick,
}) => {
  const { t } = useTranslation();

  const initialValues = {
    ...data,
  };

  return (
    <>
      <p className="mt-5 mb-0">
        <button
          type="button"
          onClick={handleBackClick}
          className={classNames('text-small font-bold link-styles')}
        >
          &#8249; {t('page-steps.back-button', 'Back')}
        </button>
      </p>
      <div className="mt-5">
        <h2>{t('mml.add-member-form.title', 'Add new candidate')}</h2>
      </div>
      <Formik initialValues={initialValues} onSubmit={() => {}}>
        {({ values }) => {
          return (
            <Form>
              <div className="mt-8">
                <h3 className="h5">
                  {t('mml.add-member-form.member-id', 'Member ID')}
                </h3>
                {values.riIndividualId}
              </div>

              {values.firstName && (
                <div className="mt-10">
                  <h3 className="h5">
                    {t('mml.add-member-form.first-name', 'First name')}
                  </h3>
                  {values.firstName}
                </div>
              )}

              {values.lastName && (
                <div className="mt-10">
                  <h3 className="h5">
                    {t('mml.add-member-form.last-name', 'Last name')}
                  </h3>
                  {values.lastName}
                </div>
              )}

              {values.primaryEmail && (
                <div className="mt-10">
                  <h3 className="h5">
                    {t('mml.add-member-form.email-primary', 'Primary email')}
                  </h3>
                  <ContactInformationEmail email={values.primaryEmail} />
                </div>
              )}

              {values.primaryPhone && (
                <div className="mt-10">
                  <h3 className="h5">
                    {t('mml.add-member-form.phone-primary', 'Primary phone')}
                  </h3>
                  <ContactInformationPhone phone={values.primaryPhone} />
                </div>
              )}

              {values.primaryAddress && (
                <div className="mt-10">
                  <ContactInformationAddress
                    address={values.primaryAddress}
                    label={t(
                      'mml.add-member-form.address-primary',
                      'Primary address'
                    )}
                  />
                </div>
              )}

              <div className="max-w-lg mt-10">
                <TextArea
                  name="comments"
                  label={t(
                    'mml.add-member-form.additional-comments',
                    'Additional comments'
                  )}
                  characters={250}
                  areaclasses="h-40"
                />
              </div>

              <div className="max-w-lg mt-10">
                <PrivacyPolicy />
              </div>

              <div className="max-w-lg">
                <Button full className="mt-16 mb-6" clickHandler={() => {}}>
                  {t('mml.add-member-form.submit', 'Submit')}
                </Button>
                {children}
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default AddClubCandidate;
