import { useEffect, useState } from 'react';

import tailwind from '@styles/tailwind.js';

import { useWindowSize } from '@hooks/useWindowSize';

const SCREENS = tailwind.theme.screens;

export enum ScreenSizes {
  mobile = SCREENS.mobile,
  mobileM = SCREENS['mobile-m'],
  mobileL = SCREENS['mobile-l'],
  tablet = SCREENS.tablet,
  tabletL = SCREENS['tablet-l'],
  desktop = SCREENS.desktop,
  desktopS = SCREENS['desktop-s'],
  desktopSM = SCREENS['desktop-s-m'],
  desktopM = SCREENS['desktop-m'],
  desktopL = SCREENS['desktop-l'],
}

export const useScreenSizeLayout = (requiredScreenSize: ScreenSizes) => {
  const { width } = useWindowSize();
  const [isCorrectSizedLayout, setCorrectSizedLayout] = useState(false);

  useEffect(() => {
    setCorrectSizedLayout(width < parseInt(String(requiredScreenSize), 10));
  }, [width]);

  return isCorrectSizedLayout;
};
