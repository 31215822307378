import React from 'react';
import { useTranslation } from '@external/react-i18next';
import BaseModal from '.';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  onConfirm: () => void;
  title: string;
}

const DeleteConfirmationModal: React.FC<Props> = ({
  isOpen,
  closeModal,
  onConfirm,
  title,
}) => {
  const { t } = useTranslation();

  return (
    <BaseModal
      isOpen={isOpen}
      closeModal={closeModal}
      onConfirm={onConfirm}
      contentLabel={t('global.modals.delete.label', 'Confirm Deletion')}
      title={title}
      text={t('global.modals.delete.confirm-text', 'This cannot be undone.')}
      confirmButtonLabel={t('global.modals.delete.confirm-label', 'Confirm')}
      cancelButtonLabel={t('global.modals.delete.cancel-label', 'Cancel')}
    />
  );
};

export default DeleteConfirmationModal;
