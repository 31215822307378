import React from 'react';

import { useField } from 'formik';

import { ElementProps } from './Element';

interface Props extends ElementProps {}
const ToggleField: React.FC<Props> = props => {
  const [field, , helpers] = useField<boolean>(props);
  // <!-- On: "bg-bright-blue-500", Off: "bg-gray-200" -->
  const { value } = field;
  return (
    <span
      onClick={() => helpers.setValue(!value)}
      onKeyDown={e => {
        if (e.keyCode === 32) {
          helpers.setValue(!value);
        }
      }}
      role="checkbox"
      tabIndex={0}
      aria-checked={value}
      className={`${
        value ? 'bg-bright-blue-500' : 'bg-gray-200'
      } relative inline-block flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:outline-0`}
    >
      {/* <!-- On: "translate-x-5", Off: "translate-x-0" --> */}
      <span
        aria-hidden="true"
        className={`${
          value ? 'translate-x-5' : 'translate-x-0'
        } inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200`}
      />
    </span>
  );
};

export default ToggleField;
