import React from 'react';

import Collapse from '@kunukn/react-collapse';
import classNames from 'classnames';

import { Avatar } from '@components/Avatar';
import { Button } from '@components/Button';
import Icon from '@components/Icon';

import { useTranslation } from '@external/react-i18next';

import { IOfficerResultRow } from '../types';

export const IdentifyOfficerResultMobile: React.FC<IOfficerResultRow> = ({
  name,
  individualId,
  photoUri,
  role,
  email,
  phoneNumber,
  selectMember,
  expanded,
  memberSince = '',
  isDisabledHonorary = false,
  isDisabled = false,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(expanded);

  return (
    <div
      className={classNames('w-full p-5', {
        'bg-bright-blue-100': isOpen,
        'pb-8': isOpen,
      })}
    >
      <button
        onClick={() => setIsOpen(!isOpen)}
        type="button"
        className={classNames(
          'relative w-full flex text-left text-xs font-normal leading-6 focus:shadow-none',
          { 'mb-5': isOpen }
        )}
      >
        <Avatar photoUri={photoUri || undefined} />
        <div className="w-4/6">
          <div className={classNames('mr-auto self-center flex-auto w-full')}>
            <h4 className="mb-0 pb-1">{name}</h4>
            <p className="mb-0">{role}</p>
            <p className="mb-0">
              {t(
                'club-add-officer.select-member.member-since',
                'Member since {{ date }}',
                { date: memberSince }
              )}
            </p>
          </div>
        </div>
        <div
          aria-hidden="true"
          className={classNames('absolute right-0 absolute-vertical-center', {
            hidden: isOpen,
          })}
        >
          <Icon name="arrows/down" size="16" />
        </div>
        <div
          aria-hidden="true"
          className={classNames('absolute right-0 top-0', {
            hidden: !isOpen,
          })}
        >
          <Icon name="ui/close" size="16" />
        </div>
      </button>
      <Collapse isOpen={isOpen} className="collapse-css-transition">
        <div className="w-full">
          <div className="pl-16 pr-8 mb-5 text-xs leading-xs">
            <p className="mb-0">{email}</p>
            {phoneNumber && <p className="mb-0">{phoneNumber}</p>}
            {isDisabledHonorary && (
              <p className="mb-0 text-red-600">
                {t(
                  'club-add-officer.select-member.disabled-reason',
                  'Honorary members cannot be officers.'
                )}
              </p>
            )}
          </div>
          <div>
            <Button
              dataTestId="mobile-select-btn"
              clickHandler={() => selectMember(individualId)}
              disabled={isDisabled}
              full
            >
              {t('club-add-officer.select-member.select-button', 'Select')}
            </Button>
          </div>
        </div>
      </Collapse>
    </div>
  );
};
