import React from 'react';

import Select, { SelectProps } from '@components/Formik/Select';

import { useTranslation } from '@external/react-i18next';

import { MeetingType } from '@typings/operations';

const MeetingTypeSelect: React.FC<Omit<SelectProps, 'options'>> = props => {
  const { t } = useTranslation();
  const options = [
    {
      label: t('global.meetingtype.physical', 'In Person Only'),
      value: MeetingType.Physical,
    },
    {
      label: t('global.meetingtype.online', 'Online Only'),
      value: MeetingType.Online,
    },
    {
      label: t('global.meetingtype.hybrid', 'In Person and Online'),
      value: MeetingType.Hybrid,
    },
  ];
  return <Select {...props} options={options} />;
};

export default MeetingTypeSelect;
