import React, { useState } from 'react';

import { RouteComponentProps } from '@reach/router';

import LinkPrevious from '@components/LinkPrevious';
import Loading from '@components/Loading';
import DeleteConfirmationModal from '@components/Modals/DeleteConfirmationModal';
import Title from '@components/Title';

import Info from './Info';
import ManagementSystemsTable from './ManagementSystemsTable';

import { getBackButtonLabel, getClubDetailsPath } from '@use-cases/clubs';
import { useNotifications } from '@use-cases/notifications';

import { useTranslation } from '@external/react-i18next';
import { useTerminateClubVendor } from '@repositories/clubs/hooks/useTerminateClubVendor';

interface EditManagementSystemsProps extends RouteComponentProps {
  clubId: string;
}

const EditManagementSystems: React.FC<EditManagementSystemsProps> = ({
  clubId,
}) => {
  const { t } = useTranslation();
  const { addError } = useNotifications();

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [terminateVendorKey, setTerminateVendorKey] = useState('');
  const [terminateRequestorKey, setTerminateRequestorKey] = useState('');

  const [terminateVendor, { loading }] = useTerminateClubVendor();

  const handleDelete = async () => {
    try {
      await terminateVendor({
        variables: {
          input: {
            vendorKey: terminateVendorKey,
            clubKey: clubId,
            requestorKey: terminateRequestorKey,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: ['fetchVendors'],
      });
    } catch (error) {
      addError(
        t(
          'club-editmanagementsystems.add-vendor.deletion-error',
          'Could not terminate vendor'
        ),
        { id: 'club-editmanagementsystems.add-vendor.deletion-error' }
      );
    } finally {
      setIsConfirmationModalOpen(false);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <LinkPrevious
        path={getClubDetailsPath(clubId)}
        label={getBackButtonLabel(t)}
        classes="mt-4"
      />
      <Title>
        {t('club-editmanagementsystems.title', 'Edit Club Management Systems')}
      </Title>
      <Info />
      <ManagementSystemsTable
        clubId={clubId}
        handleDelete={(vendorKey, requestorKey) => {
          setTerminateVendorKey(vendorKey);
          setTerminateRequestorKey(requestorKey);
          setIsConfirmationModalOpen(true);
        }}
      />
      <DeleteConfirmationModal
        isOpen={isConfirmationModalOpen}
        closeModal={() => setIsConfirmationModalOpen(false)}
        onConfirm={handleDelete}
        title={t(
          'club-editmanagementsystems.confirm-deletion.title',
          'Are you sure you want to terminate this vendor?'
        )}
      />
    </>
  );
};

export default EditManagementSystems;
