import * as React from 'react';
import { useEffect, useRef } from 'react';

import { RouteComponentProps } from '@reach/router';
import qs from 'query-string';

import { Button } from '@components/Button';
import SearchMembersForm from '@components/Formik/SearchMembersForm';
import Loading from '@components/Loading';
import { Pagination } from '@components/Pagination';
import { ResultsList, ResultsListItem } from '@components/ResultsList';
import { titleTemplateParams } from '@components/Title/util';
import RosterResult from '@presenters/web/pages/Clubs/Members/RosterResult';

import {
  getMembershipStatusOptions,
  useClubMembersFilters,
} from '@use-cases/clubs';
import {
  getCandidateDetailsPath,
  getCovertCandidateToMemberPath,
} from '@use-cases/leads';
import { useNotifications } from '@use-cases/notifications';

import { useUserAccount } from '@repositories/auth/hooks';
import { useFetchClubMembersWithFilters } from '@repositories/clubs';

import { localizedNavigate } from '@utils/localized-navigate';
import { closeChannel, getChannel } from '@utils/sendMessage';

import { ROTARY_ASSISTANCE_EMAIL } from '@constants/index';

import { Helmet } from '@external/react-helmet-async';
import { useTranslation } from '@external/react-i18next';
import { useAppConfig } from '@hooks/appConfig';

import { MembershipStatusFilter } from '@typings/operations';

interface Props extends RouteComponentProps {
  clubId: string;
  isRotaractClub?: boolean;
  selectMember: (id: string) => void;
}

const PAGE_SIZE = 10;

const CandidateClubMemberResult: React.FC<Props> = ({
  clubId,
  isRotaractClub = false,
  location,
  selectMember,
}) => {
  const { t } = useTranslation();
  const { prefix, suffix } = titleTemplateParams(t);
  const { addInfo } = useNotifications();
  const addInfoRef = useRef(addInfo);

  const { user } = useAppConfig();

  const individualIdForFetch = (user?.isLoggedIn && user?.individualId) || null;

  const { data: userData, loading: userDataLoading } = useUserAccount(
    individualIdForFetch
  );

  const isMultifilterAllowed = true;

  const allMembershipStatuses = getMembershipStatusOptions(
    t,
    true,
    isRotaractClub
  );

  const { membershipStatus } = location
    ? qs.parse(location.search)
    : { membershipStatus: null };

  const membershipStatusOption =
    (membershipStatus &&
      allMembershipStatuses.filter(
        option => option.value === membershipStatus
      )) ||
    [];

  const {
    searchValues,
    filterValues,
    currentPage,
    setCurrentPage,
    handleSearch,
  } = useClubMembersFilters(membershipStatusOption);

  useEffect(() => {
    const addMemberChannel = getChannel('add-member');
    addMemberChannel.onmessage = event => {
      addInfoRef.current(event?.data, { id: 'members-notify' });
    };

    const manageClubOfficersChannel = getChannel('manage-club-officers');
    manageClubOfficersChannel.onmessage = event => {
      addInfoRef.current(event?.data, { id: 'members-notify' });
    };

    // Close broadcast channels when the component unmounts
    return () => {
      closeChannel('add-member');
      closeChannel('manage-club-officers');
    };
  }, []);

  const { data, loading, filters } = useFetchClubMembersWithFilters(
    clubId,
    PAGE_SIZE,
    currentPage,
    searchValues,
    filterValues,
    isMultifilterAllowed
  );

  const pageHandler = (event: React.SyntheticEvent, pageNumber: number) => {
    event.preventDefault();
    setCurrentPage(pageNumber);
  };

  if (loading || userDataLoading) {
    return <Loading />;
  }

  if (!data || !data?.club) {
    return null;
  }

  const isLeadershipRole =
    userData?.getIndividual.clubAffiliations.some(
      ({ affiliations, clubId: affilationClubId }) =>
        clubId === affilationClubId &&
        affiliations.some(item => item.__typename === 'LeadershipAffiliation')
    ) || false;

  const handleConfirmClick = () => {
    localizedNavigate(getCovertCandidateToMemberPath(clubId, 'individualId'));
  };
  const handleCancelClick = () => {
    localizedNavigate(getCandidateDetailsPath(clubId));
  };

  return (
    <div data-label="roster">
      <Helmet
        titleTemplate={t(
          'metadata.title.club-members',
          '{{prefix}} Club members {{suffix}}',
          { prefix, suffix }
        )}
      />
      <h2>
        {t(
          'mml.identify-admitted-candidate.title',
          'Identify admitted candidate'
        )}
      </h2>
      <p className="text-small mt-4 tablet:mt-6 mb-0 tablet:mb-2">
        {t(
          'mml.identify-admitted-candidate.title-description',
          'Please choose a person from your club list to help our team consolidate our records. This person may have been reported as a new member in My Rotary or another club management system under a different name or ID number.'
        )}
      </p>
      <SearchMembersForm
        initialValues={searchValues}
        loading={loading}
        submitHandler={handleSearch}
        title={{
          classes:
            'mb-5 tablet:mb-0 mt-12 tablet:mt-8 tablet:normal-case capitalize',
          value: t(
            'club-roster.members.search-members-title',
            'Search club members'
          ),
        }}
        isLabelsHidden
      />
      <ResultsList
        darkBackground
        className="club-roster-result mt-15 mobile:-mx-5 tablet:mx-0"
        headerClassName="flex-wrap desktop-m:flex-nowrap"
        summary={
          <>
            <h2 className="font-bold uppercase tracking-wide text-sm mb-0">
              {t('club-roster.members.headline', 'Club members')} (
              {data.club.members.totalCount})
            </h2>
            {filters.membershipStatuses?.includes(
              MembershipStatusFilter.Terminated
            ) && (
              <span className="desktop-m:ml-3 order-5 desktop-m:order-2 font-extralight w-full desktop-m:w-auto">
                {t(
                  'club-roster.members.terminated-filter-headline',
                  'from 2015 on'
                )}
              </span>
            )}
            {filters.membershipStatuses?.includes(
              MembershipStatusFilter.Deceased
            ) && (
              <span className="desktop-m:ml-3 order-6 desktop-m:order-3 font-extralight w-full desktop-m:w-auto">
                {t(
                  'club-roster.members.deceased-filter-headline',
                  'past 2 years'
                )}
              </span>
            )}
          </>
        }
      >
        {data.club.members.results.map(member => (
          <ResultsListItem key={member.id || ''}>
            <RosterResult
              {...member}
              clubId={clubId}
              clubType={data.club.clubType}
              appliedRoleFilter={[]}
              appliedStatusFilter={[]}
              isLeadershipRole={isLeadershipRole}
              isLeads
              isThreeColumn
              selectMember={(id: string) => selectMember(id)}
            />
          </ResultsListItem>
        ))}
      </ResultsList>
      {data.club.members.totalCount > 0 && (
        <Pagination
          pageSize={PAGE_SIZE}
          page={currentPage}
          totalCount={data.club.members.totalCount}
          pageHandler={pageHandler}
        />
      )}
      <div className="flex-col w-120">
        <p className="mt-16 mb-0 text-xs font-bold text-gray-700  w-full desktop-m:w-auto">
          {t(
            'club-roster.members.cannot.identify.message',
            `Can’t identify your admitted candidate?`
          )}
        </p>
        <p className="text-gray-600 text-xs  font-normal w-full desktop-m:w-auto">
          {t(
            'club-roster.members.confirm.club.identify.message',
            `You can confirm the person as a club member.`
          )}
        </p>
      </div>
      <div className="desktop:w-120 tablet:w-full mobile:w-full">
        <Button
          full
          secondary
          className="mb-7 mt-7"
          type="button"
          clickHandler={handleConfirmClick}
        >
          {t(
            'mml-club-roster.members.confirm.button',
            'Confirm as club Member'
          )}
        </Button>
        <Button full text type="button" clickHandler={handleCancelClick}>
          {t('mml-club-roster.members.cancel.button', 'Cancel')}
        </Button>
      </div>
      <p className="text-small mt-4 tablet:mt-6 mb-0 tablet:mb-2">
        {t(
          'mml.identify-admitted-candidate.contact-data-service',
          `If you think there’s an error with your club member list
            <a href='mailto:{{email}}'>contact data services</a>.`,
          { email: ROTARY_ASSISTANCE_EMAIL }
        )}
      </p>
    </div>
  );
};

export default CandidateClubMemberResult;
