import React from 'react';

import moment from 'moment';

import { OfficerResultProps } from '@components/IdentifyOfficerResult/types';

import { IdentifyOfficerResultDesktop } from './IdentifyOfficerResultDesktop';
import { IdentifyOfficerResultMobile } from './IdentifyOfficerResultMobile';

import { specialRoles } from '@utils/roles';

import { ScreenSizes, useScreenSizeLayout } from '@hooks/useScreenSizeLayout';

import { MembershipType } from '@typings/graphql';

const IdentifyOfficerResult = (props: OfficerResultProps) => {
  const {
    type,
    individualIdDisabled,
    roleId,
    admissionDate,
    individualId,
    isReplaceOperation,
    isDisabled,
  } = props;
  const memberSince = moment(admissionDate).format('YYYY');
  // Honorary members of this club can't have officer roles in this club except
  // executive secretary / director role.
  const isDisabledHonorary =
    type === MembershipType.Honorary &&
    roleId !== specialRoles.clubExecutiveSecretaryDirector.id;

  const isDisabledOnReplace =
    individualIdDisabled === individualId && isReplaceOperation;

  const isUnderDesktopScreenSize = useScreenSizeLayout(ScreenSizes.desktop);

  return isUnderDesktopScreenSize ? (
    <IdentifyOfficerResultMobile
      {...props}
      memberSince={memberSince}
      isDisabledHonorary={isDisabledHonorary}
      isDisabled={isDisabledHonorary || isDisabledOnReplace || isDisabled}
    />
  ) : (
    <IdentifyOfficerResultDesktop
      {...props}
      memberSince={memberSince}
      isDisabledHonorary={isDisabledHonorary}
      isDisabled={isDisabledHonorary || isDisabledOnReplace || isDisabled}
    />
  );
};

export default IdentifyOfficerResult;
