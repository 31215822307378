import { gql, useLazyQuery } from '@apollo/client';

import {
  FetchRiIndividualIdQuery,
  FetchRiIndividualIdQueryVariables,
} from '@typings/operations';

export const fetchRiIndividualIdQuery = gql`
  query fetchRiIndividualId(
    $individualId: String!
    $delayAfterResponse: Int = 0
  ) {
    getIndividual(id: $individualId, delayAfterResponse: $delayAfterResponse) {
      riIndividualId
    }
  }
`;

export const useFetchRiIndividualId = () =>
  useLazyQuery<FetchRiIndividualIdQuery, FetchRiIndividualIdQueryVariables>(
    fetchRiIndividualIdQuery
  );
