import React, { useEffect, useState } from 'react';

import Link from '@components/Link';
import LinkEdit from '@components/LinkEdit';
import HarrisButton from '@presenters/web/components/Leads/HarrisButton';

import { ContactInformationProps } from '@domain/clubs';

import { useFetchMembershipsHistory } from '@repositories/leads/hooks';

import { getCountryCode } from '@utils/getCountryCode';
import { isActiveMembership } from '@utils/membershipUtils';

import { useTranslation } from '@external/react-i18next';
import { useAppConfig } from '@hooks/appConfig';

import { AccessLevel } from '@typings/graphql';

const ContactInformation: React.FC<ContactInformationProps> = ({
  mailingAddress: address,
  isEditable,
  editContactInformationLink,
  primaryEmail,
  primaryPhone,
  primaryFax,
  primaryWebsite,
  officialLanguage,
  isUserLoggedIn,
  countries,
  individualOperationAccess,
}) => {
  const { t } = useTranslation();
  const { user } = useAppConfig();

  const [joinClubButton, setJoinClubButton] = useState(!isUserLoggedIn);

  const [fetchMembershipsHistory, { data }] = useFetchMembershipsHistory();

  useEffect(() => {
    if (user?.individualId) {
      fetchMembershipsHistory({
        variables: {
          id: user?.individualId,
        },
      });
      const admissionDate = data?.getMembershipHistory[0]?.admissionDate || '';
      const terminationDate = data?.getMembershipHistory[0]?.terminationDate;

      const activeMemberShip = isActiveMembership({
        admissionDate,
        terminationDate,
      });

      if (!activeMemberShip && data && data?.getMembershipHistory.length >= 0) {
        setJoinClubButton(true);
      }
    }
  }, [user, data]);

  const country = countries.find(
    country => country.id === primaryPhone?.countryId
  );
  const countryPhoneCode = getCountryCode(country?.shortCode);
  const formattedPhoneNumber = `${countryPhoneCode && `+${countryPhoneCode} `}${
    primaryPhone?.number
  }`;

  const faxCountry = countries.find(
    country => country.id === primaryFax?.countryId
  );
  const faxCountryPhoneCode = getCountryCode(faxCountry?.shortCode || '');
  const faxFormattedPhoneNumber = `${
    faxCountryPhoneCode ? `+${faxCountryPhoneCode} ` : ''
  }${primaryFax?.number}`;

  const title = t(
    'club-details.contact-information.title',
    'Contact Information'
  );

  const noDetailsText = t('club-details.no-details', 'None');
  const showMailingAddress =
    individualOperationAccess !== AccessLevel.LimitedViewer;

  const ensureHttpPrefix = (url: string): string => {
    if (!/^https?:\/\//i.test(url)) {
      return `http://${url}`;
    }
    return url;
  };

  return (
    <div>
      <div className="flex justify-between">
        <h2>{title}</h2>
        {isEditable ? (
          <LinkEdit path={editContactInformationLink} component={title} />
        ) : (
          <span className="h5 alternate mb-0 text-gray-300">
            {t('club-details.rotary-club.not-editable', 'Not Editable')}
          </span>
        )}
      </div>
      <div className="flex flex-col">
        {primaryEmail && (
          <div className="my-4">
            <h5>
              {t(
                'club-details.contact-information.email-address-label',
                'Email'
              )}
            </h5>
            <span>
              <Link to={`mailto:${primaryEmail}`}>{primaryEmail}</Link>
            </span>
          </div>
        )}
        <div className="my-4">
          <h5>
            {t('club-details.contact-information.phone-number-label', 'Phone')}
          </h5>
          <span>
            {primaryPhone?.number ? formattedPhoneNumber : noDetailsText}
          </span>
        </div>
        {primaryFax?.number && (
          <div className="my-4">
            <h5>
              {t('club-details.contact-information.fax-number-label', 'Fax')}
            </h5>
            <span>
              {primaryFax?.number ? faxFormattedPhoneNumber : noDetailsText}
            </span>
          </div>
        )}
        {primaryWebsite && (
          <div className="my-4">
            <h5>
              {t('club-details.contact-information.website-label', 'Website')}
            </h5>
            <span>
              <Link to={ensureHttpPrefix(primaryWebsite)} target="_blank">
                {primaryWebsite}
              </Link>
            </span>
          </div>
        )}
        {officialLanguage && (
          <div className="my-4">
            <h5>
              {t(
                'club-details.contact-information.language-label',
                'Rotary language'
              )}
            </h5>
            <span>
              {officialLanguage ? officialLanguage.name : noDetailsText}
            </span>
          </div>
        )}
        {joinClubButton && (
          <div className="my-4">
            <HarrisButton variant="secondary">Join this club</HarrisButton>
          </div>
        )}

        {isUserLoggedIn && showMailingAddress && (
          <div className="my-4">
            <h5>
              {t(
                'club-details.contact-information.mailing-address-label',
                'Mailing address'
              )}
            </h5>
            {address ? (
              <div>
                {address.lineOne && (
                  <span className="block">{address.lineOne}</span>
                )}
                {address.lineTwo && (
                  <span className="block">{address.lineTwo}</span>
                )}
                {address.lineThree && (
                  <span className="block">{address.lineThree}</span>
                )}
                {address.city && <span>{address.city}</span>}
                {address.postalCode && <span>, {address.postalCode}</span>}
                {address.country && <span>, {address.country}</span>}
                {address.state && (
                  <span className="block"> {address.state}</span>
                )}
              </div>
            ) : (
              <div>{noDetailsText}</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactInformation;
