import React from 'react';

import { useField } from 'formik';
import moment from 'moment';

import { Button } from '@components/Button';
import Checkbox from '@components/Formik/Checkbox';
import { WizardPage } from '@components/Formik/Wizard';
import Title from '@components/Title';

import {
  TerminateMembershipPageProps,
  TerminateMembershipValues,
} from '@domain/clubs';

import { getClubMembersPath, isHonoraryMember } from '@use-cases/clubs';

import { useTerminationReasons } from '@repositories/clubs';

import { useTranslation } from '@external/react-i18next';

const ConfirmationForm: WizardPage<
  TerminateMembershipValues,
  TerminateMembershipPageProps
> = ({ clubId, clubType, membershipType, memberName, isProcessing }) => {
  const { t } = useTranslation();
  const { data } = useTerminationReasons(clubType);

  const [terminationDate] = useField<Date>('terminationDate');
  const [terminationReason] = useField<string>('terminationReason');

  const [terminationConfirmation] = useField<boolean>(
    'terminationConfirmation'
  );

  const isHonorary = isHonoraryMember(membershipType);

  const isChecked = terminationConfirmation.value;

  return (
    <div className="mt-16">
      <Title>
        {t(
          'terminate-membership.confirm.mobile-title',
          'Confirm Termination Details'
        )}
      </Title>
      <p className="text-small mt-4 tablet:mt-5 mb-10 tablet:mb-12">
        {t(
          'terminate-membership.confirm.description',
          "Please confirm the details of this member's termination"
        )}
      </p>

      <dl>
        <dt>{t('terminate-membership.confirm.member-name', 'Member Name')}</dt>
        <dd>{memberName}</dd>

        <dt>
          {t(
            'terminate-membership.confirm.termination-date',
            'Termination Date'
          )}
        </dt>
        <dd>{moment(terminationDate.value).format('YYYY-MM-DD')}</dd>
        {!isHonorary && (
          <>
            <dt>
              {t(
                'terminate-membership.confirm.termination-reason',
                'Termination Reason'
              )}
            </dt>
            {data ? (
              <dd>
                {
                  data?.terminationReasons
                    .filter(reason => reason.id === terminationReason.value)
                    .shift()?.reason
                }
              </dd>
            ) : (
              `...`
            )}
          </>
        )}
      </dl>
      <Checkbox
        name="terminationConfirmation"
        disabled={isProcessing}
        label={t(
          'terminate-membership.confirm.procedure-certification',
          'Procedure Certification'
        )}
        description={t(
          'terminate-membership.confirm.procedure-description',
          'I certify that this individual was terminated in accordance with our club bylaws and understands and accepts the conditions of termination.'
        )}
        required
      />
      <div className="mt-15 flex flex-col">
        <Button className="max-w-lg w-full" disabled={!isChecked}>
          {t(
            'terminate-membership.confirm.submit-label',
            'Confirm Termination'
          )}
        </Button>
        <Button
          to={getClubMembersPath(clubId)}
          text
          className="max-w-lg w-full mt-6"
          disabled={isProcessing}
        >
          {t(
            'terminate-membership.confirm.cancel-button',
            'Cancel and back to club roster'
          )}
        </Button>
      </div>
    </div>
  );
};

export default ConfirmationForm;
