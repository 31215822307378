import { TFunction } from 'i18next';
import * as Yup from 'yup';

import { emailSchema } from '@components/Formik/validation/fieldDefinitions';
import {
  isRequired,
  schemaField,
} from '@components/Formik/validation/schemaDefinitions';

const testEmail = (email: string, otherEmails: string[]) =>
  !otherEmails.some(
    otherEmail => otherEmail.toLowerCase() === email.toLowerCase()
  );

const emailValidationMessage = (t: TFunction) =>
  t(
    'club-officers.confirm-replace-officer',
    'This email address is already exist in the system. Try some other email, please!'
  );

const requiredMessage = (t: TFunction) =>
  t('forms.replace-officer', 'This field is required.');

export const confirmOfficerValidationSchema = (t: TFunction) =>
  Yup.object().shape({
    email: schemaField(t, emailSchema(t), isRequired),
  });

export const confirmAssignMemberToOfficerRoleValidationSchema = (
  t: TFunction,
  isFutureRY: boolean,
  isCustomStartChecked?: boolean,
  isCustomEndChecked?: boolean
) =>
  Yup.object().shape({
    email: schemaField(t, emailSchema(t), isRequired),
    customStart:
      isFutureRY || !isCustomStartChecked
        ? Yup.date().nullable()
        : Yup.date()
            .required(requiredMessage(t))
            .nullable(),
    customEnd:
      isFutureRY || !isCustomEndChecked
        ? Yup.date().nullable()
        : Yup.date()
            .required(requiredMessage(t))
            .nullable(),
  });

export const confirmReplaceOfficerValidationSchema = (
  t: TFunction,
  isFutureRY: boolean,
  otherEmails: string[] = []
) =>
  Yup.object().shape({
    effectiveDate: isFutureRY
      ? Yup.date().nullable()
      : Yup.date()
          .required(requiredMessage(t))
          .nullable(),
    email: schemaField(t, emailSchema(t))
      .test('email.test', emailValidationMessage(t), (value: string) =>
        testEmail(value, otherEmails)
      )
      .required(requiredMessage(t)),
  });

export const selectRoleValidationSchema = (t: TFunction) =>
  Yup.object({
    email: emailSchema(t),
  });
