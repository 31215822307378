import React from 'react';

import LinksBox from '@components/LinksBox';
import { Drupal } from '@typings/drupal';
import { Finances } from '@typings/finances';

interface Props {
  tabHeader: string;
  data: Array<Drupal.MenuItem> | Array<Finances.MenuItem>;
  classes?: string;
}
const ClubTabsLayout: React.FC<Props> = ({ tabHeader, data, classes }) => {
  return (
    <div className="mt-12">
      {data && <LinksBox header={tabHeader} items={data} classes={classes} />}
    </div>
  );
};

export default ClubTabsLayout;
