import React, { useContext } from 'react';

import { useField, useFormikContext } from 'formik';
import { toNumber } from 'lodash';
import moment from 'moment';

import { Button } from '@components/Button';
import Calendar from '@components/Formik/Calendar';
import TextField from '@components/Formik/TextField';
import { WizardPage } from '@components/Formik/Wizard';
import LabelWithValue from '@components/LabelWithValue';
import Title from '@components/Title';
import EmailInfoMessage from '@presenters/web/components/EmailInfoMessage/EmailInfoMessage';

import {
  ConfirmOfficerValues,
  Entity,
  Individual,
  Period,
} from '@domain/clubs/types';

import { getEffectiveMinDate } from '@use-cases/clubs';

import {
  getFormattedRYRange,
  getRotaryYear,
  getYear,
  isFutureRY,
} from '@utils/datetime';
import { getClubRoleTValue } from '@utils/getClubRoleTValue';

import { ClubLeadersContext } from '@layouts/club';

import { useTranslation } from '@external/react-i18next';

const ConfirmReplaceOfficer: WizardPage<ConfirmOfficerValues> = ({
  proceed,
  step,
  setStep,
  handleCancelBtnClick,
  confirmationModal,
}) => {
  const dateFormat = 'YYYY-MM-DD';

  const { t } = useTranslation();
  const { isValid } = useFormikContext<ConfirmOfficerValues>();

  const [individual] = useField<Individual>('individual');
  const [role] = useField<Entity>('role');
  const [effectiveDate, , effectiveDateHelpers] = useField<Date>(
    'effectiveDate'
  );
  const [individualToReplace] = useField<Individual>('individualToReplace');

  const [slot] = useField<Period>('slot');

  const { start: startDate, end: endDate } = slot.value;

  const formatedRoleStartDate = moment(startDate).format(dateFormat);

  const rotaryYear = toNumber(getRotaryYear(formatedRoleStartDate));

  const {
    startYear: selectedRYStart,
    endYear: selectedRYEnd,
  } = getFormattedRYRange(rotaryYear);

  const termValue = `${getYear(selectedRYStart)}-${getYear(selectedRYEnd)}`;

  const isFutureRotaryYear = isFutureRY(rotaryYear);

  const endDateToDate = moment(endDate).toDate();

  const endDateValue = moment(endDateToDate).add(-1, 'days');

  const CALENDAR_DATE_FORMAT = 'dd MMM yyyy';

  const effectiveDateMinDate = isFutureRotaryYear
    ? startDate.toDate()
    : moment().toDate();

  const effectiveMaxDate = endDateValue.toDate();

  const customCurrentYearDate = isFutureRotaryYear
    ? startDate.toDate()
    : effectiveDate.value;

  const isEmailFieldDisabled =
    individualToReplace.value.email?.toLowerCase() ===
    individualToReplace.value.onlineId?.toLowerCase();

  const handleConfirmButtonClick = () => {
    effectiveDateHelpers.setValue(customCurrentYearDate);

    proceed();
  };

  // CDA-762 : getEffectiveMinDate method consider role's gap scenario to get correct default start date while replacing an officer.
  const [clubLeaders] = useContext(ClubLeadersContext);
  const effectiveMinDate = clubLeaders?.length
    ? getEffectiveMinDate(clubLeaders, role.value.id, formatedRoleStartDate)
    : effectiveDateMinDate;

  const memberReplacedNameLabel = t(
    'club-replace-officer.confirm.replaced-name-label',
    'Officer to be replaced'
  );

  const memberNewlyNameLabel = t(
    'club-replace-officer.confirm.newly-name-label',
    'Newly Assigned Officer'
  );

  const termLabel = t('club-replace-officer.confirm.term-label', 'Term');

  const effectiveDateLabel = t(
    'club-replace-officer.confirm.effective-date',
    'Effective Date'
  );

  const roleLabel = t('club-replace-officer.confirm.role-label', 'Role');

  const emailLabel = t('club-replace-officer.confirm.email-label', 'Email');

  const finishBtnText = t(
    'club-replace-officer.confirm.submit-label',
    'Confirm'
  );

  const cancelBtnText = t(
    'club-replace-officer.confirm.cancel-button',
    'Cancel'
  );

  return (
    <>
      <Title className="h2 mb-5 mt-7">
        {t(
          'club-replace-officer.confirm.title',
          'Confirm the role of {{selectedRole}}',
          {
            selectedRole: getClubRoleTValue(t, role.value.name),
          }
        )}
      </Title>
      <div className="w-100 desktop:w-auto desktop:max-w-lg">
        {individual && (
          <LabelWithValue
            label={memberReplacedNameLabel}
            value={individual.value.name}
          />
        )}
        {individualToReplace && (
          <LabelWithValue
            label={memberNewlyNameLabel}
            value={individualToReplace.value.name}
          />
        )}
        {individualToReplace && (
          <TextField
            name="email"
            label={emailLabel}
            disabled={isEmailFieldDisabled}
            required
          />
        )}
        <EmailInfoMessage
          emailMessage={t(
            'club-add-officer.confirm.description',
            "Please make sure the member's email address is up-to-date"
          )}
          notificationMessage={t(
            'club-replace-officers.privacy-setting-message',
            `Club officer email address(es) will be shared with other club members regardless of an individual’s privacy settings. By adding a new club officer you acknowledge that they are aware that their personal information will be shared.`
          )}
        />
        {role && (
          <LabelWithValue
            label={roleLabel}
            value={getClubRoleTValue(t, role.value.name)}
          />
        )}
        <LabelWithValue label={termLabel} value={termValue} />
        {!isFutureRotaryYear && (
          <div className="custom-datepicker w-45 relative mb-4">
            <p className="mb-4 text-xs font-bold tablet:mb-2">
              {effectiveDateLabel}
            </p>
            <Calendar
              labelHidden
              name="effectiveDate"
              minDate={effectiveMinDate}
              maxDate={effectiveMaxDate}
              initialDateValue={effectiveMinDate}
              dateFormat={CALENDAR_DATE_FORMAT}
            />
          </div>
        )}
        <div className="flex flex-col mt-15">
          <Button
            full
            type="button"
            clickHandler={handleConfirmButtonClick}
            disabled={!isValid}
            data-testid="finishButton"
          >
            {finishBtnText}
          </Button>
          <Button clickHandler={handleCancelBtnClick} text className="mt-8">
            {cancelBtnText}
          </Button>
          {confirmationModal?.(step, setStep)}
        </div>
      </div>
    </>
  );
};

export default ConfirmReplaceOfficer;
