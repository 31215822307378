import React, { useState } from 'react';

import PageSteps from '@components/PageSteps';
import CandidateAddressFormDetails from '@presenters/web/pages/Leads/MMLPage/CandidateManagement//AddCandidate/AddressInformation/CandidateAddressFormDetails';
import CandidateFormDetails from '@presenters/web/pages/Leads/MMLPage/CandidateManagement/AddCandidate/ContactInformation/CandidateFormDetails';

import { localizedNavigate } from '@utils/localized-navigate';

const AddNewCandidateClub: React.FC<{
  clubId: string;
}> = ({ clubId }) => {
  enum AddNewClubCandidateSteps {
    CANDIDATEINFO,
    ADDRESSINFO,
    THANKYOU,
  }
  const noPaddingClass = 'p-0';
  const navClassName = 'text-small font-bold mt-5';
  const backBtnClassName = 'font-bold text-bright-blue-600';
  const [step, setStep] = useState<AddNewClubCandidateSteps>(
    AddNewClubCandidateSteps.CANDIDATEINFO
  );

  const backHandler = () =>
    localizedNavigate(`/club/${clubId}/manage-membership`);

  const handleBackArrowClick = (pageStep: AddNewClubCandidateSteps) =>
    setStep(pageStep);

  switch (step) {
    case AddNewClubCandidateSteps.CANDIDATEINFO:
      return (
        <PageSteps
          step={1}
          total={2}
          backHandler={backHandler}
          className={noPaddingClass}
          navClassName={navClassName}
          backBtnClassName={backBtnClassName}
        >
          <CandidateFormDetails
            clubId={clubId}
            submitHandler={() => {
              setStep(AddNewClubCandidateSteps.ADDRESSINFO);
              window.scrollTo(0, 0);
            }}
          />
        </PageSteps>
      );
    case AddNewClubCandidateSteps.ADDRESSINFO:
      return (
        <PageSteps
          step={2}
          total={2}
          backHandler={() =>
            handleBackArrowClick(AddNewClubCandidateSteps.CANDIDATEINFO)
          }
          className={noPaddingClass}
          navClassName={navClassName}
          backBtnClassName={backBtnClassName}
        >
          <CandidateAddressFormDetails
            clubId={clubId}
            submitHandler={() => {
              setStep(AddNewClubCandidateSteps.THANKYOU);
              window.scrollTo(0, 0);
            }}
          />
        </PageSteps>
      );
    case AddNewClubCandidateSteps.THANKYOU:
    default:
      return null;
  }
};
export default AddNewCandidateClub;
