import React from 'react';

import { RouteComponentProps } from '@reach/router';
import Alert from '@rotaryintl/harris-alert';

import Loading from '@components/Loading';
import GenerateReportAccordion from '@presenters/web/components/GenerateReportAccordion';

import { useFetchSiteCoreReport } from '@repositories/reports/hooks/useFetchSitecoreReport';

import { Helmet } from '@external/react-helmet-async';
import { useTranslation } from '@external/react-i18next';

import { AccessLevel, FetchAdminAccessRightsQuery } from '@typings/operations';
import { SitecoreReportTypeEnum } from '@typings/sitecore';

const ClubReportsReact: React.FC<RouteComponentProps & {
  adminAccessRightsData: {
    data: FetchAdminAccessRightsQuery | undefined;
    loading: boolean;
  };
  riClubId: string;
}> = ({ riClubId, adminAccessRightsData }) => {
  const { t } = useTranslation();
  const { data, loading } = useFetchSiteCoreReport(SitecoreReportTypeEnum.Club);
  const {
    data: accessData,
    loading: accessDataLoading,
  } = adminAccessRightsData;

  const accessLevel = accessData?.club.operationsAccess.memberships;

  const reportsData =
    data?.reportData.filter(
      report =>
        accessLevel === AccessLevel.Manager ||
        report.description.toLowerCase() === 'awards'
    ) ?? [];

  const columnViseReportsData = [];
  for (let i = 0; i < reportsData.length; i += 2) {
    columnViseReportsData.push(reportsData.slice(i, i + 2));
  }

  if (loading || accessDataLoading) {
    return <Loading />;
  }

  return (
    <div className="w-full">
      <Helmet
        titleTemplate={`${t(
          'metadata.title.club-reports',
          'Club Reports'
        )}: %s | ${t('metadata.title.default', 'My Rotary')}`}
      />
      <h1 className="font-semibold text-lg-24 mb-4 mt-8 tablet:mt-10 leading-md-heading mobile-m:text-2lg mobile-m:leading-3md tablet:text-3lg tablet:leading-lgx tablet:mb-6">
        {t('club-reports.title', 'Reports')}
      </h1>
      <div className="flex flex-col gap-2 tablet:gap-4 mb-12 tablet:mb-15 w-full tablet:w-1/2 tablet:pr-1875">
        <p className="m-0 text-md leading-md">
          {t(
            'club-reports.description',
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras suscipit ut nisi sed sodales. Suspendisse ac condimentum enim. Sed suscipit fringilla sapien nec pellentesque.'
          )}
        </p>
        <Alert variant="info" isDismissible={false}>
          {t(
            'club-reports.note',
            'Note: Membership and Contribution data updates may take up to 4-6 days to be reflected on the reports upon receipt by RI. During peak periods, it may take 8-11 days.'
          )}
        </Alert>
      </div>
      <div className="flex flex-col w-full gap-12 tablet:gap-15 mb-12">
        {columnViseReportsData.map((reportsArray, index) => {
          return (
            <div
              key={`section-${index + 1}`}
              className="w-full flex flex-col tablet:flex-row gap-12 tablet:gap-15"
            >
              {reportsArray.map(report => {
                return (
                  <div
                    key={report.label}
                    className="w-full tablet:w-1/2 flex flex-col gap-2"
                  >
                    <div className="text-lg-24 font-semibold">
                      {report.label}
                    </div>
                    <GenerateReportAccordion
                      items={report.children}
                      riClubId={riClubId}
                    />
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default ClubReportsReact;
