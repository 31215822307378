import React from 'react';

import Select, { SelectOptions, SelectProps } from '@components/Formik/Select';

import { useTerminationReasons } from '@repositories/clubs';

import getTerminationReasonLabel from '@utils/getTerminationReasonLabel';

import { useTranslation } from '@external/react-i18next';

export type TerminationReasonSelectProps = Omit<SelectProps, 'options'> & {
  clubType: string;
};

export const TerminationReasonSelect: React.FC<TerminationReasonSelectProps> = props => {
  const { t } = useTranslation();
  const { clubType } = props;
  const useTerminationReasonOptions = () => {
    const { data, error, loading } = useTerminationReasons(clubType);
    const options: SelectOptions =
      data?.terminationReasons.map(({ id, reason }) => {
        return {
          value: id,
          label: getTerminationReasonLabel(t, reason),
        };
      }) || [];
    return { options, error: error?.message, loading };
  };
  return <Select {...props} options={useTerminationReasonOptions} />;
};
