// Libs
import React from 'react';

// Hooks
import { useTranslation } from '@external/react-i18next';

interface DefinitionProps {
  term: string;
  definition: string;
}

const Definition: React.FC<DefinitionProps> = ({ term, definition }) => (
  <>
    <dt className="mb-0 mr-1 float-left leading-sm">{term}</dt>
    <dd className="text-xs leading-sm">{definition}</dd>
  </>
);

const Definitions: React.FC = () => {
  const { t } = useTranslation();

  return (
    <section className="mb-14">
      <h2>
        {t(
          'club-editmanagementsystems.add-vendor.definitions-title',
          'Definitions'
        )}
      </h2>
      <dl>
        <Definition
          term={t(
            'club-editmanagementsystems.add-vendor.definitions.subjects-term',
            'Data Subjects:'
          )}
          definition={t(
            'club-editmanagementsystems.add-vendor.definitions.subjects-definition',
            'The members and honorary members of my Rotary or Rotaract club.'
          )}
        />
        <Definition
          term={t(
            'club-editmanagementsystems.add-vendor.definitions.data-term',
            'Personal Data:'
          )}
          definition={t(
            'club-editmanagementsystems.add-vendor.definitions.data-definition',
            'All personal data of club members held by a Rotary club, Rotary International, or a Club Management Vendor, including, but not limited to, name, address, phone number(s), email address(es), gender, birthdate, club roles, and classification. This excludes sensitive personal data such as health data or financial information.'
          )}
        />
        <Definition
          term={t(
            'club-editmanagementsystems.add-vendor.definitions.offices-term',
            'International Offices:'
          )}
          definition={t(
            'club-editmanagementsystems.add-vendor.definitions.offices-definition',
            'RI’s International Offices are located in Australia, Brazil, India, Japan, South Korea and Switzerland.'
          )}
        />
      </dl>
    </section>
  );
};

export default Definitions;
