import { useQuery } from '@apollo/client';

import { fetchClubStatusByClubIds } from '@repositories/clubs/queries/fetchClubStatusByClubIds';

import {
  FetchClubStatusByClubIdsQuery,
  FetchClubStatusByClubIdsQueryVariables,
} from '@typings/operations';

export const useFetchClubStatusByClubIds = (clubIds: string[]) =>
  useQuery<
    FetchClubStatusByClubIdsQuery,
    FetchClubStatusByClubIdsQueryVariables
  >(fetchClubStatusByClubIds, { variables: { clubIds } });
