import React from 'react';

import { Button } from '@components/Button';

import MeetingItem from './MeetingItem';

import { useTranslation } from '@external/react-i18next';

import { Meeting as MeetingOutput } from '@typings/operations';

interface MeetingsProps {
  isEditable: boolean;
  addMeetingLink: string;
  editMeetingLink: (index: number) => string;
  handleDelete: (meetingIndex: number) => void;
  meetings: Omit<MeetingOutput, '__typename'>[];
}
const Meetings: React.FC<MeetingsProps> = ({
  isEditable,
  addMeetingLink,
  editMeetingLink,
  handleDelete,
  meetings,
}) => {
  const { t } = useTranslation();
  const meetingsCount = meetings.length;

  const showMeetingButtons = isEditable;

  return (
    <div>
      {meetings.map((meeting, i) => {
        const index = i;

        return (
          <MeetingItem
            isEditable={showMeetingButtons}
            key={index}
            meeting={meeting}
            index={index}
            meetingsCount={meetingsCount}
            editMeetingLink={editMeetingLink(i)}
            handleDelete={() => handleDelete(i)}
          />
        );
      })}
      {showMeetingButtons ? (
        <Button icon="ui/plus" to={addMeetingLink}>
          {t('club-details.meeting.add-button-label', 'Add Meeting')}
        </Button>
      ) : null}
    </div>
  );
};

export default Meetings;
