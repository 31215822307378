import * as React from 'react';
import { useTranslation } from '@external/react-i18next';
import classNames from 'classnames';
import Icon from '../Icon';

const Note: React.FC<{ className?: string }> = ({ className, children }) => {
  const { t } = useTranslation();
  return (
    <div className={classNames(className, 'pl-6 text-xs leading-md')}>
      <Icon
        name="circles/notification"
        size="16"
        color="gold-600"
        className="-ml-6 mt-1 -mb-5"
      />
      <p className="sr-only">{t('note', 'Note:')}</p>
      {children}
    </div>
  );
};

export default Note;
