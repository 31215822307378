import { TFunction } from 'i18next';
import moment from 'moment';
import * as yup from 'yup';

import { schemaField } from '@components/Formik/validation/schemaDefinitions';

import { DATA_CORRECTIONS_EMAIL } from '@constants/index';

const validationMessage = (t: TFunction) =>
  t(
    'club-officers.change-membership-error',
    `This member already has a membership record with this club on this date. Please pick another date or <a href='mailto:{{email}}'>contact data services</a>.`,
    { email: DATA_CORRECTIONS_EMAIL }
  );

const testEffectiveDate = (
  effectiveDate: Date,
  minEditableDate: Date | undefined,
  maxEditableDate: Date
) =>
  !minEditableDate ||
  moment(effectiveDate).isBetween(
    minEditableDate,
    maxEditableDate,
    undefined,
    '[]'
  );

export const changeMembershipValidationSchema = (
  t: TFunction,
  minEditableDate: Date | undefined,
  maxEditableDate: Date
) =>
  yup
    .object()
    .shape({
      effectiveDate: schemaField(t, yup.date()).test(
        'effectiveDate.test',
        validationMessage(t),
        (value: Date) =>
          testEffectiveDate(value, minEditableDate, maxEditableDate)
      ),
    })
    .required();
