import React from 'react';

import { RouteComponentProps } from '@reach/router';
import { Form, Formik } from 'formik';
import moment from 'moment';

import { Button } from '@components/Button';
import Calendar from '@components/Formik/Calendar';
import LinkPrevious from '@components/LinkPrevious';
import Loading from '@components/Loading';
import LeaveFormConfirmationModal from '@components/Modals/LeaveFormConfirmationModal';
import Title from '@components/Title';

import { TermToAssign } from '@domain/districts';

import { getBackButtonLabel, getOfficersPagePath } from '@use-cases/clubs';
import {
  getTermStringFromObject,
  useModal,
  useStopBrowserNavigate,
} from '@use-cases/districts';
import { useErrorHandling, useNotifications } from '@use-cases/notifications';

import {
  useFetchClubOfficersForRemoving,
  useRemoveOfficerMutation,
} from '@repositories/clubs';

import { getRotaryYear, isCurrentRY } from '@utils/datetime';
import { getClubRoleTValue } from '@utils/getClubRoleTValue';
import { localizedNavigate } from '@utils/localized-navigate';
import { getChannel } from '@utils/sendMessage';

import { useTranslation } from '@external/react-i18next';

interface RemoveOfficerProps extends RouteComponentProps {
  clubId: string;
  leadershipId?: string;
  termStartYear: string;
}

interface FormValues {
  terminationDate: Date;
}

const RemoveOfficer: React.FC<RemoveOfficerProps> = ({
  clubId,
  leadershipId,
  termStartYear,
}) => {
  const { t } = useTranslation();
  const { addError, addSuccess } = useNotifications();
  const {
    data: clubOfficersData,
    loading,
    error,
  } = useFetchClubOfficersForRemoving({
    clubId,
    termStartYear: Number(termStartYear),
  });
  const [removeOfficer] = useRemoveOfficerMutation();

  const { isShowing, show } = useModal(window.stopBrowserNavigate);

  const modalOnConfirmHandler = () => {
    localizedNavigate(getOfficersPagePath(clubId));
  };

  const modalBackHandler = () => {
    show(true);
  };

  const { globalHide } = useStopBrowserNavigate({
    showModal: show,
    isNextStepVisited: true,
    onNavigate: modalBackHandler,
  });

  const handleCancelBtnClick = (e?: React.MouseEvent) => {
    e?.preventDefault();
    show(true);
  };

  useErrorHandling(error?.message, !!error, 'remove-officer.error');

  if (
    loading ||
    error ||
    !clubId ||
    !leadershipId ||
    !clubOfficersData?.club?.leaders
  ) {
    return <Loading />;
  }

  const {
    individualName,
    role,
    startDate,
    endDate,
    primaryEmail,
  } = clubOfficersData.club.leaders.filter(l => l.id === leadershipId)[0];

  const term = getTermStringFromObject({ startDate, endDate } as TermToAssign);

  const handleSubmit = async ({ terminationDate }: FormValues) => {
    try {
      await removeOfficer({
        variables: {
          clubId,
          leadershipId,
          terminationDate: moment(terminationDate).format('YYYY-MM-DD'),
        },
      });

      addSuccess(
        t(
          'remove-leadership.success',
          'Removed {{ individualName }} from {{ roleName }} leadership position.',
          { individualName, roleName: getClubRoleTValue(t, role.name) }
        ),
        { id: 'form.success' }
      );
      getChannel('manage-club-officers').postMessage(
        t(
          'club-officer-tab-update.message',
          'Please refresh this browser tab to see updated information.'
        )
      );

      localizedNavigate(getOfficersPagePath(clubId));
    } catch (err) {
      addError((err as Error).message, { id: 'form.error' });
    }
  };

  const isFutureRole = !isCurrentRY(startDate);
  const isStartDateInFuture = moment().isBefore(moment(startDate));

  const minDate: Date =
    isFutureRole || isStartDateInFuture
      ? moment(startDate).toDate()
      : moment().toDate();
  const maxDate: Date = moment(`${getRotaryYear()}-06-30`).toDate();

  const initialValues: FormValues = {
    terminationDate: minDate,
  };

  return (
    <div className="mt-4">
      <LinkPrevious
        path=""
        label={getBackButtonLabel(t)}
        showModal={modalBackHandler}
      />
      <LeaveFormConfirmationModal
        isOpen={isShowing}
        closeModal={globalHide}
        onConfirm={modalOnConfirmHandler}
      />
      <Title className="h2 mb-5 mt-7">
        {t('unassign-from', 'Remove from a {{selectedRole}}', {
          selectedRole: getClubRoleTValue(t, role.name),
        })}
      </Title>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Form>
          <h5>{t('unassign-from.officer.label', 'Officer')}</h5>
          <p className="leading-md">{individualName}</p>

          {primaryEmail && (
            <>
              <h5>{t('unassign-from.email.label', 'Email')}</h5>
              <p className="leading-md">{primaryEmail}</p>
            </>
          )}

          {role.name && (
            <>
              <h5>{t('unassign-from.role.label', 'Role')}</h5>
              <p className="leading-md">{getClubRoleTValue(t, role.name)}</p>
            </>
          )}

          {term && (
            <>
              <h5>{t('unassign-from.term.label', 'Term')}</h5>
              <p className="leading-md">{term}</p>
            </>
          )}

          {!isFutureRole && (
            <div className="custom-datepicker w-45 relative mb-4">
              <Calendar
                name="terminationDate"
                label={t('club-roster.members.select-label', 'End Date')}
                minDate={minDate}
                maxDate={maxDate}
                dateFormat="dd MMM yyyy"
                showDefaultDate
              />
            </div>
          )}

          <div className="mt-15 flex flex-col">
            <Button
              className="max-w-lg"
              dataTestId="remove-officer-action-button"
            >
              {t('remove-leadership.remove', 'Remove')}
            </Button>
            <Button
              clickHandler={handleCancelBtnClick}
              text
              className="mt-5 max-w-lg"
            >
              {t('remove-leadership.back-link', 'Cancel')}
            </Button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default RemoveOfficer;
