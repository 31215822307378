import React from 'react';

import { Form, Formik } from 'formik';

import { personalDetailsValidationSchema } from '@presenters/web/pages/Leads/MMLPage/CandidateManagement/AddCandidate/ValidationSchema';
import {
  getCandidateFormInformation,
  getCandidateFormInitialValue,
} from '@presenters/web/pages/Leads/MMLPage/CandidateManagement/FormInfo';
import {
  CandidateFormFieldsProps,
  CandidateFormValues,
} from '@presenters/web/pages/Leads/MMLPage/CandidateManagement/types';

import NewCandidateForm from './NewCandidateForm';

import { useTranslation } from '@external/react-i18next';

interface Props {
  submitHandler: () => void;
  clubId: string;
}

const CandidateFormDetails: React.FC<Props> = ({ submitHandler, clubId }) => {
  const { t } = useTranslation();
  const fieldInitialValue: CandidateFormValues = getCandidateFormInitialValue(
    'contactInformation'
  );
  const fieldProps: CandidateFormFieldsProps = getCandidateFormInformation(
    t,
    'contactInformation'
  );
  const gridContainerClassName = 'tablet:w-1/2 desktop:w-1/2';
  return (
    <Formik
      initialValues={fieldInitialValue}
      onSubmit={submitHandler}
      validateOnBlur
      validationSchema={personalDetailsValidationSchema(t)}
    >
      {() => {
        return (
          <Form>
            <h2 className="mt-5 mb-10 mobile:mb-5">
              {t('mml.add-candidate-form.title', 'Add new candidate')}
            </h2>
            <div className={gridContainerClassName}>
              <NewCandidateForm
                id={clubId}
                fieldProps={fieldProps}
                districtName=""
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default CandidateFormDetails;
