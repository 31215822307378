import { TFunction } from 'i18next';
import moment from 'moment';

export default (t: TFunction, startDate: string, endDate: string): string => {
  const start = moment(startDate);
  const end = moment(endDate);
  const dateFormat = 'DD MMM YYYY';
  const isFullTerm =
    start.format('MM-DD') === '07-01' && end.format('MM-DD') === '06-30';

  return isFullTerm
    ? `${t('select-rotary-year.term', 'Term')}: ${start.year()}-${end.year()}`
    : `${t('officer-result.start-date', 'Start')}: ${start.format(
        dateFormat
      )} ${t('officer-result.end-date', 'End')}: ${end.format(dateFormat)}`;
};
