import React from 'react';

import moment from 'moment';

import { Button } from '@components/Button';
import Calendar from '@components/Formik/Calendar';
import { TerminationReasonSelect } from '@components/Formik/Select/TerminationReasonSelect';
import { WizardPage } from '@components/Formik/Wizard';
import Title from '@components/Title';

import {
  TerminateMembershipPageProps,
  TerminateMembershipValues,
} from '@domain/clubs';

import { getClubMembersPath, isHonoraryMember } from '@use-cases/clubs';

import { useTranslation } from '@external/react-i18next';

const InformationForm: WizardPage<
  TerminateMembershipValues,
  TerminateMembershipPageProps
> = ({
  membershipType,
  isProcessing,
  clubType,
  clubId,
  admissionDate,
  dirty,
  isValid,
}) => {
  const { t } = useTranslation();

  const dateThirtyDaysBefore = moment().subtract(30, 'd');
  const membershipStartDate = moment(admissionDate);

  const minDate = membershipStartDate.isSameOrAfter(dateThirtyDaysBefore)
    ? membershipStartDate
    : dateThirtyDaysBefore;

  const maxDate = moment().add(30, 'd');
  const isHonorary = isHonoraryMember(membershipType);

  return (
    <div className="mt-16">
      <Title>
        {t(
          'terminate-membership.form.mobile-title',
          'Select Termination Details'
        )}
      </Title>
      <div className="max-w-lg">
        <p className="text-small mt-4 tablet:mt-5 mb-10 tablet:mb-12">
          {t(
            'terminate-membership.form.description',
            'Please provide termination details and review how this data will be used by Rotary.'
          )}
        </p>
        <Calendar
          name="terminationDate"
          label={t(
            'terminate-membership.form.terminationDateLabel',
            'Termination Date'
          )}
          required
          disabled={isProcessing}
          minDate={minDate.toDate()}
          maxDate={maxDate.toDate()}
        />
        {!isHonorary && (
          <TerminationReasonSelect
            name="terminationReason"
            label={t(
              'terminate-membership.form.terminationReasonLabel',
              'Termination Reason'
            )}
            required
            clubType={clubType}
            disabled={isProcessing}
          />
        )}
        <h6 className="leading-7">
          {t(
            'terminate-membership.form.how-information-used',
            'How this information will be used'
          )}
        </h6>
        <p className="leading-7 text-small mb-0">
          {t(
            'terminate-membership.form.sorry-to-see-members-leave',
            'We are sorry to see members leave Rotary. The personal information provided to Rotary about members termination will not be shared and will be used to better understand why members leave Rotary. We may also use this information to contact former members about future memberships opportunities. Please contact membershipdevelopment@rotary.org with any questions.'
          )}
        </p>

        <div className="mt-15 flex flex-col">
          <Button
            className="max-w-lg w-full"
            disabled={isProcessing || !(isValid && dirty)}
          >
            {t('terminate-membership.form.submit-label', 'Continue')}
          </Button>
          <Button
            disabled={isProcessing}
            to={getClubMembersPath(clubId)}
            text
            className="max-w-lg w-full mt-6"
          >
            {t(
              'terminate-membership.form.cancel-button',
              'Cancel and back to club roster'
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default InformationForm;
