import { TFunction } from 'i18next';

import { Reason } from '@typings/graphql';

export default (t: TFunction, terminationReason: Reason) => {
  const translations: Record<Reason, string> = {
    [Reason.NonPaymentOfClubDues]: t(
      'termination-reason.non-payment-of-club-dues',
      'Terminated - Non-payment of club dues'
    ),
    [Reason.Personal]: t(
      'termination-reason.personal',
      'Terminated - Personal'
    ),
    [Reason.Relocation]: t(
      'termination-reason.relocation',
      'Terminated - Relocation'
    ),
    [Reason.FamilyObligations]: t(
      'termination-reason.family-obligations',
      'Terminated - Family Obligations'
    ),
    [Reason.BusinessObligations]: t(
      'termination-reason.business-obligations',
      'Terminated - Business Obligations'
    ),
    [Reason.JoiningNewClub]: t(
      'termination-reason.joining-new-club',
      'Terminated - Joining New Club'
    ),
    [Reason.ByClubForCause]: t(
      'termination-reason.by-club-for-cause',
      'Terminated - By club for cause'
    ),
    [Reason.Deceased]: t(
      'termination-reason.deceased',
      'Terminated - Deceased'
    ),
    [Reason.NonAttendance]: t(
      'termination-reason.non-attendance',
      'Terminated - Non-Attendance'
    ),
    [Reason.Health]: t('termination-reason.health', 'Terminated - Health'),

    [Reason.LackOfParticipation]: t(
      'termination-reason.lack-of-participation',
      'Terminated - Lack of participation'
    ),
    [Reason.JoiningRotaryClub]: t(
      'termination-reason.joining-rotary-club',
      'Terminated - Joining Rotary Club'
    ),
    [Reason.NoContactUpdate]: t(
      'termination-reason.no-contact-update',
      'Terminated - No Contact Update'
    ),
    [Reason.Age]: t('termination-reason.age', 'Terminated - Age'),
    [Reason.SchoolGraduation]: t(
      'termination-reason.school-graduation',
      'Terminated - School Graduation'
    ),
  };
  return translations[terminationReason];
};
