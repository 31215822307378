// Libs
import React from 'react';

// Components
import Checkbox from '@components/Formik/Checkbox';

// Hooks
import { useTranslation } from '@external/react-i18next';

interface ConditionProps {
  title: string;
  condition: string;
}

const Condition: React.FC<ConditionProps> = ({ title, condition }) => (
  <>
    <h3 className="h5">{title}</h3>
    <p className="mb-10">{condition}</p>
  </>
);

const TermsAndConditions: React.FC = () => {
  const { t } = useTranslation();

  return (
    <section className="mb-14 terms-and-conditions">
      <h2>
        {t(
          'club-editmanagementsystems.add-vendor.terms-title',
          'Review the Terms and Conditions'
        )}
      </h2>
      <Condition
        title={t(
          'club-editmanagementsystems.add-vendor.terms.personal-data-title',
          'Personal and Member Data'
        )}
        condition={t(
          'club-editmanagementsystems.add-vendor.terms.personal-data-content',
          'I represent and warrant that I have the authority to act on behalf of my club (the “Club”) and to enter this Agreement with Rotary International (“RI”). I represent and warrant that the members of my Club understand that their personal data will be used by Rotary International, including its foundation, employees, and International Offices (collectively “RI”), and transferred to its agents and representatives for processing purposes and the club management vendor identified above (“Club Management Vendor”).'
        )}
      />
      <Condition
        title={t(
          'club-editmanagementsystems.add-vendor.terms.consent-title',
          'Granting Express Consent'
        )}
        condition={t(
          'club-editmanagementsystems.add-vendor.terms.consent-content',
          'The Club grants RI express consent to integrate Club data, which includes individual members’ personal data, with the Club Management Vendor. The Club represents and warrants that it has granted the Club Management Vendor express consent to provide Club data, including individual members’ personal data, to RI. The Club grants RI express consent to provide the personal data of its members to the Club Management Vendor.'
        )}
      />
      <Condition
        title={t(
          'club-editmanagementsystems.add-vendor.terms.consent-withdrawal-title',
          'Withdrawal of Consent'
        )}
        condition={t(
          'club-editmanagementsystems.add-vendor.terms.consent-withdrawal-content',
          'The Club understands that it can withdawal its express consent at any time by returning to <a href="https://my.rotary.org/manage/club-district-administration/club-administration">https://my.rotary.org/manage/club-district-administration/club-administration</a> and following the appropriate stesp or by contacting RI staff at <a href="mailto:data@rotary.org">data@rotary.org</a>. The Club understands that such a  withdrawal of consent applies on a go forward basis only and does not apply to personal data that may have been integrated between RI and the Club Management Vendor when consent was current or not withdrawn.'
        )}
      />
      <Condition
        title={t(
          'club-editmanagementsystems.add-vendor.terms.liability-title',
          'Liability of Damages'
        )}
        condition={t(
          'club-editmanagementsystems.add-vendor.terms.liability-content',
          'The Club agrees that RI shall not be liable to the Club or its members (existing now or in the future), individually or collectively, for any damages of any kind whatsoever (including fines) related to any of the personal data it provides to RI hereunder and the personal data integrated between RI and the Club Management Vendor or any privacy or confidentiality laws or regulations, data breach or data theft.'
        )}
      />
      <Checkbox
        name="certification"
        label={t(
          'club-editmanagementsystems.add-vendor.terms.certification-label',
          'Certification'
        )}
        required
        description={t(
          'club-editmanagementsystems.add-vendor.terms.certification-description',
          'I agree to the terms and conditions set forth in this Rotary Club and Rotaract Club Authorization to Initiate Data Integration Agreement.'
        )}
      />
    </section>
  );
};

export default TermsAndConditions;
