import React, { useEffect, useRef, useState } from 'react';

import { RouteComponentProps } from '@reach/router';

import Divider from '@components/Divider';
import Loading from '@components/Loading';
import DeleteConfirmationModal from '@components/Modals/DeleteConfirmationModal';
import NotFound from '@components/Routing/NotFound';
import { titleTemplateParams } from '@components/Title/util';

import ContactInformation from './Contacts/View';
import GeneralInfo from './GeneralInfo/index';
import ClubManagementSystems from './ManagementSystems/View';
import Meetings from './Meetings/MeetingsList';
import ClubOfficers from './Officers';
import Sponsorships from './Sponsorships';

import { mapMeetingValues } from '@domain/clubs';

import {
  getOfficersPagePath,
  getVendorsList,
  isClubRotaract,
} from '@use-cases/clubs';
import { isClubRotaractSatellite } from '@use-cases/clubs/helpers/isClubRotaractSatellite';
import { useNotifications } from '@use-cases/notifications';

import { useUserAccount } from '@repositories/auth/hooks';
import {
  useDeleteClubMeeting,
  useFetchClubDetails,
  useUpdateClubMeeting,
} from '@repositories/clubs';
import { useDISCountries } from '@repositories/disCountry';

import { isEditLevel } from '@utils/access-helpers';
import { FEATURE_DOMINO_CDM_PI1, isEnabled } from '@utils/features';
import { getClubDestinationURL } from '@utils/getClubDestination';
import { isRotaryClub } from '@utils/isRotaryClub';
import { isActiveLeadership, LeadershipDate } from '@utils/leadershipUtils';
import { localizedNavigate } from '@utils/localized-navigate';
import { getCurrentTermStartYear } from '@utils/officers';
import { closeChannel, getChannel } from '@utils/sendMessage';

import { Helmet } from '@external/react-helmet-async';
import { useTranslation } from '@external/react-i18next';
import { useAppConfig } from '@hooks/appConfig';

const ClubDetails: React.FC<RouteComponentProps & {
  clubId: string;
  dominoId?: string;
}> = ({ clubId, dominoId }) => {
  const { t } = useTranslation();
  const { user } = useAppConfig();
  const { prefix, suffix } = titleTemplateParams(t);
  const { addInfo } = useNotifications();
  const addInfoRef = useRef(addInfo);
  const isDominoFeature = isEnabled(FEATURE_DOMINO_CDM_PI1);
  const individualIdForFetch =
    user?.isLoggedIn && user?.individualId ? user.individualId : null;

  const { data: userData } = useUserAccount(individualIdForFetch);

  const [updateMeetings] = useUpdateClubMeeting();
  const [deleteMeeting] = useDeleteClubMeeting();
  const { data, loading, error } = useFetchClubDetails(
    clubId,
    getCurrentTermStartYear(),
    dominoId
  );

  useEffect(() => {
    const meetingChannel = getChannel('meeting');
    meetingChannel.onmessage = event => {
      addInfoRef.current(event?.data, { id: 'club' });
    };
    const contactChannel = getChannel('contact');
    contactChannel.onmessage = event => {
      addInfoRef.current(event?.data, { id: 'club' });
    };
    // Close broadcast channel when the component unmounts
    return () => {
      closeChannel('meeting');
      closeChannel('contact');
    };
  }, []);

  const { data: countriesList, loading: loadingCountries } = useDISCountries();

  const [meetingToDelete, setMeetingToDelete] = useState<number | null>(null);

  if (loading || loadingCountries || error || !data?.club) {
    return <Loading />;
  }

  const isRotaractClub = isClubRotaract(data.club.clubType);
  const isRotaractClubType =
    isRotaractClub || isClubRotaractSatellite(data.club.clubType);

  if (!data?.club.riClubId) {
    return <NotFound />;
  }

  const {
    riClubId,
    clubType,
    district,
    districtId,
    mailingAddress,
    primaryEmail,
    primaryPhone,
    primaryFax,
    primaryWebsite,
    officialLanguage,
    leaders,
    charteredDate,
    physicalLocation,
    childClubRelationships,
    parentClubRelationships,
    meetings,
    operationsAccess,
    vendors,
  } = data.club;

  const isUserHomeDistrict = userData?.getIndividual.clubAffiliations.some(
    affiliation => district === affiliation.district?.riDistrictId.toString()
  );

  const officersWithActiveRole = leaders?.filter(({ startDate, endDate }) =>
    isActiveLeadership({
      startDate,
      endDate,
    } as LeadershipDate)
  );

  const isRotaryClubType = isRotaryClub(clubType);

  const canEditClubInfo = isEditLevel(operationsAccess.club);
  const canEditOfficers = isEditLevel(operationsAccess.leaderships);

  const handleDeleteMeeting = async (meetingIndex: number | null) => {
    if (meetingIndex != null) {
      if (isDominoFeature) {
        const meeting = meetings[Number(meetingIndex)];
        const selectedMeeting = {
          clubId,
          dominoId,
          meetingId: meeting.meetingId as string,
        };
        await deleteMeeting({
          variables: selectedMeeting,
        });
      } else {
        const newMeetings = [
          ...meetings.slice(0, meetingIndex),
          ...meetings.slice(meetingIndex + 1),
        ].map(mapMeetingValues);
        await updateMeetings({
          variables: { clubId, meetings: newMeetings },
        });
      }
    }
  };

  const handleGoToOfficers = () =>
    localizedNavigate(getOfficersPagePath(clubId), {
      state: {
        isFromDetailsPage: true,
      },
    });

  const isClubManagementSystemsSectionShown =
    user?.isLoggedIn &&
    (!!vendors.length || (!vendors.length && canEditClubInfo));

  return (
    <>
      <Helmet
        titleTemplate={t(
          'metadata.title.club-details-section',
          '{{prefix}} Club details {{suffix}}',
          { prefix, suffix }
        )}
      />
      <div className="flex flex-col mt-8 desktop:flex-row">
        <div className="desktop:w-3/5 desktop:px-6 desktop:mr-15 w-full">
          <GeneralInfo
            isUserHomeDistrict={isUserHomeDistrict}
            districtId={districtId}
            district={district}
            physicalLocation={physicalLocation}
            charteredDate={charteredDate}
            riClubId={riClubId}
            clubType={clubType}
          />
          <Divider />
          <ContactInformation
            primaryEmail={primaryEmail}
            primaryPhone={primaryPhone}
            primaryFax={primaryFax}
            primaryWebsite={primaryWebsite}
            officialLanguage={officialLanguage}
            mailingAddress={mailingAddress}
            isEditable={canEditClubInfo}
            isUserLoggedIn={user?.isLoggedIn}
            editContactInformationLink={`/club/${clubId}/edit-contact`}
            countries={countriesList?.countries || []}
            individualOperationAccess={data.club.operationsAccess.club}
          />

          {!!(
            parentClubRelationships?.length || childClubRelationships?.length
          ) && (
            <>
              <Divider />
              <Sponsorships
                childClubRelationships={childClubRelationships}
                parentClubRelationships={parentClubRelationships}
                isRotaractClubType={isRotaractClubType}
              />
            </>
          )}

          {(isRotaractClub || isRotaryClubType) && (
            <>
              {!!officersWithActiveRole?.length && (
                <>
                  <Divider />
                  <ClubOfficers
                    leaders={officersWithActiveRole}
                    isEditable={canEditOfficers}
                    handleGoToOfficers={handleGoToOfficers}
                    profilePreviousLinkPath={getClubDestinationURL(
                      clubId,
                      'details'
                    )}
                  />
                </>
              )}
              {isClubManagementSystemsSectionShown && (
                <>
                  <Divider />
                  <ClubManagementSystems
                    isEditable={canEditClubInfo}
                    vendors={getVendorsList(vendors)}
                    editManagementSystemsLink={`/club/${clubId}/edit-management-systems`}
                  />
                </>
              )}
            </>
          )}
        </div>
        <div className="desktop:w-2/5 w-full">
          <Meetings
            editMeetingLink={(index: number) =>
              `/club/${clubId}/edit-meeting/${index}`
            }
            isEditable={canEditClubInfo}
            addMeetingLink={`/club/${clubId}/add-meeting`}
            handleDelete={meetingIndex => setMeetingToDelete(meetingIndex)}
            meetings={meetings || []}
          />
        </div>
      </div>
      <DeleteConfirmationModal
        isOpen={meetingToDelete !== null}
        onConfirm={() => {
          handleDeleteMeeting(meetingToDelete);
          setMeetingToDelete(null);
        }}
        closeModal={() => setMeetingToDelete(null)}
        title={t(
          'club-details.meetings.confirm-deletion.title',
          'Are you sure you want to delete this meeting?'
        )}
      />
    </>
  );
};

export default ClubDetails;
