import React, { MutableRefObject, useRef, useState } from 'react';

import { v4 as uuid } from 'uuid';

import { Button } from '@components/Button';
import { IdentifyMemberResult } from '@components/IdentifyMemberResult';
import Loading from '@components/Loading';
import { Pagination } from '@components/Pagination';
import { ResultsList, ResultsListItem } from '@components/ResultsList';
import Title from '@components/Title';
import Filters from '@presenters/web/pages/Clubs/Members/Filters';

import SearchForm from './SearchForm';

import { CLUB_ROSTER_FILTERS, IdentificationParameters } from '@domain/clubs';

import {
  getClubTypeFilterOptions,
  useClubAddMemberFilters,
} from '@use-cases/clubs';
import { useErrorHandling } from '@use-cases/notifications';

import { useFetchClubsByName, useSearchIndividual } from '@repositories/clubs';
import { useDISCountries } from '@repositories/disCountry';

import { getFormattedClubTypeAndLocation } from '@utils/getFormattedClubTypeAndLocation';
import { isTerminationBeforeCurrentDate } from '@utils/membershipUtils';

import { useTranslation } from '@external/react-i18next';

import { DIS } from '@typings/dis';
import { ClubTypeFilterValue, MembershipType } from '@typings/operations';

const PAGE_SIZE = 5;

const searchNonMembers = true;

const IdentifyMember: React.FC<{
  filters: IdentificationParameters;
  setFilters: (filters: IdentificationParameters) => void;
  submitHandler: (id: string | undefined | false) => void;
  isRotaractClub?: boolean;
  clubName: string;
  handleFormFieldsTouched?: (isTouched: boolean) => void;
  isCreateAssignFlowEligible?: boolean;
  roleName?: string;
}> = ({
  submitHandler,
  children,
  setFilters,
  filters,
  isRotaractClub,
  clubName,
  handleFormFieldsTouched,
  isCreateAssignFlowEligible,
  roleName = 'member',
}) => {
  const gridContainerClassName = 'tablet:w-1/2 desktop:w-2/5';

  const { t } = useTranslation();

  const [expandedResult, setExpandedResult] = useState<string | null>(null);

  const clubTypes = isRotaractClub
    ? [ClubTypeFilterValue.RotaryClub, ClubTypeFilterValue.RotaractClub]
    : [ClubTypeFilterValue.RotaryClub];

  const clubType = isRotaractClub
    ? DIS.ClubTypeEnum.RotaractClub
    : DIS.ClubTypeEnum.RotaryClub;
  const { data: clubs, error: clubsError } = useFetchClubsByName('', clubTypes);
  const { data: countriesRegions, error: errorCountries } = useDISCountries();

  const countriesRegionsMapped = countriesRegions?.countries
    ?.map(({ id, name }) => ({
      label: name,
      value: id,
      id,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const clubsMapped = clubs?.clubs.results
    .map(({ name, riClubId, type, physicalLocation }) => {
      return {
        label: `${name}${getFormattedClubTypeAndLocation(
          t,
          type,
          physicalLocation
        )}`,
        value: riClubId,
        id: riClubId,
      };
    })
    .sort((a, b) =>
      a.label.localeCompare(
        b.label,
        navigator.languages[0] || navigator.language,
        {
          numeric: true,
          ignorePunctuation: true,
        }
      )
    );

  const multiSelectOptions = {
    countriesRegions: countriesRegionsMapped,
    clubs: clubsMapped,
    clubTypes: getClubTypeFilterOptions(t),
  };

  const {
    filterValues,
    currentPage,
    setCurrentPage,
    handleSearch,
    handleApplyFilters,
    handleResetFilters,
  } = useClubAddMemberFilters(setFilters);

  const {
    data,
    error: errorSearch,
    loading: loadingSearch,
  } = useSearchIndividual(filters, currentPage, PAGE_SIZE, searchNonMembers);

  const isError = Boolean(errorCountries || clubsError || errorSearch);

  useErrorHandling(
    t(
      'add-member.identify-member-form.temporary-error',
      'A temporary error occurred.'
    ),
    isError
  );

  const totalCount = data?.searchIndividual.totalCount || 0;

  const results = data?.searchIndividual.results;

  const resultListRef = useRef<HTMLElement | null>(null);

  const scrollToElement = (
    elementRef: MutableRefObject<HTMLElement | null>
  ) => {
    const currentElement = elementRef.current;

    const elementTopValue = currentElement?.getBoundingClientRect().top || 0;
    const elementOffsetHeightValue = currentElement?.offsetHeight || 0;

    window.scrollTo(
      0,
      elementTopValue + window.scrollY - elementOffsetHeightValue
    );
  };

  const pageHandler = (event: React.SyntheticEvent, pageNumber: number) => {
    event.preventDefault();
    setCurrentPage(pageNumber);
    scrollToElement(resultListRef);
  };

  const handleCollapse = (uniqueIdentifier: string) => {
    expandedResult !== uniqueIdentifier
      ? setExpandedResult(uniqueIdentifier)
      : setExpandedResult(null);
  };

  return (
    <>
      <Title className="h2 mt-4 mb-2">
        {isCreateAssignFlowEligible
          ? t(
              'add-new-officer-assign.identify-member-form.title',
              'Add New {{name}}',
              { name: roleName }
            )
          : t('add-member.identify-member-form.title', 'Identify Member')}
      </Title>
      <div className="mb-4 desktop:mb-6 desktop:w-5/5">
        <p className="mb-4">
          {t(
            'add-member.identify-member-form.info',
            'First, determine if the person is already in Rotary’s database. Enter any information you have for them — only one <br>field is required.</br>'
          )}
        </p>
        <p className="font-bold mt-2">
          {t(
            'add-member.identify-member-form.latin-alert',
            'Note that this form accepts information only in the Latin alphabet.'
          )}
        </p>
      </div>

      <SearchForm
        classes={gridContainerClassName}
        filters={filters}
        loadingSearch={loadingSearch}
        searchHandler={handleSearch}
        handleFormFieldsTouched={handleFormFieldsTouched}
      />

      {loadingSearch && <Loading className={gridContainerClassName} />}

      {data && (
        <ResultsList
          darkBackground
          summary={
            <span ref={resultListRef} data-cy="results-count" className="pr-4">
              {totalCount === 0 ? (
                <b>
                  {t(
                    'add-member.identify-member-form.zero-results',
                    '0 search results'
                  )}
                </b>
              ) : (
                t(
                  'add-member.identify-member-form.show_results-html',
                  '<b>RESULTS ({{ totalCount }})</b>',
                  { totalCount }
                )
              )}
            </span>
          }
          filters={
            totalCount > 1 && (
              <Filters
                {...{
                  multiSelectOptions,
                  initialFilterValues: filterValues,
                  applyFilter: handleApplyFilters,
                  resetFilter: handleResetFilters,
                  filtersListToShow: [
                    CLUB_ROSTER_FILTERS.COUNTRIES_REGIONS,
                    CLUB_ROSTER_FILTERS.CLUBS,
                    CLUB_ROSTER_FILTERS.CLUB_TYPES,
                  ],
                }}
              />
            )
          }
        >
          {totalCount === 0 && (
            <ResultsListItem key={0}>
              <span data-cy="no-results-message">
                {t(
                  'add-member.identify-member-form.no-results',
                  'Sorry, we couldn’t find a result to your search. Check the spelling of your search and try again. Or you can add the person as a new {{name}}.',
                  { name: roleName }
                )}
              </span>
            </ResultsListItem>
          )}
          {results?.map(result => {
            const highlightedMembership =
              result.activeMemberships.find(
                member =>
                  member.type === MembershipType.Member &&
                  !member.terminationDate
              ) || result.activeMemberships.find(() => true);

            const uniqueIdentifier = result.email ? `${result.email}` : uuid();
            const isTerminated = isTerminationBeforeCurrentDate(
              highlightedMembership?.terminationDate
            );
            return (
              <ResultsListItem key={uniqueIdentifier}>
                <IdentifyMemberResult
                  uniqueIdentifier={uniqueIdentifier}
                  collapseHandler={handleCollapse}
                  name={result.name}
                  email={result.email || ''}
                  riIndividualId={result.riIndividualId}
                  address={result.address || {}}
                  activeMemberships={result.activeMemberships}
                  phoneNumber={result.phoneNumber || undefined}
                  photoUri={result.photoUri}
                  selectMember={() => submitHandler(result.id)}
                  expanded={
                    data?.searchIndividual?.totalCount === 1 ||
                    expandedResult === uniqueIdentifier
                  }
                  sharingPermission={result.sharingPermission || null}
                  isTerminated={isTerminated}
                  clubName={clubName}
                  clubType={clubType}
                />
              </ResultsListItem>
            );
          })}

          {totalCount > 1 && (
            <Pagination
              pageSize={PAGE_SIZE}
              page={currentPage}
              totalCount={data?.searchIndividual?.totalCount}
              pageHandler={pageHandler}
            />
          )}
        </ResultsList>
      )}
      <div className={gridContainerClassName}>
        {data && (
          <>
            <p className="mt-8 mb-4 desktop:mb-8" data-cy="not-found">
              {isCreateAssignFlowEligible &&
                totalCount > 0 &&
                t(
                  'add-new-officer-assign.identify-member-form.not-found',
                  'If the person you searched for isnt in these results, check the spelling of their name and try again. You can also add the person as a new {{name}}.',
                  { name: roleName }
                )}
            </p>
            <Button
              clickHandler={() => submitHandler(false)}
              secondary
              full
              className="mb-4 desktop:mb-8"
              small={isCreateAssignFlowEligible}
            >
              {isCreateAssignFlowEligible
                ? t(
                    'add-new-officer-assign.identify-member-form.add-new-member-button',
                    'Add new {{name}}',
                    { name: roleName }
                  )
                : t(
                    'add-member.identify-member-form.add-new-member-button',
                    'Add new member'
                  )}
            </Button>
          </>
        )}
        {children}
      </div>
    </>
  );
};

export default IdentifyMember;
