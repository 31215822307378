import React from 'react';

import { RouteComponentProps } from '@reach/router';
import { Masonry } from 'masonic';

import LinksBox from '@components/LinksBox';
import Loading from '@components/Loading';
import { titleTemplateParams } from '@components/Title/util';

import { getReportsData } from '@use-cases/clubs';

import { Helmet } from '@external/react-helmet-async';
import { useTranslation } from '@external/react-i18next';
import { useMenu } from '@hooks/menu';

import { FetchAdminAccessRightsQuery } from '@typings/operations';

interface ClubReportsProps extends RouteComponentProps {
  adminAccessRightsData: {
    data: FetchAdminAccessRightsQuery | undefined;
    loading: boolean;
  };
}

const ClubReports: React.FC<ClubReportsProps> = ({ adminAccessRightsData }) => {
  const { t, i18n } = useTranslation();
  const { prefix, suffix } = titleTemplateParams(t);

  const {
    data: accessData,
    loading: accessDataLoading,
  } = adminAccessRightsData;

  const { data, loading } = useMenu(
    'menu-club-administration-reports',
    i18n.language,
    true,
    false
  );

  if (loading || accessDataLoading) {
    return <Loading />;
  }

  const reportsData = getReportsData(data, accessData);

  return (
    <>
      <Helmet
        titleTemplate={t(
          'metadata.title.club-reports',
          '{{prefix}} Club reports {{suffix}}',
          { prefix, suffix }
        )}
      />
      <div className="flex flex-col justify-items-center items-center mt-11">
        <Masonry
          items={reportsData}
          columnGutter={32}
          columnWidth={320}
          render={({ data: { label, children } }) => (
            <LinksBox classes="w-auto" header={label} items={children || []} />
          )}
        />
      </div>
    </>
  );
};

export default ClubReports;
