import React from 'react';

// Most styles are directly targeting table-related HTML tags (default-html.css).
// This wrapper makes the table scroll horizontally if wider than its container.

const TableWrapper: React.FC = ({ children }) => (
  <div className="block overflow-x-auto whitespace-nowrap">{children}</div>
);

export default TableWrapper;
