// Libs
import React from 'react';

// Hooks
import { useTranslation } from '@external/react-i18next';

const Info = () => {
  const { t } = useTranslation();

  return (
    <>
      <p>
        {t(
          'club-editmanagementsystems.info-1',
          'A Rotary or Rotaract club can simplify its recordkeeping and support Rotary programs and membership by using one or more club management vendors to share club members’ personal data among the club, Rotary International and the club management vendorlink its own membership records to Rotary International’s database. A district or zone may also use a vendor to view club members’ personal data if the club allows that vendor access to club information. (Note that, though more than one vendor may be authorized to view a club members’s personal data, only one may update the data.)'
        )}
      </p>
      <p>
        {t(
          'club-editmanagementsystems.info-2',
          'First, your club must be a customer of a club management vendor. Find a list of the vendors that provide database services on the Club Management Systems & Website Providers page of My Rotary. Then, your club can allow the vendor access to club members’ personal data by choosing the appropriate link below.'
        )}
      </p>
      <p>
        {t(
          'club-editmanagementsystems.info-3',
          'Please visit RI’s Privacy Policy (found at <a href="https://my.rotary.org/en/privacy-policy">https://my.rotary.org/en/privacy-policy</a>) for more information about how RI processes personal data. Please consult with the Club Management Vendor to determine its practices for processing personal data.'
        )}
      </p>
      <p className="mb-16">
        {t(
          'club-editmanagementsystems.info-4',
          'Note: You can allow multiple vendors view-only acess but only one vendor can update your data. Repeat this process as many times as necessary if you allow multiple vendors view-only access to your club data.'
        )}
      </p>
    </>
  );
};

export default Info;
